import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../GlobalVariables";
import { Modal, Button } from "react-bootstrap";
export default class Listing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      isOpen: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    fetch(global.apiUrl + "/editMyProfile", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          users: data.users,
        });
      });
  }

  deleteUser(user_id) {
    if (window.confirm("Are you sure?")) {
      fetch(global.apiUrl + "/deleteUser/" + user_id, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: global.token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.componentDidMount();
        });
    }
  }
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });
  render() {
    const { users } = this.state;
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="bg-white">
            <div className="p-2">
              <div className="row">
                <div className="col-md-6">
                  <b>Users</b>
                </div>
                <div className="col-md-6 text-right">
                  {(() => {
                    if (this.props.status === "Active") {
                      return (
                        <Link to="/add-user" className="btn btn-sm btn-primary">
                          <i className="fa fa-plus"></i> Add User
                        </Link>
                      );
                    } else {
                      return (
                        <Button
                          className="btn btn-sm btn-primary"
                          onClick={this.openModal}
                        >
                          <i className="fa fa-plus"></i> Add User
                        </Button>
                      );
                    }
                  })()}
                </div>
                <Modal show={this.state.isOpen} onHide={this.closeModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Alert</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    We are verifying your account.We'll notify you when the
                    process is complete so that you can add demands
                  </Modal.Body>
                </Modal>
              </div>
            </div>
            <div className="">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Surname</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Propertices</th>
                      <th>Role</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users
                      ? users.map((user, index) => (
                          <tr key={index}>
                            <td>{user.first_name}</td>
                            <td>{user.last_name}</td>
                            <td>{user.phone}</td>
                            <td>{user.email}</td>
                            <td>{user.properties_count}</td>
                            <td>{user.roles[0].description}</td>
                            <td className="d-flex">
                              <Link to={`/edit-user/${user.id}`}>
                                <i className="fa fa-edit border p-1 text-success"></i>
                              </Link>

                              <button
                                onClick={() => this.deleteUser(user.id)}
                                className="ml-1 border-unset bg-unset"
                              >
                                <i className="fa fa-trash border p-1 text-danger"></i>
                              </button>
                            </td>
                          </tr>
                        ))
                      : ""}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
