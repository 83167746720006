import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import swal from "sweetalert";
import { NumericFormat } from "react-number-format";
import "../../GlobalVariables";
import { TabTitle } from "../../../utils/GeneralFunction";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { doPost } from "../../../actions/common";
import { successToast, errorToast } from "../../../actions/toast";
import { useDispatch, useSelector } from "react-redux";

const AddDemand = () => {
  TabTitle("Add Demand"); //set the title
  if (!global.token) {
    global.token = localStorage.getItem("token")
      ? "Bearer " + localStorage.getItem("token").replace(/['"]+/g, "")
      : "";
  }
  const authUser = JSON.parse(localStorage.getItem("auth_user"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState("");

  const [areas, setAreas] = useState([]);
  const [area, setArea] = useState("");

  const [districts, setDistricts] = useState([]);
  const [district, setDistrict] = useState("");

  const [neighborhoodses, setNeighborhoodses] = useState([]);

  const [propertyTypes, setPropertyTypes] = useState([]);
  const [property_type, setPropertyType] = useState([]);
  const [subPropertyTypes, setSubPropertyTypes] = useState([]);

  const [features, setFeatures] = useState([]);
  const [isBuy, setIsBuy] = useState(true);
  const [isRent, setIsRent] = useState(false);

  let [addDemand, setAddDemand] = useState({});
  const [userinfo, setUserInfo] = useState({ languages: [] });
  let [status, setStatus] = useState(null);

  const statusOptions = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
    { value: "Draft", label: "Draft" },
  ];

  const setStatusHandle = (e) => {
    setAddDemand({ ...addDemand, status: e.value });
  };

  const handleChangeFeatures = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { languages } = userinfo;
    if (checked) {
      setUserInfo({
        languages: [...languages, value],
      });
    } else {
      let index = languages.findIndex((f) => f == value);
      languages.splice(index, 1);
      setUserInfo({
        languages: languages,
      });
    }
  };

  const handleDemand = (e) => {
    if (e.target.name == "property_availabilities") {
      setIsBuy(false);
      setIsRent(false);
      if (e.target.value == "buy") {
        setIsBuy(true);
      } else if (e.target.value == "rent") {
        setIsRent(true);
      } else {
        setIsBuy(true);
        setIsRent(true);
      }
    }
    setAddDemand({ ...addDemand, [e.target.name]: e.target.value });
  };

  const handleSubPropertyType = (selectedOption) => {
    const $data = selectedOption.map(function($data) {
      return $data.id;
    });
    setAddDemand({ ...addDemand, sub_property_type: $data });
  };

  const validations = () => {
    if (city == "") {
      errorToast("City field is required");
      return false;
    }

    if (city.nested_children.length > 0 && addDemand.area == null) {
      errorToast("Area field is required");
      return false;
    }

    if (area && area.nested_children.length > 0 && addDemand.district == null) {
      errorToast("Districts field is required");
      return false;
    }

    if (
      district &&
      district.nested_children.length > 0 &&
      addDemand.neighborhoods == null
    ) {
      errorToast("Neighborhoods field is required");
      return false;
    }

    if (addDemand.property_availabilities == null) {
      errorToast("Property availabilities field is required");
      return false;
    }

    if (addDemand.property_type == null) {
      errorToast("Property type field is required");
      return false;
    }

    if (addDemand.sub_property_type == null) {
      errorToast("Sub property type field is required");
      return false;
    }

    if (addDemand.sub_property_type == null) {
      errorToast("Sub property type field is required");
      return false;
    }

    if (addDemand.property_availabilities == "buy") {
      if (addDemand.p_selling_min == null || addDemand.p_selling_max == null) {
        errorToast("Price selling min,max field is required");
        return false;
      }
    }

    if (addDemand.property_availabilities == "rent") {
      if (addDemand.p_renting_min == null || addDemand.p_renting_max == null) {
        errorToast("Price renting min,max field is required");
        return false;
      }
    }

    if (addDemand.floor_area_min == null || addDemand.floor_area_min == null) {
      errorToast("Floor area min,max field is required");
      return false;
    }

    return true;
  };

  const addDemandSubmit = (e) => {
    e.preventDefault();

    if (validations() == false) {
      return;
    }

    addDemand = { ...addDemand, features: userinfo.languages };
    setLoading(true);
    if (authUser.status === "Inactive") {
      addDemand = { ...addDemand, status: "Draft" };
    }
    dispatch(doPost(`addDemand`, addDemand))
      .then((response) => {
        console.log(response);
        setLoading(true);
        //  const p = response.data.data;
        navigate("/my-demands");
        // setData(response.data.data);
        successToast(response.data.message);
      })
      .catch((error) => {
        setLoading(false);
        let message;
        let errors = [];
        if (error.error.title) {
          errors = error.error.title;
          errors.forEach((element) => (message = element));
        } else if (error.error.property_availabilities) {
          errors = error.error.property_availabilities;
          errors.forEach((element) => (message = element));
        } else if (error.error.city) {
          errors = error.error.city;
          errors.forEach((element) => (message = element));
        } else if (error.message) {
          message = error.message;
        } else {
          message = "something went wrong!!";
        }
        errorToast(message);
      });
  };

  const fetchCities = () => {
    fetch(global.apiUrl + "/getCities", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCities(data.results);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  useEffect(() => {
    fetchCities();
  }, []);

  const resetValues = () => {
    setAreas([]);
    setDistricts([]);
    setNeighborhoodses([]);

    addDemand = { ...addDemand, area: null };
    setAddDemand(addDemand);
  };

  const handleCity = (e) => {
    resetValues();
    setCity(e.target.value);
    const city = cities.find((element) => element.id == e.target.value);
    if (city) {
      setAreas(city.nested_children);
    }
    setCity(city);
    setAddDemand({ ...addDemand, city: e.target.value });
  };

  const handleArea = (e) => {
    setArea(e.target.value);
    const area = areas.find((element) => element.id == e.target.value);
    if (area) {
      setDistricts(area.nested_children);
    }
    setArea(area);
    setAddDemand({ ...addDemand, area: e.target.value });
  };

  const handleDistrict = (e) => {
    setDistrict(e.target.value);
    const district = districts.find((element) => element.id == e.target.value);
    if (district) {
      setNeighborhoodses(district.nested_children);
    }
    setDistrict(district);
    setAddDemand({ ...addDemand, district: e.target.value });
  };

  const fetchPropertyTypes = () => {
    fetch(global.apiUrl + "/getPropertyTypes", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setPropertyTypes(data.results);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  useEffect(() => {
    fetchPropertyTypes();
  }, []);

  const handlePropertyType = (e) => {
    setPropertyType(e.target.value);
    setAddDemand({ ...addDemand, property_type: e.target.value });
  };

  const fetchSubPropertyTypes = () => {
    fetch(global.apiUrl + "/getSubPropertyTypes/" + property_type, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const dropDownValue = data.results.map((subPropertyType) => ({
          id: subPropertyType.id,
          name: subPropertyType.name,
        }));
        setSubPropertyTypes(dropDownValue);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  useEffect(() => {
    if (property_type > 0) {
      fetchSubPropertyTypes();
    }
  }, [property_type]);

  const fetchFeatures = () => {
    fetch(global.apiUrl + "/getFeatures", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setFeatures(data.results);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  useEffect(() => {
    fetchFeatures();
  }, []);

  return (
    <div className="dashboard">
      <div className="row">
        <div className="col-md-6 col-7">
          <h4 className="font-weight-500 mb-0">Add Demand</h4>
        </div>
        <div className="col-md-6 text-right col-5">
          <Link to="/my-demands" className="btn btn-primary">
            <i className="fa fa-angle-left mr-2" />
            Back
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <form onSubmit={addDemandSubmit}>
            <input
              type="hidden"
              name="user_id"
              value={authUser.id}
              onChange={(e) => handleDemand(e)}
            />
            <div className="row mt-3">
              <div className="col-md-12 form-group">
                <label className="font-weight-500">Title</label>
                <input
                  name="title"
                  onChange={(e) => handleDemand(e)}
                  type="text"
                  className="form-control"
                  placeholder="Title"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 form-group">
                <label className="font-weight-500">Description</label>
                <textarea
                  name="description"
                  onChange={(e) => handleDemand(e)}
                  className="form-control"
                  placeholder="Description"
                  rows="3"
                ></textarea>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 form-group">
                <label className="font-weight-500">
                  Property Availabilities
                </label>
                <select
                  name="property_availabilities"
                  onChange={(e) => handleDemand(e)}
                  className="form-control"
                >
                  <option value="">Select</option>
                  <option value="buy">Buy</option>
                  <option value="rent">Rent</option>
                  {/* <option value="buy_rent">Buy/Rent</option> */}
                </select>
              </div>
            </div>
            {isBuy && (
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Price Selling</label>

                  <NumericFormat
                    name="p_selling_min"
                    onChange={(e) => handleDemand(e)}
                    type="text"
                    className="form-control"
                    placeholder="Min"
                    thousandSeparator={"."}
                    decimalSeparator={","}
                  />
                </div>
                <div className="col-md-6 mt-2">
                  <NumericFormat
                    name="p_selling_max"
                    onChange={(e) => handleDemand(e)}
                    type="text"
                    className="form-control mt-4"
                    placeholder="Max"
                    thousandSeparator={"."}
                    decimalSeparator={","}
                  />
                </div>
              </div>
            )}

            {isRent && (
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Price Renting</label>

                  <NumericFormat
                    name="p_renting_min"
                    onChange={(e) => handleDemand(e)}
                    type="text"
                    className="form-control"
                    placeholder="Min"
                    thousandSeparator={"."}
                    decimalSeparator={","}
                  />
                </div>
                <div className="col-md-6 mt-2">
                  <NumericFormat
                    name="p_renting_max"
                    onChange={(e) => handleDemand(e)}
                    type="text"
                    className="form-control mt-4"
                    placeholder="Max"
                    thousandSeparator={"."}
                    decimalSeparator={","}
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-3 form-group">
                <label className="font-weight-500">City</label>
                <select
                  name="city"
                  onChange={(e) => handleCity(e)}
                  className="form-control"
                >
                  <option value="">Select</option>
                  {cities.map((city, index) => (
                    <option key={index} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-3 form-group">
                <label className="font-weight-500">Area</label>
                <select
                  name="area"
                  onChange={(e) => handleArea(e)}
                  className="form-control"
                >
                  <option value="">Select</option>
                  {areas
                    ? areas.map((area, index) => (
                        <option key={index} value={area.id}>
                          {area.name}
                        </option>
                      ))
                    : ""}
                </select>
              </div>
              <div className="col-md-3 form-group">
                <label className="font-weight-500">Districts</label>
                <select
                  name="district"
                  onChange={(e) => handleDistrict(e)}
                  className="form-control"
                >
                  <option value="">Select</option>
                  {districts
                    ? districts.map((district, index) => (
                        <option key={index} value={district.id}>
                          {district.name}
                        </option>
                      ))
                    : ""}
                </select>
              </div>
              <div className="col-md-3 form-group">
                <label className="font-weight-500">Neighborhoods</label>
                <select
                  name="neighborhoods"
                  onChange={(e) => handleDemand(e)}
                  className="form-control"
                >
                  <option value="">Select</option>
                  {neighborhoodses
                    ? neighborhoodses.map((neighborhoods, index) => (
                        <option key={index} value={neighborhoods.id}>
                          {neighborhoods.name}
                        </option>
                      ))
                    : ""}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <label className="font-weight-500">Propery Type</label>
                <select
                  onChange={(e) => handlePropertyType(e)}
                  className="form-control"
                >
                  <option value="">Select</option>
                  {propertyTypes.map((type, index) => (
                    <option key={index} value={type.id}>
                      {type.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 form-group">
                <label className="font-weight-500">Sub Propery Type</label>
                <Multiselect
                  options={subPropertyTypes} // Options to display in the dropdown
                  onSelect={handleSubPropertyType} // Function will trigger on select event
                  onRemove={handleSubPropertyType} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 form-group">
                <label className="font-weight-500">No of Floors</label>
                <input
                  name="no_of_floor_min"
                  onChange={(e) => handleDemand(e)}
                  type="text"
                  className="form-control"
                  placeholder="Min"
                />
              </div>
              <div className="col-md-6 mt-2">
                <input
                  name="no_of_floor_max"
                  onChange={(e) => handleDemand(e)}
                  type="text"
                  className="form-control mt-4"
                  placeholder="Max"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <label className="font-weight-500">Bedrooms</label>
                <input
                  name="bedrooms_min"
                  onChange={(e) => handleDemand(e)}
                  type="text"
                  className="form-control"
                  placeholder="Min"
                />
              </div>
              <div className="col-md-6 mt-2">
                <input
                  name="bedrooms_max"
                  onChange={(e) => handleDemand(e)}
                  type="text"
                  className="form-control mt-4"
                  placeholder="Max"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <label className="font-weight-500">Bathrooms</label>
                <input
                  name="bathrooms_min"
                  onChange={(e) => handleDemand(e)}
                  type="text"
                  className="form-control"
                  placeholder="Min"
                />
              </div>
              <div className="col-md-6 mt-2">
                <input
                  name="bathrooms_max"
                  onChange={(e) => handleDemand(e)}
                  type="text"
                  className="form-control mt-4"
                  placeholder="Max"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <label className="font-weight-500">Property Year</label>
                <input
                  name="p_year_min"
                  onChange={(e) => handleDemand(e)}
                  type="text"
                  className="form-control"
                  placeholder="Min"
                />
              </div>
              <div className="col-md-6 mt-2">
                <input
                  name="p_year_max"
                  onChange={(e) => handleDemand(e)}
                  type="text"
                  className="form-control mt-4"
                  placeholder="Max"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <label className="font-weight-500">Floor Area</label>
                <input
                  name="floor_area_min"
                  onChange={(e) => handleDemand(e)}
                  type="text"
                  className="form-control"
                  placeholder="Min"
                />
              </div>
              <div className="col-md-6 mt-2">
                <input
                  name="floor_area_max"
                  onChange={(e) => handleDemand(e)}
                  type="text"
                  className="form-control mt-4"
                  placeholder="Max"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 form-group">
                <label className="font-weight-500">Property condition</label>
                <select
                  name="property_condition"
                  onChange={(e) => handleDemand(e)}
                  className="form-control"
                >
                  <option value="">Select</option>
                  <option value="Fully Renovated">
                    Fully Renovated
                  </option>
                  <option value="Renovated">Renovated</option>
                  <option value="Partially Renovated">
                    Partially Renovated
                  </option>
                  <option value="Non Renovated">Non Renovated</option>
                  <option value="Renovation Year">Renovation Year</option>
                </select>
              </div>
              <div className="col-md-6">
                <label className="font-weight-500">Property Heating</label>
                <select
                  name="property_heating"
                  onChange={(e) => handleDemand(e)}
                  className="form-control"
                >
                  <option value="">Select</option>
                  <option value="Central Heating">
                    Central Heating
                  </option>
                  <option value="Autonomous Heating">Autonomous Heating</option>
                  <option value="Does Not Have">Does Not Have</option>
                  <option value="Oil">Oil</option>
                  <option value="Natural Gas">Natural Gas</option>
                  <option value="Heat Pump">Heat Pump</option>
                  <option value="Electrical">Electrical</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              {/* <div className="col-md-6 form-group">
                <label className="font-weight-500">Status </label>
                <Select
                  name="status"
                  options={statusOptions} // Options to display in the dropdown
                  onChange={setStatusHandle} // Preselected value to persist in dropdown
                />
              </div> */}
            </div>

            <div className="row">
              <label className="font-weight-500">Features</label>
            </div>
            <div className="row">
              {features.map((feature, index) => (
                <div key={index} className="col-md-3 form-group">
                  <label htmlFor={"feature_id_" + feature.id}>
                    <input
                      name="features"
                      onChange={handleChangeFeatures}
                      value={feature.id}
                      id={"feature_id_" + feature.id}
                      type="checkbox"
                    />
                    <label
                      className="form-check-label"
                      htmlFor={"feature_id_" + feature.id}
                    >
                      {feature.feature_name}
                    </label>
                  </label>
                </div>
              ))}
            </div>

            <div className="row">
              <div className="col-md-12">
                <button
                  type="submit"
                  className="btn btn-primary btn-block mt-3"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default AddDemand;
