import React, { useState, useEffect } from 'react';
import { Link, Navigate,useParams } from "react-router-dom";
import Spinner from '../../../Component/Spinner';
import { useDispatch, useSelector } from "react-redux";

import { TabTitle } from '../../../utils/GeneralFunction';
import Dropdown from 'react-bootstrap/Dropdown';
import DataTable from 'react-data-table-component';
import { doPost, doGet, doDelete } from '../../../actions/common'
import { successToast, errorToast, infoToast } from '../../../actions/toast'
import CustomPopup from "../Demands/CustomPopup";
import { useNavigate } from "react-router-dom";

const MyDemands = () => {
    const { isLoggedIn, user } = useSelector(state => state.auth);
    
    TabTitle("My Demands");
    const { id } = useParams();
    const dispatch = useDispatch();
    const [demands, setValues] = useState([]);
    const [property, setProperty] = useState(null);
    const [selectedRows, setSelectedRows] = React.useState([]);
	const [toggleCleared, setToggleCleared] = React.useState(false);
	const [pending, setPending] = React.useState(true);
    const [visibility, setVisibility] = useState(false);
    const navigate = useNavigate();
    const columns = [
        { name: 'Demand ID', selector: row => row.demand.id},
        { name: 'Demand name', selector: row => row.demand.title},
        { name: 'Location', selector: row => row.demand.full_address},
        { name: 'Type', selector: row => row.demand.property_type ?  row.demand.property_type.name : '' },
        { name: 'Availability', selector: row => row.demand.property_availabilities ? formatAvailability(row.demand.property_availabilities) : ''},
        { name: 'Year built', selector: row => ( row.demand.p_year_min || 0 ) +'-'+  ( row.demand.p_year_max || 0 )},,
        { name: 'Floor', selector: row =>( row.demand.no_of_floor_min || 0 ) +'-'+  ( row.demand.no_of_floor_max || 0 )},
        { name: 'Surface (sqm)', selector: row => ( row.demand.no_of_floor_min || 0 ) +'-'+  ( row.demand.no_of_floor_max || 0 )},
        { name: 'Bedrooms', selector: row => ( row.demand.bedrooms_min || 0 ) +'-'+  ( row.demand.bedrooms_min || 0 )},
        { name: 'Bathrooms', selector: row => ( row.demand.bathrooms_min || 0 ) +'-'+  ( row.demand.bathrooms_max || 0 )},
        { name: 'Price', selector: row => showPrice(row.demand)},
        { name: 'Status', selector: row => row.demand.status},
        { name: 'Agent ', selector: row => row.demand.user ? `${row.demand.user.first_name} ${row.demand.user.last_name}` : ''},
        {
            cell:(row) => <Dropdown>
            <Dropdown.Toggle  id="dropdown-basic">
            </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item href={`/edit-demand/${row.id}`}>Edit</Dropdown.Item>
                    <Dropdown.Item onClick={()=> {
                        console.log(row);
                        setSelectedRows([row]);
                        deleteProperties();
                    }}>Delete</Dropdown.Item>
                    <Dropdown.Item href="#">View demand</Dropdown.Item>
                    <Dropdown.Item href={`/matching-properties/${row.id}`}>View matches</Dropdown.Item>
                </Dropdown.Menu>
          </Dropdown>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
          },
    ];

    const customStyles = {
        rows: {
            style: {
              //  minHeight: '50px', // override the row height
            },
        }
    };

    const showPrice = (demand) => {
        if (demand.property_availabilities == "buy") {
            return (demand.p_selling_min ? demand.p_selling_min : 0)+'-'+(demand.p_selling_max ? demand.p_selling_max : 0);
        } else if (demand.property_availabilities == "rent") {
            return (demand.p_renting_min ? demand.p_renting_min : 0)+'-'+(demand.p_renting_max ? demand.p_renting_max : 0);
        } else {
          return (demand.p_selling ? demand.p_selling+'/' : '')+(demand.p_renting ? demand.p_renting : '');
        }
    }
    const getProperties = () => {
        dispatch(doGet("matchingDemands/"+id))
        .then((response) => {
            setValues(response.data.data);
            setProperty(response.data.property);
            setPending(false);
           // successToast(response.data.message)
        })
        .catch((message) => {
            errorToast(message)
        });
    }
    const popupCloseHandler = (e, row) => {
      setVisibility(e);
    };

    const popupTrigger = (row) => {
        setVisibility(!visibility)
    };

    const handleAddDemad = () => { 
        if(user.status == 'Inactive'){
            setVisibility(!visibility)
            return;
        }
        navigate('/new-demand');
    }    

    const deleteProperties = () => {
        dispatch(doDelete("deleteDemands", {ids: selectedRows.map(r => r.id)}))
        .then((response) => {
            successToast(response.data.message)
            setValues(differenceBy(demands, selectedRows, 'id'));
        })
        .catch((message) => {
            errorToast(message)
        });
    }
    
    const formatAvailability = (str) => {
        let newString = str.charAt(0).toUpperCase() + str.slice(1);
        return newString.replace(/_/g, ",");
    }

    useEffect(() => {
        getProperties();
    },[])

	const handleRowSelected = React.useCallback(state => {
		setSelectedRows(state.selectedRows);
	}, []);

    const differenceBy = (array1, array2, key) =>{
        return array1.filter(a => !array2.some(b => b[key] === a[key]))
    }

	const contextActions = React.useMemo(() => {
		const handleDelete = () => {

			if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.title)}?`)) {
				setToggleCleared(!toggleCleared);
                deleteProperties();    
			}
		};

		return (
			<button key="delete" className="btn btn-danger" onClick={handleDelete} style={{ backgroundColor: 'red' }} icon>
				Delete
			</button>
		);
	}, [ demands, selectedRows, toggleCleared]);

        return(
            <div className='dashboard'>
                <div className="row">
                    <div className="col-md-6 col-7">
                        {/* <h2 className="font-weight-500 mb-0">My Properties ({demands.length})</h2> */}
                    </div>
                   
                    <div className="col-md-6 text-right col-5">
                        <Link to="/my-properties" className="btn btn-primary">
                            <i className="fa fa-angle-left mr-2" />Back
                        </Link>
                    </div>
                    
                </div>
                <div className="row mt-4">
                    <div className="col-md-12">
                        <DataTable
                            title={"Matched Demands ("+ demands.length + ") for property ("+ (property ? property.title : '') +") "}
                            columns={columns}
                            data={demands}
                            selectableRows
                            contextActions={contextActions}
                            onSelectedRowsChange={handleRowSelected}
                            clearSelectedRows={toggleCleared}
                            pagination
                            customStyles={customStyles}
                            progressPending={pending}
                        />
                    </div>
                </div>
                <CustomPopup
                    onClose={popupCloseHandler}
                    show={visibility}
                    title="Alert"
                >We are verifying your account. We'll notify you when the process is complete so that you can add demands</CustomPopup>
            </div>
        );
    }


export default MyDemands;