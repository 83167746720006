import React, { useState, useEffect, useRef } from "react";
import uniqueString from "unique-string";
import { Link } from "react-router-dom";
import Tabs, { TabPane } from "rc-tabs";
import swal from "sweetalert";
import "../../GlobalVariables";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { doPost } from "../../../actions/common";
import { successToast, errorToast } from "../../../actions/toast";
import { useDispatch, useSelector } from "react-redux";
import { TabTitle } from "../../../utils/GeneralFunction";
import readXlsxFile from "read-excel-file";
import { NumericFormat } from "react-number-format";
import axios from "axios";
const API_URL = global.apiUrl;
const ImportProperties = () => {
  TabTitle("Bulk import");
  const authUser = JSON.parse(localStorage.getItem("auth_user"));
  const isEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);
  const SampleTemplateArray = [
    "Property Year",
    "Property-b-levels",
    "Plot area",
    "Floor Area",
    "Bedrooms",
  ];
  const propertyExcel = useRef(null);
  const [loading, setLoading] = useState(false);
  const [checkTemplate, setCheckTemplate] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const [areaId, setAreaId] = useState("");

  // Mrugesh Hooks
  const dispatch = useDispatch();
  const [sampleFile, setSampleFile] = useState("");
  const [rC, setRC] = useState(true);
  const [l, setL] = useState(true);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [subPropertyTypes, setSubPropertyTypes] = useState([]);
  const [propertyTypeId, setPropertyTypeId] = useState([]);
  const [cityId, setCityId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [areas, setAreas] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [neighborhoodses, setNeighborhoodses] = useState([]);
  const [cities, setCities] = useState([]);
  const [oID, setOID] = useState([]);
  const [allData, setAllData] = useState([]);
  const [counter, setCounter] = useState();
  const [noOfRows, setNoOfRows] = useState(1);
  const [rowError, setRowError] = useState(0);
  const [subPropertyType, setSubPropertyType] = useState();
  const [propertyAvailabilities, setPropertyAvailabilities] = useState();
  const [allOtherInfo, setAllOtherInfo] = useState([
    { name: "Terrace flat", id: 1 },
    { name: "Flat (non - adjacent)", id: 2 },
    { name: "Penthouse", id: 3 },
    { name: "Lift", id: 4 },
    { name: "Storage Room", id: 5 },
    { name: "CCTV", id: 6 },
    { name: "Furnished", id: 7 },
    { name: "Home appliances	", id: 8 },
    { name: "Security door", id: 9 },
    { name: "Security system", id: 10 },
    { name: "Storage space", id: 11 },
    { name: "Tents", id: 12 },
    { name: "Attic", id: 13 },
    { name: "Garden", id: 14 },
    { name: "Pool", id: 15 },
    { name: "Indoor Pool	", id: 16 },
    { name: "Smart home", id: 17 },
    { name: "Sauna/Jacuzzi", id: 18 },
    { name: "Fireplace", id: 19 },
    { name: "Aircondition", id: 20 },
    { name: "Solar water heater", id: 21 },
    { name: "Double frontage", id: 22 },
    { name: "Corner property", id: 23 },
    { name: "Street frontage", id: 24 },
    { name: "Facing back", id: 25 },
    { name: "Balcony", id: 26 },
    { name: "Terrace", id: 27 },
    { name: "Bright", id: 28 },
    { name: "Listed building", id: 29 },
    { name: "Night meter", id: 30 },
    { name: "Families", id: 31 },
    { name: "Students", id: 32 },
    { name: "Pets", id: 33 },
    { name: "Available immediately", id: 34 },
    { name: "Available for short term", id: 35 },
    { name: "3-phase power", id: 36 },
    { name: "Structured cabling", id: 37 },
    { name: "With equipment", id: 38 },
    { name: "Within urban plan", id: 39 },
    { name: "With building", id: 40 },
    { name: "Registered in the Cadastre", id: 41 },
    { name: "Inside residential zone", id: 42 },
    { name: "Suitable for building", id: 43 },
    { name: "Road access (paved)", id: 44 },
    { name: "Road access (unpaved)", id: 45 },
  ]);
  const [otherInfo, setOtherInfo] = useState([
    { name: "Terrace flat", id: 1 },
    { name: "Flat (non - adjacent)", id: 2 },
    { name: "Penthouse", id: 3 },
    { name: "Lift", id: 4 },
    { name: "Storage Room", id: 5 },
    { name: "CCTV", id: 6 },
    { name: "Furnished", id: 7 },
    { name: "Home appliances	", id: 8 },
    { name: "Security door", id: 9 },
    { name: "Security system", id: 10 },
    { name: "Storage space", id: 11 },
    { name: "Tents", id: 12 },
    { name: "Attic", id: 13 },
    { name: "Garden", id: 14 },
    { name: "Pool", id: 15 },
    { name: "Indoor Pool	", id: 16 },
    { name: "Smart home", id: 17 },
    { name: "Sauna/Jacuzzi", id: 18 },
    { name: "Fireplace", id: 19 },
    { name: "Aircondition", id: 20 },
    { name: "Solar water heater", id: 21 },
    { name: "Double frontage", id: 22 },
    { name: "Corner property", id: 23 },
    { name: "Street frontage", id: 24 },
    { name: "Facing back", id: 25 },
    { name: "Balcony", id: 26 },
    { name: "Terrace", id: 27 },
    { name: "Bright", id: 28 },
    { name: "Listed building", id: 29 },
    { name: "Night meter", id: 30 },
    { name: "Families", id: 31 },
    { name: "Students", id: 32 },
    { name: "Pets", id: 33 },
    { name: "Available immediately", id: 34 },
    { name: "Available for short term", id: 35 },
    { name: "3-phase power", id: 36 },
    { name: "Structured cabling", id: 37 },
    { name: "With equipment", id: 38 },
    { name: "Within urban plan", id: 39 },
    { name: "With building", id: 40 },
    { name: "Registered in the Cadastre", id: 41 },
  ]);
  const [dataError, setDataError] = useState(false);
  const [perfectErrorList, setPerfectDataList] = useState([]);
  // End Mrugesh Hooks

  // Mrugesh Api
  if (!global.token) {
    global.token = localStorage.getItem("token")
      ? "Bearer " + localStorage.getItem("token").replace(/['"]+/g, "")
      : "";
  }
  const fetchPropertyTypes = () => {
    fetch(global.apiUrl + "/getPropertyTypes", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setPropertyTypes(data.results);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  const fetchSubPropertyTypes = () => {
    fetch(global.apiUrl + "/getSubPropertyTypes/" + propertyTypeId, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setSubPropertyTypes(data.results);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  const fetchAreas = () => {
    fetch(global.apiUrl + "/getAreas/" + cityId, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setAreas(data.results);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  const fetchDistricts = () => {
    fetch(global.apiUrl + "/getDistricts/" + areaId, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setDistricts(data.results);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  const fetchNeighborhoods = () => {
    fetch(global.apiUrl + "/getNeighborhoods/" + districtId, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setNeighborhoodses(data.results);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  const handlePropertyType = (e) => {
    if (parseInt(e.target.value) === 17) {
      setSampleFile(global.websiteUrl + "Land.xlsx");
      setOID(otherInfo);
      setRC(false);
      setL(true);
    } else {
      setSampleFile(global.websiteUrl + "Residential_Commercial.xlsx");
      setOID(allOtherInfo);
      setL(false);
      setRC(true);
    }
    setPropertyTypeId(e.target.value);
  };
  function DataChange(e) {
    // console.log(e.target.id, e.target.value);
    var _id_ = e.target.id.split("_");
    var _col_ = e.target.getAttribute("data-col");
    allData[_id_[0]][_col_] = e.target.value;
    // console.log(allData[_id_[0]][_col_]);
  }
  function handleProperty(e) {
    if (e.target.name === "property_availabilities") {
      setPropertyAvailabilities(e.target.value);
    } else {
      setSubPropertyType(e.target.value);
    }
  }
  const fetchCities = () => {
    fetch(global.apiUrl + "/getCities", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCities(data.results);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  function getChangeData(eData, getType) {
    var __index = eData.target.selectedIndex;
    var __el = eData.target.childNodes[__index];
    return __el.getAttribute(getType);
  }
  function handleCity(e) {
    const newCityId = getChangeData(e, "id");
    setCityId(newCityId);
    DataChange(e);
    // setValues({ ...addProperty, city: e.target.value });
  }
  function handleArea(e) {
    const newAreaId = getChangeData(e, "id");
    setAreaId(newAreaId);
    DataChange(e);
    // setValues({ ...addProperty, area: e.target.value });
  }
  function removeRow(id) {
    const _removeDiv = document.getElementById(`${id}__row`);
    if (_removeDiv) {
      _removeDiv.remove();
    }
    // const _allData = allData.filter((d, i) => i !== id);
    delete allData[id];
    setTimeout(() => {
      setAllData(allData);
      // setNoOfRows(parseInt(noOfRows) - parseInt(1));
    }, 2000);
  }
  // after ui call Api
  useEffect(() => {
    fetchPropertyTypes();
    fetchCities();
    if (propertyTypeId > 0) {
      fetchSubPropertyTypes();
    }
    if (cityId > 0) {
      fetchAreas();
    }
    if (areaId > 0) {
      fetchDistricts();
    }
    if (districtId > 0) {
      fetchNeighborhoods();
    }
  }, [propertyTypeId, cityId, areaId, districtId]);
  function getPropertyData(fileData) {
    setNoOfRows(0);
    setAllData([]);
    readXlsxFile(fileData).then(async (rows) => {
      let _tempData = [];
      let __error_ = 0;
      rows.forEach((element, index) => {
        if (index) {
          element.push(uniqueString());
          if (parseInt(propertyTypeId) === 17 && element.length === 14) {
            _tempData.push(element);
          } else if (
            (parseInt(propertyTypeId) === 7 ||
              parseInt(propertyTypeId) === 12) &&
            element.length === 23
          ) {
            _tempData.push(element);
          } else {
            __error_ = 1;
          }
        }
      });
      if (__error_) {
        errorToast(`Something wrong in data.`);
      } else {
        setNoOfRows(parseInt(rows.length) - parseInt(1));
        setAllData(_tempData);
      }
    });
  }
  function removeErrorRow(ar, vl) {
    const _index_ = ar.indexOf(vl);
    if (_index_ > -1) {
      ar.splice(_index_, 1);
    }
    return ar;
  }
  function isInArray(value, array) {
    return new Promise((resolve, reject) => {
      resolve(array.indexOf(value));
    });
  }
  async function goToPublish() {
    console.time("Start");
    setLoading(true);
    let _error_ = 0;
    let _errorList_ = "";
    let _checkData_ = new Promise(async (resolve, reject) => {
      if (!propertyTypeId) {
        _errorList_ += "Please select property type.<br>";
        _error_ = 1;
      }
      if (!subPropertyType) {
        _errorList_ += "Please select sub property type.<br>";
        _error_ = 1;
      }
      if (!propertyAvailabilities) {
        _errorList_ += "Please select property availability.<br>";
        _error_ = 1;
      }
      let perfectDataRow = [];
      let _propertyCodeArray_ = [];
      for (let index = 0; index < allData.length; index++) {
        var element = allData[index];
        var __e__ = 0;
        var __errorList__ = `Row ${index + 1} : <br> `;
        var d = await isInArray(element[1], _propertyCodeArray_);
        if (d > -1) {
          __errorList__ += `${element[1]} Property code is Duplicate.<br>`;
          __e__ = _error_ = 1;
        }
        perfectDataRow.push(index);
        _propertyCodeArray_.push(element[1]);
        var PropertyIdByToken = await checkPropertyToken(element[1]);
        console.log(PropertyIdByToken);
        if (PropertyIdByToken) {
          __errorList__ += `${element[1]} Property code is used.<br>`;
          __e__ = _error_ = 1;
        }
        if (!element[0]) {
          __errorList__ += `Property Title required.<br>`;
          __e__ = _error_ = 1;
        }
        if (!element[1]) {
          __errorList__ += `Property Code required.<br>`;
          __e__ = _error_ = 1;
        }
        if (!element[2]) {
          __errorList__ += `Price required.<br>`;
          __e__ = _error_ = 1;
        }
        if (!element[3]) {
          __errorList__ += `City required.<br>`;
          __e__ = _error_ = 1;
        }
        if (!element[4]) {
          __errorList__ += `Area required.<br>`;
          __e__ = _error_ = 1;
        }
        if (parseInt(propertyTypeId) === 17) {
          if (!element[5]) {
            __errorList__ += `Property plot area required.<br>`;
            __e__ = _error_ = 1;
          }
        } else {
          if (!element[5]) {
            __errorList__ += `Property floor area required.<br>`;
            __e__ = _error_ = 1;
          }
          if (!element[6]) {
            __errorList__ += `Property year required.<br>`;
            __e__ = _error_ = 1;
          }
          if (!element[7]) {
            __errorList__ += `Property floor required.<br>`;
            __e__ = _error_ = 1;
          }
          if (!element[14]) {
            __errorList__ += `Property bedrooms required.<br>`;
            __e__ = _error_ = 1;
          }
        }
        if (__e__ === 1) {
          _errorList_ += __errorList__;
        }
        if (index === allData.length - 1) {
          resolve(perfectDataRow);
        }
      }
    });
    _checkData_.then((perfectDataRow) => {
      if (_error_) {
        var div = document.createElement("div");
        div.innerHTML = _errorList_;
        swal({
          title: "Some mandatory fields are missing are you sure to continue?",
          content: div,
          icon: "warning",
          closeOnClickOutside: false,
          closeOnEsc: false,
          dangerMode: true,
          button: "Close",
          showCloseButton: true,
        }).then((willDelete) => {
          setLoading(false);
          return false;
        });
      } else {
        callDataSaveApi(perfectDataRow);
      }
    });
  }
  function checkPropertyToken(propertyToken) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          API_URL + "/checkTokenExistOrNot",
          {
            property_token: propertyToken,
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: global.token,
            },
          }
        )
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((message) => {
          console.log(message);
          resolve(0);
        });
    });
  }
  function saveProperty(data, url) {
    console.log(data);
    // return false;
    return new Promise((resolve, reject) => {
      axios
        .post(
          API_URL + "/" + url,
          {
            data,
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: global.token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          resolve(1);
        })
        .catch((message) => {
          console.log(message);
          resolve(0);
        });
    });
  }
  async function callDataSaveApi() {
    let ans = new Promise(async (resolve, reject) => {
      for (let index = 0; index < allData.length; index++) {
        var element = allData[index];
        let _data_ = "";
        if (parseInt(propertyTypeId) === 17) {
          _data_ = {};
        } else {
          _data_ = {
            title: element[0],
            property_code: element[1],
            p_selling: element[2],
            city: element[3],
            area: element[4],
            floor_area: element[5],
            p_year: element[6],
            prop_floor: element[7],
            building_condition: element[8],
            property_condition: element[9],
            energy_rating: element[10],
            windows: element[11],
            property_heating: element[12],
            b_levels: element[13],
            bedrooms: element[14],
            bathrooms: element[15],
            property_wc: element[16],
            parking_space: element[17],
            price_negotiable: element[18] === "Yes" ? 1 : 0,
            features: element[19],
            main_image: element[20],
            video_url: element[21],
            property_type: propertyTypeId,
            sub_property_type: subPropertyType,
            property_availabilities: propertyAvailabilities,
            status: "Active",
          };
        }
        dispatch(doPost("addProperty", _data_));
        if (index === allData.length - 1) {
          resolve();
        }
      }
    });
    ans.then(() => {
      setLoading(false);
      console.timeEnd("Start");
    });
  }
  // End Mrugesh Api

  return (
    <div className="dashboard">
      <div className="row">
        <div className="col-md-6 col-7">
          <h4 className="font-weight-500 mb-0">Bulk import</h4>
        </div>
        <div className="col-md-6 text-right col-5">
          <Link to="/my-properties" className="btn btn-primary">
            <i className="fa fa-angle-left mr-2" /> Back
          </Link>
        </div>
      </div>
      <div className="row mx-0">
        <div
          className="col-md-12 bg-white mt-3 py-3 px-3"
          style={{ borderRadius: 15 }}
        >
          <h5 className="font-weight-600 mb-5">
            Lets import your properties in homexpress.
          </h5>
          <div className="row">
            <div className="col-4 mb-5">
              <div className="d-flex align-items-center justify-content-between">
                <label className="">Choose property type *</label>
                <select
                  onChange={(e) => handlePropertyType(e)}
                  className="form-select w-auto"
                  required
                >
                  <option value="">Select</option>
                  {propertyTypes &&
                    propertyTypes.map((type, index) => (
                      <option key={index} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
          {sampleFile && (
            <div className="row">
              <div className="col-4 mb-5">
                <a href={sampleFile} download>
                  Download Sample File
                </a>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-4 mb-5">
              <div className="d-flex align-items-center justify-content-between">
                <label className="">Choose Sub Property Type *</label>
                <select
                  name="sub_property_type"
                  onChange={(e) => handleProperty(e)}
                  className="form-select w-auto"
                >
                  <option value="">Select</option>
                  {subPropertyTypes &&
                    subPropertyTypes.map((subType, index) => (
                      <option key={index} value={subType.id}>
                        {subType.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4 mb-5">
              <div className="d-flex align-items-center justify-content-between">
                <label className="">Choose property availability *</label>
                <select
                  name="property_availabilities"
                  onChange={(e) => handleProperty(e)}
                  className="form-select w-auto"
                  required
                >
                  <option value="">Select</option>
                  <option value="buy">Buy</option>
                  <option value="rent">Rent</option>
                  <option value="buy_rent">Buy/Rent</option>
                </select>
              </div>
            </div>
          </div>
          {(() => {
            if (propertyTypeId.length) {
              return (
                <div className="row">
                  <div className="col form-group">
                    <input
                      className="form-control"
                      type="file"
                      id="propertyFile"
                      onChange={function(e) {
                        getPropertyData(e.target.files[0]);
                        e.target.value = null;
                      }}
                    />
                  </div>
                </div>
              );
            }
          })()}
          {(() => {
            if (allData.length) {
              return (
                <>
                  <h5 className="font-weight-600 mb-3">Verify your data</h5>
                  <div className="over_table">
                    <table className="table table-bordered mb-0">
                      <thead>
                        <tr>
                          <th scope="col">No</th>
                          <th scope="col">Title *</th>
                          <th scope="col">Property code *</th>
                          <th scope="col">Price-selling/renting *</th>
                          <th scope="col">City *</th>
                          <th scope="col">Area *</th>
                          <th
                            scope="col"
                            className="R C"
                            style={{ display: rC ? " " : "none" }}
                          >
                            Property floor area *
                          </th>
                          <th
                            scope="col"
                            className="R C"
                            style={{ display: rC ? " " : "none" }}
                          >
                            Property year *
                          </th>
                          <th
                            scope="col"
                            className="R C"
                            style={{ display: rC ? " " : "none" }}
                          >
                            Property floor *
                          </th>
                          <th
                            scope="col"
                            className="L"
                            style={{ display: l ? " " : "none" }}
                          >
                            Property plot area *
                          </th>
                          <th
                            scope="col"
                            className="R C"
                            style={{ display: rC ? " " : "none" }}
                          >
                            Building condition
                          </th>
                          <th
                            scope="col"
                            className="R C"
                            style={{ display: rC ? " " : "none" }}
                          >
                            Property condition
                          </th>
                          <th
                            scope="col"
                            className="R C"
                            style={{ display: rC ? " " : "none" }}
                          >
                            Energy rating
                          </th>
                          <th
                            scope="col"
                            className="R C"
                            style={{ display: rC ? " " : "none" }}
                          >
                            Property windows
                          </th>
                          <th
                            scope="col"
                            className="R C"
                            style={{ display: rC ? " " : "none" }}
                          >
                            Property heating
                          </th>
                          <th
                            scope="col"
                            className="R C"
                            style={{ display: rC ? " " : "none" }}
                          >
                            Property b levels
                          </th>
                          <th
                            scope="col"
                            className="R C"
                            style={{ display: rC ? " " : "none" }}
                          >
                            Property bedrooms *
                          </th>
                          <th
                            scope="col"
                            className="R C"
                            style={{ display: rC ? " " : "none" }}
                          >
                            Property bathrooms
                          </th>
                          <th
                            scope="col"
                            className="R C"
                            style={{ display: rC ? " " : "none" }}
                          >
                            Property wc
                          </th>
                          <th
                            scope="col"
                            className="R C"
                            style={{ display: rC ? " " : "none" }}
                          >
                            Property parking
                          </th>
                          <th scope="col">Price negotiable</th>
                          <th scope="col">Other Info</th>
                          <th scope="col">photos</th>
                          <th scope="col">video</th>
                          <th
                            scope="col"
                            className="L"
                            style={{ display: l ? " " : "none" }}
                          >
                            Property frontage length
                          </th>
                          <th
                            scope="col"
                            className="L"
                            style={{ display: l ? " " : "none" }}
                          >
                            Property land depth
                          </th>
                          <th
                            scope="col"
                            className="L"
                            style={{ display: l ? " " : "none" }}
                          >
                            Property field area
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {[...Array(noOfRows)].map((elementInArray, index) => {
                          let _index = index + 1;
                          console.log(noOfRows, index, allData);
                          return (
                            <tr id={index + "__row"} key={index}>
                              <td className="id" id={index + "__id"}>
                                {_index}
                                <div onClick={() => removeRow(index)}>
                                  Remove
                                </div>
                              </td>
                              <td className="propertyTitle">
                                <input
                                  type="text"
                                  id={index + "_propertyTitle"}
                                  className="form-control w-auto"
                                  defaultValue={allData[index][0]}
                                  data-col={0}
                                  onChange={(e) => DataChange(e)}
                                  required
                                />
                              </td>
                              <td className="propertyCode">
                                <input
                                  type="text"
                                  id={index + "_propertyCode"}
                                  className="form-control w-auto"
                                  defaultValue={allData[index][1]}
                                  data-col={1}
                                  onChange={(e) => DataChange(e)}
                                  required
                                />
                              </td>
                              <td className="sellingRenting">
                                <NumericFormat
                                  name="p_selling"
                                  data-col={2}
                                  onChange={(e) => DataChange(e)}
                                  type="text"
                                  className="form-control w-auto"
                                  placeholder=""
                                  thousandSeparator={"."}
                                  decimalSeparator={","}
                                  id={index + "_sellingRenting"}
                                  defaultValue={allData[index][2]}
                                  required
                                />
                              </td>
                              <td className="city">
                                <select
                                  onChange={(e) => handleCity(e)}
                                  className="form-select w-auto"
                                  id={index + "_city"}
                                  data-col={3}
                                  defaultValue={allData[index][3]}
                                  required
                                >
                                  <option value="">Select</option>
                                  {cities &&
                                    cities.map((city, index) => (
                                      <option
                                        key={index}
                                        id={city.id}
                                        value={city.name}
                                      >
                                        {city.name}
                                      </option>
                                    ))}
                                </select>
                              </td>
                              <td className="area">
                                <select
                                  onChange={(e) => handleArea(e)}
                                  className="form-select w-auto"
                                  id={index + "_area"}
                                  data-col={4}
                                  defaultValue={allData[index][4]}
                                  required
                                >
                                  <option value="">Select</option>
                                  {areas
                                    ? areas.map((area, index) => (
                                        <option
                                          key={index}
                                          id={area.id}
                                          value={area.name}
                                        >
                                          {area.name}
                                        </option>
                                      ))
                                    : ""}
                                </select>
                              </td>
                              <td
                                className="floorArea"
                                style={{ display: rC ? " " : "none" }}
                              >
                                <input
                                  id={index + "_floorArea"}
                                  name="floorArea"
                                  type="number"
                                  className="form-control w-auto"
                                  onChange={(e) => DataChange(e)}
                                  data-col={5}
                                  defaultValue={allData[index][5]}
                                  required={rC}
                                />
                              </td>
                              <td
                                className="year"
                                style={{ display: rC ? " " : "none" }}
                              >
                                <input
                                  className="form-control w-auto"
                                  type="text"
                                  id={index + "_year"}
                                  maxLength="4"
                                  minLength="4"
                                  onChange={(e) => DataChange(e)}
                                  data-col={6}
                                  defaultValue={allData[index][6]}
                                  required={rC}
                                />
                              </td>
                              <td
                                className="floor"
                                style={{ display: rC ? " " : "none" }}
                              >
                                <select
                                  className="form-select w-auto"
                                  id={index + "_floor"}
                                  onChange={(e) => DataChange(e)}
                                  data-col={7}
                                  defaultValue={allData[index][7]}
                                  required={rC}
                                >
                                  <option value="">Select</option>
                                  <option value="Basement">Basement</option>
                                  <option value="Semi basement">
                                    Semi basement
                                  </option>
                                  <option value="Ground floor">
                                    Ground floor
                                  </option>
                                  <option value="Mezzanine">Mezzanine</option>
                                </select>
                              </td>
                              <td
                                className="plotArea"
                                style={{ display: l ? " " : "none" }}
                              >
                                <input
                                  className="form-control w-auto"
                                  type="number"
                                  id={index + "_plotArea"}
                                  onChange={(e) => DataChange(e)}
                                  data-col={5}
                                  defaultValue={allData[index][5]}
                                  required={l}
                                />
                              </td>
                              <td
                                className="buildingCondition"
                                style={{ display: rC ? " " : "none" }}
                              >
                                <select
                                  className="form-select w-auto"
                                  id={index + "_buildingCondition"}
                                  onChange={(e) => DataChange(e)}
                                  data-col={8}
                                  defaultValue={allData[index][8]}
                                >
                                  <option value="">Select</option>
                                  <option value="Excellent">Excellent</option>
                                  <option value="Semi basement">
                                    Semi basement
                                  </option>
                                  <option value="Very Good">Very Good</option>
                                  <option value="Good">Good</option>
                                  <option value="Average">Average</option>
                                </select>
                              </td>
                              <td
                                className="propertyCondition"
                                style={{ display: rC ? " " : "none" }}
                              >
                                <select
                                  className="form-select w-auto"
                                  id={index + "_propertyCondition"}
                                  onChange={(e) => DataChange(e)}
                                  data-col={9}
                                  defaultValue={allData[index][9]}
                                >
                                  <option value="">Select</option>
                                  <option value="Fully Renovated">
                                    Fully Renovated
                                  </option>
                                  <option value="Renovated">Renovated</option>
                                  <option value="Partially Renovated">
                                    Partially Renovated
                                  </option>
                                  <option value="Non Renovated">
                                    Non Renovated
                                  </option>
                                  <option value="Renovation Year">
                                    Renovation Year
                                  </option>
                                </select>
                              </td>
                              <td
                                className="energyRating"
                                style={{ display: rC ? " " : "none" }}
                              >
                                <select
                                  className="form-select w-auto"
                                  id={index + "_energyRating"}
                                  onChange={(e) => DataChange(e)}
                                  data-col={10}
                                  defaultValue={allData[index][10]}
                                >
                                  <option value="">Select</option>
                                  <option value="A+">A+</option>
                                  <option value="A">A</option>
                                  <option value="B">B</option>
                                  <option value="B+">B+</option>
                                  <option value="C">C</option>
                                  <option value="D">D</option>
                                  <option value="E">E</option>
                                  <option value="Z">Z</option>
                                  <option value="H">H</option>
                                  <option value="Excepted From Mandatory EPC Issuance">
                                    Excepted From Mandatory EPC Issuance
                                  </option>
                                  <option value="EPC Under Issuance">
                                    EPC Under Issuance
                                  </option>
                                </select>
                              </td>
                              <td
                                className="propertyWindows"
                                style={{ display: rC ? " " : "none" }}
                              >
                                <select
                                  className="form-select w-auto"
                                  id={index + "_propertyWindows"}
                                  onChange={(e) => DataChange(e)}
                                  data-col={11}
                                  defaultValue={allData[index][11]}
                                >
                                  <option value="">Select</option>
                                  <option value="Wooden">Wooden</option>
                                  <option value="Single Glazed">
                                    Single Glazed
                                  </option>
                                  <option value="Double Glazed">
                                    Double Glazed
                                  </option>
                                  <option value="PVC">PVC</option>
                                </select>
                              </td>
                              <td
                                className="propertyHeating"
                                style={{ display: rC ? " " : "none" }}
                              >
                                <select
                                  className="form-select w-auto"
                                  id={index + "_propertyHeating"}
                                  onChange={(e) => DataChange(e)}
                                  data-col={12}
                                  defaultValue={allData[index][12]}
                                >
                                  <option value="">Select</option>
                                  <option value="Central Heating">
                                    Central Heating
                                  </option>
                                  <option value="Autonomous Heating">
                                    Autonomous Heating
                                  </option>
                                  <option value="Does Not Have">
                                    Does Not Have
                                  </option>
                                  <option value="Oil">Oil</option>
                                  <option value="Natural Gas">
                                    Natural Gas
                                  </option>
                                  <option value="Heat Pump">Heat Pump</option>
                                  <option value="Electrical">Electrical</option>
                                  <option value="Other">Other</option>
                                </select>
                              </td>
                              <td
                                className="propertyBLevels"
                                style={{ display: rC ? " " : "none" }}
                              >
                                <input
                                  className="form-control w-auto"
                                  type="number"
                                  id={index + "_propertyBLevels"}
                                  onChange={(e) => DataChange(e)}
                                  data-col={13}
                                  defaultValue={allData[index][13]}
                                />
                              </td>
                              <td
                                className="propertyBedrooms"
                                style={{ display: rC ? " " : "none" }}
                              >
                                <input
                                  className="form-control w-auto"
                                  type="number"
                                  id={index + "_propertyBedrooms"}
                                  data-col={14}
                                  defaultValue={allData[index][14]}
                                />
                              </td>
                              <td
                                className="propertyBathrooms"
                                style={{ display: rC ? " " : "none" }}
                              >
                                <input
                                  className="form-control w-auto"
                                  type="number"
                                  id={index + "_propertyBathrooms"}
                                  onChange={(e) => DataChange(e)}
                                  data-col={15}
                                  defaultValue={allData[index][15]}
                                />
                              </td>
                              <td
                                className="propertyWc"
                                style={{ display: rC ? " " : "none" }}
                              >
                                <input
                                  className="form-control w-auto"
                                  type="number"
                                  id={index + "_propertyWc"}
                                  onChange={(e) => DataChange(e)}
                                  data-col={16}
                                  defaultValue={allData[index][16]}
                                />
                              </td>
                              <td
                                className="propertyParking"
                                style={{ display: rC ? " " : "none" }}
                              >
                                <input
                                  className="form-control w-auto"
                                  type="number"
                                  id={index + "_propertyParking"}
                                  onChange={(e) => DataChange(e)}
                                  data-col={17}
                                  defaultValue={allData[index][17]}
                                />
                              </td>
                              <td className="priceNegotiable">
                                <input
                                  className=""
                                  type="checkbox"
                                  id={index + "_priceNegotiable"}
                                  onChange={(e) => DataChange(e)}
                                  data-col={rC ? 18 : 6}
                                  defaultChecked={
                                    rC ? allData[index][18] : allData[index][6]
                                  }
                                />
                              </td>
                              <td className="otherInfo">
                                <Multiselect
                                  id={index + "_otherInfo"}
                                  options={oID} // Options to display in the dropdown
                                  // selectedValues={ct} // Preselected value to persist in dropdown
                                  // onSelect={handleCompanytype} // Function will trigger on select event
                                  // onRemove={handleCompanytype} // Function will trigger on remove event
                                  // onChange={(e) => handleProfile(e)}
                                  displayValue="name" // Property name to display in the dropdown options
                                  data-col={rC ? 19 : 7}
                                  defaultValue={
                                    rC ? allData[index][19] : allData[index][7]
                                  }
                                />
                              </td>
                              <td className="photos">
                                <input
                                  name=""
                                  type="textarea"
                                  className="form-control w-auto"
                                  id={index + "_photos"}
                                  onChange={(e) => DataChange(e)}
                                  data-col={rC ? 20 : 8}
                                  defaultValue={
                                    rC ? allData[index][20] : allData[index][8]
                                  }
                                />
                              </td>
                              <td className="video">
                                <input
                                  name=""
                                  type="textarea"
                                  className="form-control w-auto"
                                  id={index + "_video"}
                                  onChange={(e) => DataChange(e)}
                                  data-col={rC ? 21 : 9}
                                  defaultValue={
                                    rC ? allData[index][21] : allData[index][9]
                                  }
                                />
                              </td>
                              <td
                                style={{ display: l ? " " : "none" }}
                                className="frontageLength"
                              >
                                <input
                                  className="form-control w-auto"
                                  type="number"
                                  id={index + "_frontageLength"}
                                  onChange={(e) => DataChange(e)}
                                  data-col={10}
                                  defaultValue={allData[index][10]}
                                />
                              </td>
                              <td
                                style={{ display: l ? " " : "none" }}
                                className="landDepth"
                              >
                                <input
                                  className="form-control w-auto"
                                  type="number"
                                  id={index + "_landDepth"}
                                  onChange={(e) => DataChange(e)}
                                  data-col={11}
                                  defaultValue={allData[index][11]}
                                />
                              </td>
                              <td
                                style={{ display: l ? " " : "none" }}
                                className="fieldArea"
                              >
                                <input
                                  className="form-control w-auto"
                                  type="number"
                                  id={index + "_fieldArea"}
                                  onChange={(e) => DataChange(e)}
                                  data-col={12}
                                  defaultValue={allData[index][12]}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="row py-5">
                    <div className="col-12">
                      <div className="d-flex align-items-center justify-content-end gap-4">
                        <div className="savemsg">Properties saved</div>
                        <button className="btn btn-secondary">
                          Save as draft properties
                        </button>
                        <button
                          className="btn btn-success"
                          onClick={() => goToPublish()}
                          disabled={loading}
                        >
                          Publish
                          {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              );
            }
          })()}
          {/* <button
            type="button"
            class="btn btn-primary me-3"
            onClick={() => setNoOfRows(noOfRows + 1)}
          >
            Add new properties
          </button> */}
        </div>
      </div>
    </div>
  );
};
export default ImportProperties;
