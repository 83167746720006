import React, { useState, useEffect } from 'react';
import { TabTitle } from '../../../utils/GeneralFunction';
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Tabs, { TabPane } from 'rc-tabs';
import housePlaceholder from "../../../img/placeholder-house.jpg";
import pdf from "../../../img/download-pdf.png";
import '../../GlobalVariables';
import { doPost, doGet } from '../../../actions/common'
import { successToast, errorToast } from '../../../actions/toast'
import Multiselect from 'multiselect-react-dropdown';
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import CustomPopup from "./CustomPopup";

const MatchingProperties = props => {
  
    TabTitle("Matching Demands");
    const {id} = useParams();
    const dispatch = useDispatch();
    const [properties, setValues] = useState([]);
    const [demand, setDemand] = useState([]);
    const [selectedRows, setSelectedRows] = React.useState([]);
	const [toggleCleared, setToggleCleared] = React.useState(false);
	const [pending, setPending] = React.useState(true);
    const [visibility, setVisibility] = useState(false);
    const [contact, setContact] = useState(null);
 
    const popupCloseHandler = (e, row) => {
      setVisibility(e);
      setContact(null);
    };

    const popupTrigger = (row) => {
        setVisibility(!visibility)
        setContact(row);
    };

    const columns = [
        { name: 'Property ID', selector: row => row.property.id},
        { name: 'Property name', selector: row => row.property.title},
        { name: 'Location', selector: row => row.property.full_address},
        { name: 'Type', selector: row => row.property.property_type ?  row.property.property_type.name : '' },
        { name: 'Availability', selector: row => formatAvailability(row.property.property_availabilities)},
        { name: 'Year built', selector: row => row.property.p_year},
        { name: 'Floor', selector: row => row.property.prop_floor},
        { name: 'Surface (sqm)', selector: row => row.property.floor_area},
        { name: 'Bedrooms', selector: row => row.property.bedrooms},
        { name: 'Price', selector: row => showPrice(row.property)},
        { name: 'Matches', selector: row => row.percentage+' %'},
        {
            cell:(row) => <a href="#" onClick={(e) =>popupTrigger(row.property )} className='btn btn-primary btn-sm'>Contact info</a>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            cell:(row) => <a href="#" onClick={(e) =>deleteProperty(row.property)} ><i className='fa fa-trash'></i></a>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            cell:(row) => <a href="#"><i className='fa fa-heart'></i></a>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
       // { name: 'Status', selector: row => row.status},
       // { name: 'Agent ', selector: row => row.user ? row.user.first_name+' '+row.user.last_name : ''},
    ];

    const customStyles = (state, rowInfo, column, instance) => ({
        style: {
            cursor: 'pointer',
            overflow: 'visible'
        },
        
    })
    const showPrice = (property) => {
        if (property.property_availabilities == "buy") {
            return  (property.p_selling ? property.p_selling : 0);
        } else if (property.property_availabilities == "rent") {
            return (property.p_renting ? demand.p_renting : 0);
        } else {
          return (property.p_selling ? property.p_selling+'/' : '')+(property.p_renting ? property.p_renting : '');
        }
    }
    const getProperties = () => {
        dispatch(doGet("matchingProperties/"+id))
        .then((response) => {
            setValues(response.data.data);
            setDemand(response.data.demand);
            setPending(false);
           // successToast(response.data.message)
        })
        .catch((message) => {
            errorToast(message)
        });
    }

    const deleteProperty = (row) => {
        dispatch(doPost("deleteMatchingProperties/"+row.id))
        .then((response) => {
            getProperties();
            successToast(response.data.message)
        })
        .catch((message) => {
            errorToast(message)
        });
    }

    // const deleteProperties = (ids) => {
    //     dispatch(doDelete("deleteProperties", {ids: selectedRows.map(r => r.id)}))
    //     .then((response) => {
    //         successToast(response.data.message)
    //        // setValues(differenceBy(properties, selectedRows, 'id'));
    //     })
    //     .catch((message) => {
    //         errorToast(message)
    //     });
    // }
    
    const formatAvailability = (str) => {
        let newString = str.charAt(0).toUpperCase() + str.slice(1);
        return newString.replace(/_/g, ",");
    }

    useEffect(() => {
        getProperties();
    },[])

    return (
        <div className='dashboard'>
            <div className="row">
                <div className="col-md-8 col-7">
                    <h2 className="font-weight-500 mb-0">Matched properties for {demand.title}</h2>
                </div>
                    <div className="col-md-4 text-right col-5">
                    <Link to="/my-demands" className="btn btn-primary">
                        <i className="fa fa-angle-left mr-2" />Back
                    </Link>
                </div>
            </div>
            <div className="row mt-4">
                    <div className="col-md-12">
                        <DataTable
                            title={"Matched properties ("+ properties.length + ")"}
                            columns={columns}
                            data={properties}
                            selectableRows
                            //contextActions={contextActions}
                            //onSelectedRowsChange={handleRowSelected}
                            clearSelectedRows={toggleCleared}
                            pagination
                            customStyles={customStyles}
                            progressPending={pending}
                        />
                    </div>
                </div>
                <CustomPopup
                    onClose={popupCloseHandler}
                    show={visibility}
                    title="Contact info"
                >
                    {/* { JSON.stringify(contact) } */}
                   <div className='row'>
                        <div className='col-md-6' style={{borderRight: "1px solid #ccc"}}>
                            <h4>Agency</h4>
                            <div className="d-flex mt-2 align-items-center" >
                                <div className="">
                                    <label htmlFor="image">
                                        {contact && contact.user.company ? (
                                        <img
                                            src={contact.user.company.logo}
                                            className="profile-image"
                                            alt=""
                                        />
                                        ) : (
                                        <i className="fa fa-solid fa-camera camera-icon ml-1"></i>
                                        )}
                                    </label>
                                </div>
                                <div className="ml-2">
                                <label>{contact && contact.user.company ? contact.user.company.name : "N/A"}</label>
                                <br />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <h4>Agent info</h4>
                            <div className="d-flex mt-2 align-items-center">
                                <div className="">
                                    <label htmlFor="image">
                                        {contact && contact.user ? (
                                        <img
                                            src={contact.user}
                                            className="profile-image"
                                            alt=""
                                        />
                                        ) : (
                                        <i className="fa fa-solid fa-camera camera-icon ml-1"></i>
                                        )}
                                    </label>
                                </div>
                                <div className="ml-2">
                                <label>{contact && contact.user ? contact.user.full_name : "N/A"}</label>
                                <br />
                                </div>
                            </div>                   
                        </div>
                   </div>
                </CustomPopup>
        </div>
    )
   
}

export default MatchingProperties;
