import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Dropdown, Navbar, Nav } from "react-bootstrap";
import CsoonModal from "../CsoonModal";

import homexpressLogo from "../../img/logos/homexpress-logo.png";

const authUser = JSON.parse(sessionStorage.getItem("auth_user"));
class Header extends Component {
  logout() {
    localStorage.clear();
    window.location.href = "/";
  }

  csoonmodalRef = ({ handleShow }) => {
    this.showModal = handleShow;
  };

  onCsoonClick = () => {
    this.showModal();
  };
  render() {
    const authUser = localStorage.getItem("auth_user");
    return (
      <div className="header">
        <Navbar expand="lg" className="on-mobile">
          <Container>s,amd
            <Link className="navbar-brand" to="/">
              <img className="img-fluid d-block" src={homexpressLogo} alt="" />
            </Link>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className="border-0"
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <li className="nav-item">
                  <Link to="/dashboard" className="nav-link">
                    Dashboard
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/my-properties" className="nav-link">
                    My properties
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/my-demands" className="nav-link">
                    My demands
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/" className="nav-link">
                    My matches
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/" className="nav-link">
                    Profile
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/" className="nav-link">
                    Logout
                  </Link>
                </li>
                {/* <li className="nav-item">
                            <Link className="nav-link" to="/">Help</Link>
                        </li> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        {/* <nav className="navbar navbar-expand-lg navbar-light on-mobile">
                    <div className="container">                    
                        <a className="navbar-brand text-primary" href="#/">
                            <img className="img-fluid d-block" src={homexpressLogo} alt=""/>
                        </a>
                        <button className="navbar-toggler navbar-toggler-right border-0" type="button" data-toggle="collapse" data-target="#navbar4" >
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbar4">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">            
                                    <Link to="/dashboard" className="nav-link">Dashboard</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/my-properties" className="nav-link">My properties</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/my-demands" className="nav-link">My demands</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/" className="nav-link">Profile</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/" className="nav-link">Logout</Link>
                                </li>
                            </ul>
                            <Link to="#/" className="btn navbar-btn ml-md-2 btn-light">Help</Link>                            
                        </div>
                    </div>
                </nav> */}
        <div className="py-2 on-desktop">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8">
                <img
                  className="img-fluid d-block"
                  src={homexpressLogo}
                  alt=""
                />
              </div>
              <div className="col-md-4 d-flex justify-content-around align-items-center pr-4">
               
                <Dropdown className="ml-2 head_plus">
                  <Dropdown.Toggle id="dropdown-basic">
                    <i className="fa fa-plus" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <Link to="/productListing">Product listing</Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/uploadCsv">Import product</Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <a className="btn ml-2 btn-link text-muted px-1" href="#/">
                  <i className="fa fa-question-circle-o" />
                </a> */}
                {authUser && (
                  <div className="d-inline-block ml-4 mr-2">
                    <p
                      className="mb-0 font-weight-500"
                      style={{ fontSize: "0.8rem" }}
                    >
                      
                    </p>
                    
                  </div>
                )}
                <Dropdown>
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    <i className="fa fa-user" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {/* <Dropdown.Item>
                      <Link to="/my-profile">
                        <i className="fa fa-user-edit"></i> My Profile
                      </Link>
                    </Dropdown.Item> */}
                    <Dropdown.Item>
                      <a href="jascript:void(0);" onClick={() => this.logout()}>
                        <i className="fa fa-sign-out"></i> Logout
                      </a>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          {/* modal */}
          <CsoonModal ref={this.csoonmodalRef(this)}></CsoonModal>
        </div>
      </div>
    );
  }
}

export default Header;
