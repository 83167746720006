import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import "../../GlobalVariables";
import { doPost } from "../../../actions/common";
import { successToast, errorToast } from "../../../actions/toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { TabTitle } from "../../../utils/GeneralFunction";
import { useDropzone } from "react-dropzone";
import { Modal, Button } from "react-bootstrap";
const EditDemand = () => {
  TabTitle("Edit Demand");
  const authUser = JSON.parse(localStorage.getItem("auth_user"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  let [demand, setDemand] = useState({});
  const [forOpenModel, setForOpenModel] = useState(false);

  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [loading, setLoading] = useState(false);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [subPropertyTypes, setSubPropertyTypes] = useState([]);
  const [userinfo, setUserInfo] = useState({ languages: [] });

  const [features, setFeatures] = useState([]);
  const [defaultFeatures, setDefaultFeatures] = useState([]);
  const [isBuy, setIsBuy] = useState(true);
  const [isRent, setIsRent] = useState(false);

  const statusOptions = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
    { value: "Draft", label: "Draft" },
  ];
  const setStatusHandle = (e) => {
    setDemand({ ...demand, status: e.value });
  };
  const handleChangeFeatures = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { languages } = userinfo;
    if (checked) {
      setUserInfo({
        languages: [...languages, value],
      });
    } else {
      let index = languages.findIndex((f) => f == value);
      languages.splice(index, 1);
      setUserInfo({
        languages: languages,
      });
    }
  };

  useEffect(() => {
    fetch(global.apiUrl + "/editDemand/" + id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setDemand(data.data);
        let n = data.data.sub_property_type_selected;
        if (n.length > 0) {
          const defaultFeatures = n.map((feature) => ({
            name: feature.name,
            id: feature.id,
          }));
          setDefaultFeatures(defaultFeatures);
        }

        let features = data.data.features;
        if (features.length > 0) {
          const defaultFeature = features.map((feature) => feature.id);
          setUserInfo({
            languages: defaultFeature,
          });
        }
      });
  }, []);

  const handleDemand = (e) => {
    if (e.target.name == "property_availabilities") {
      setIsBuy(false);
      setIsRent(false);
      if (e.target.value == "buy") {
        setIsBuy(true);
      } else if (e.target.value == "rent") {
        setIsRent(true);
      } else {
        setIsBuy(true);
        setIsRent(true);
      }
    }

    setDemand({ ...demand, [e.target.name]: e.target.value });
  };

  const handleSubPropertyType = (selectedOption) => {
    console.log(`Option selected:`, selectedOption);
    let subPropertyTypeArray = [];
    selectedOption.map((o) => subPropertyTypeArray.push(o.value));
    setDemand({ ...demand, sub_property_type: subPropertyTypeArray });
  };

  const updateDemandSubmit = (e) => {
    e.preventDefault();
    demand = { ...demand, feature_id: userinfo.languages };
    setLoading(true);
    dispatch(doPost("updateDemand/" + id, demand))
      .then((response) => {
        setLoading(false);
        successToast(response.data.message);
      })
      .catch((message) => {
        setLoading(false);
        errorToast(message);
      });
  };

  const handleNeighbourhoods = (selectedList, selectedItem) => {
    setDemand({ ...demand, sub_property_type: selectedList.map((n) => n.id) });
  };

  const fetchCities = () => {
    fetch(global.apiUrl + "/getCities", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCities(data.results);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  useEffect(() => {
    fetchCities();
  }, []);

  const fetchAreas = () => {
    fetch(global.apiUrl + "/getAreas/" + demand.city, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setAreas(data.results);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  useEffect(() => {
    fetchAreas();
  }, [demand.city]);

  const fetchDistricts = () => {
    fetch(global.apiUrl + "/getDistricts/" + demand.area, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setDistricts(data.results);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  useEffect(() => {
    fetchDistricts();
  }, [demand.area]);

  const fetchNeighborhoods = () => {
    fetch(global.apiUrl + "/getNeighborhoods/" + demand.district, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setNeighborhoods(data.results);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  useEffect(() => {
    fetchNeighborhoods();
  }, [demand.district]);

  const fetchPropertyTypes = () => {
    fetch(global.apiUrl + "/getPropertyTypes", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setPropertyTypes(data.results);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  useEffect(() => {
    fetchPropertyTypes();
  }, []);

  const fetchSubPropertyTypes = () => {
    if (demand.property_type != null) {
      fetch(global.apiUrl + "/getSubPropertyTypes/" + demand.property_type, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: global.token,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          const dropDownValue = data.results.map((subPropertyType) => ({
            id: subPropertyType.id,
            name: subPropertyType.name,
          }));
          setSubPropertyTypes(dropDownValue);
        })
        .catch((err) => {
          console.log("Error Reading data " + err);
        });
    }
  };
  useEffect(() => {
    fetchSubPropertyTypes();
  }, [demand.property_type]);

  const fetchFeatures = () => {
    fetch(global.apiUrl + "/getFeatures", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setFeatures(data.results);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  useEffect(() => {
    fetchFeatures();
  }, []);
  function openModel() {
    setForOpenModel(true);
  }
  function closeModel() {
    setForOpenModel(false);
  }
  return (
    <div className="dashboard">
      <div className="row">
        <div className="col-md-6 col-7">
          <h4 className="font-weight-500 mb-0">Edit Demand</h4>
        </div>
        <div className="col-md-6 text-right col-5">
          <Link to="/my-demands" className="btn btn-primary">
            <i className="fa fa-angle-left mr-2" />
            Back
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <form onSubmit={updateDemandSubmit}>
            <div className="row mt-3">
              <div className="col-md-12 form-group">
                <label className="font-weight-500">Title</label>
                <input
                  name="title"
                  onChange={(e) => handleDemand(e)}
                  value={demand.title || ""}
                  type="text"
                  className="form-control"
                  placeholder="Title"
                  required
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 form-group">
                <label className="font-weight-500">Description</label>
                <textarea
                  name="description"
                  onChange={(e) => handleDemand(e)}
                  value={demand.description || ""}
                  className="form-control"
                  placeholder="Description"
                  rows="3"
                ></textarea>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 form-group">
                <label className="font-weight-500">
                  Property Availabilities
                </label>
                <select
                  name="property_availabilities"
                  onChange={(e) => handleDemand(e)}
                  value={demand.property_availabilities || ""}
                  className="form-control"
                >
                  <option disabled>Select</option>
                  <option value="buy">Buy</option>
                  <option value="rent">Rent</option>
                  {/* <option value="buy_rent">Buy/Rent</option> */}
                </select>
              </div>
            </div>
            {isBuy && (
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Price Selling</label>

                  <input
                    name="p_selling_min"
                    onChange={(e) => handleDemand(e)}
                    value={demand.p_selling_min}
                    type="text"
                    className="form-control"
                    placeholder="Min"
                  />
                </div>
                <div className="col-md-6 mt-2">
                  <input
                    name="p_selling_max"
                    onChange={(e) => handleDemand(e)}
                    value={demand.p_selling_max}
                    type="text"
                    className="form-control mt-4"
                    placeholder="Max"
                  />
                </div>
              </div>
            )}

            {isRent && (
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Price Renting</label>
                  <input
                    name="p_renting_min"
                    onChange={(e) => handleDemand(e)}
                    value={demand.p_renting_min}
                    type="text"
                    className="form-control"
                    placeholder="Min"
                  />
                </div>
                <div className="col-md-6 mt-2">
                  <input
                    name="p_renting_max"
                    onChange={(e) => handleDemand(e)}
                    value={demand.p_renting_max}
                    type="text"
                    className="form-control mt-4"
                    placeholder="Max"
                  />
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-md-3 form-group">
                <label className="font-weight-500">City</label>
                <select
                  name="city"
                  onChange={(e) => handleDemand(e)}
                  value={demand.city}
                  className="form-control"
                >
                  <option value="">Select</option>
                  {cities.map((city, index) => (
                    <option key={index} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-3 form-group">
                <label className="font-weight-500">Area</label>
                <select
                  name="area"
                  onChange={(e) => handleDemand(e)}
                  value={demand.area}
                  className="form-control"
                >
                  <option value="">Select</option>
                  {areas
                    ? areas.map((area, index) => (
                        <option key={index} value={area.id}>
                          {area.name}
                        </option>
                      ))
                    : ""}
                </select>
              </div>
              <div className="col-md-3 form-group">
                <label className="font-weight-500">Districts</label>
                <select
                  name="district"
                  onChange={(e) => handleDemand(e)}
                  value={demand.district}
                  className="form-control"
                >
                  <option value="">Select</option>
                  {districts
                    ? districts.map((district, index) => (
                        <option key={index} value={district.id}>
                          {district.name}
                        </option>
                      ))
                    : ""}
                </select>
              </div>
              <div className="col-md-3 form-group">
                <label className="font-weight-500">Neighborhoods</label>
                <select
                  name="neighborhoods"
                  onChange={(e) => handleDemand(e)}
                  value={demand.neighborhoods}
                  className="form-control"
                >
                  <option value="">Select</option>
                  {neighborhoods
                    ? neighborhoods.map((neighborhood, index) => (
                        <option key={index} value={neighborhood.id}>
                          {neighborhood.name}
                        </option>
                      ))
                    : ""}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <label className="font-weight-500">Propery Type</label>
                <select
                  name="property_type"
                  onChange={(e) => handleDemand(e)}
                  value={demand.property_type || ""}
                  className="form-control"
                >
                  <option value="">Select</option>
                  {propertyTypes.length > 0 ? (
                    propertyTypes.map((type, index) => (
                      <option key={index} value={type.id}>
                        {type.name}
                      </option>
                    ))
                  ) : (
                    <p>No matching record found</p>
                  )}
                </select>
              </div>
              <div className="col-md-6 form-group">
                <label className="font-weight-500">Sub Propery Type</label>
                <Multiselect
                  options={subPropertyTypes} // Options to display in the dropdown
                  selectedValues={defaultFeatures} // Preselected value to persist in dropdown
                  onSelect={handleNeighbourhoods} // Function will trigger on select event
                  onRemove={handleNeighbourhoods} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 form-group">
                <label className="font-weight-500">No of Floors</label>
                <input
                  name="no_of_floor_min"
                  onChange={(e) => handleDemand(e)}
                  value={demand.no_of_floor_min}
                  type="text"
                  className="form-control"
                  placeholder="Min"
                />
              </div>
              <div className="col-md-6 mt-2">
                <input
                  name="no_of_floor_max"
                  onChange={(e) => handleDemand(e)}
                  value={demand.no_of_floor_max}
                  type="text"
                  className="form-control mt-4"
                  placeholder="Max"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <label className="font-weight-500">Bedrooms</label>
                <input
                  name="bedrooms_min"
                  onChange={(e) => handleDemand(e)}
                  value={demand.bedrooms_min}
                  type="text"
                  className="form-control"
                  placeholder="Min"
                />
              </div>
              <div className="col-md-6 mt-2">
                <input
                  name="bedrooms_max"
                  onChange={(e) => handleDemand(e)}
                  value={demand.bedrooms_max}
                  type="text"
                  className="form-control mt-4"
                  placeholder="Max"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <label className="font-weight-500">Bathrooms</label>
                <input
                  name="bathrooms_min"
                  onChange={(e) => handleDemand(e)}
                  value={demand.bathrooms_min}
                  type="text"
                  className="form-control"
                  placeholder="Min"
                />
              </div>
              <div className="col-md-6 mt-2">
                <input
                  name="bathrooms_max"
                  onChange={(e) => handleDemand(e)}
                  value={demand.bathrooms_max}
                  type="text"
                  className="form-control mt-4"
                  placeholder="Max"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <label className="font-weight-500">Property Year</label>
                <input
                  name="p_year_min"
                  onChange={(e) => handleDemand(e)}
                  value={demand.p_year_min}
                  type="text"
                  className="form-control"
                  placeholder="Min"
                />
              </div>
              <div className="col-md-6 mt-2">
                <input
                  name="p_year_max"
                  onChange={(e) => handleDemand(e)}
                  value={demand.p_year_max}
                  type="text"
                  className="form-control mt-4"
                  placeholder="Max"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <label className="font-weight-500">Floor Area</label>
                <input
                  name="floor_area_min"
                  onChange={(e) => handleDemand(e)}
                  value={demand.floor_area_min}
                  type="text"
                  className="form-control"
                  placeholder="Min"
                />
              </div>
              <div className="col-md-6 mt-2">
                <input
                  name="floor_area_max"
                  onChange={(e) => handleDemand(e)}
                  value={demand.floor_area_max}
                  type="text"
                  className="form-control mt-4"
                  placeholder="Max"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <label className="font-weight-500">Property condition</label>
                <select
                  name="property_condition"
                  onChange={(e) => handleDemand(e)}
                  value={demand.property_condition}
                  className="form-control"
                >
                  <option value="">Select</option>
                  <option value="Fully Renovated">Fully Renovated</option>
                  <option value="Renovated">Renovated</option>
                  <option value="Partially Renovated">
                    Partially Renovated
                  </option>
                  <option value="Non Renovated">Non Renovated</option>
                  <option value="Renovation Year">Renovation Year</option>
                </select>
              </div>
              <div className="col-md-6">
                <label className="font-weight-500">Property Heating</label>
                <select
                  name="property_heating"
                  onChange={(e) => handleDemand(e)}
                  value={demand.property_heating}
                  className="form-control"
                >
                  <option value="">Select</option>
                  <option value="Central Heating">Central Heating</option>
                  <option value="Autonomous Heating">Autonomous Heating</option>
                  <option value="Does Not Have">Does Not Have</option>
                  <option value="Oil">Oil</option>
                  <option value="Natural Gas">Natural Gas</option>
                  <option value="Heat Pump">Heat Pump</option>
                  <option value="Electrical">Electrical</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="col-md-6 form-group">
                <label className="font-weight-500">Status </label>
                {(() => {
                  if (authUser.status === "Active") {
                    return (
                      <Select
                        name="status"
                        options={statusOptions} // Options to display in the dropdown
                        onChange={setStatusHandle} // Preselected value to persist in dropdown
                        value={{ label: demand.status, value: demand.status }}
                      />
                    );
                  } else {
                    return (
                      <>
                        <Modal show={forOpenModel} onHide={closeModel}>
                          <Modal.Header closeButton>
                            <Modal.Title>Alert</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            You can update the status once we verify your
                            account.
                          </Modal.Body>
                        </Modal>
                        <input
                          readOnly
                          type="text"
                          className="form-control"
                          placeholder="Draft"
                          value="Draft"
                          onClick={openModel}
                        />
                      </>
                    );
                  }
                })()}
              </div>
            </div>

            <div className="row">
              <label className="font-weight-500">Features</label>
            </div>

            <div className="row">
              {features.map((feature, index) => (
                <div key={index} className="col-md-3 form-group">
                  <label htmlFor={"feature_id_" + feature.id}>
                    <input
                      type="checkbox"
                      value={feature.id}
                      onChange={handleChangeFeatures}
                      id={"feature_id_" + feature.id}
                      defaultChecked={
                        demand.features &&
                        demand.features.findIndex((f) => f.id === feature.id) !=
                          -1
                          ? true
                          : false
                      }
                    />{" "}
                    {feature.feature_name}
                  </label>
                </div>
              ))}
            </div>

            <div className="row">
              <div className="col-md-12">
                <button
                  type="submit"
                  className="btn btn-primary btn-block mt-3"
                  disabled={loading}
                >
                  Update
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default EditDemand;
