import React, { useState, useEffect, useRef } from "react";
import Listing from "../Users/Listing";
import { Modal } from "react-bootstrap";
import "../../GlobalVariables";
import { useNavigate } from "react-router-dom";
import { TabTitle } from "../../../utils/GeneralFunction";
import { useDispatch, useSelector } from "react-redux";
import { successToast, errorToast, infoToast } from "../../../actions/toast";
import Multiselect from "multiselect-react-dropdown";
import RichTextEditor from "./RichTextEditor";
import Spinner from "../../../Component/Spinner";
import axios from "axios";
const MyProfile = () => {
  const userProfileRef = useRef(null);
  const companyProfileRef = useRef(null);
  const [user, setUser] = useState([]);
  const [defaultMemberOf, setDefaultMemberOf] = useState([]);
  const [ct, setCT] = useState([]);
  const [profileValue, setProfileValues] = useState([]);
  const [editor, setEditor] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isTypeOn, setIsTypeOn] = useState(false);
  const [checked, setChecked] = useState(false);
  // mrugesh add this
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [bio, setBio] = useState("");
  const [office_location, setOffice_location] = useState("");
  const [company_name, setCompany_name] = useState("");
  const [company_website, setCompany_website] = useState("");
  const [company_address, setCompany_address] = useState("");
  const [company_size, setCompany_size] = useState("");
  const [company_description, setCompany_description] = useState("");
  // const [description, setDescription] = useState("");
  const [role, setRole] = useState("");
  const [company_id, setCompany_id] = useState("");
  const [company_email, setCompany_email] = useState("");
  const [userImage, setUserImage] = useState("/assets/images/user.png");
  const [companyImage, setCompanyImage] = useState(
    "/assets/images/office-building.png"
  );
  const [individualChecked, setIndividualChecked] = useState(false);
  const [legalChecked, setLegalChecked] = useState(false);
  const [company_type, setCompany_type] = useState([]);
  const [member_of, setMember_of] = useState([]);
  const [userStatus, setUserStatus] = useState();
  // mrugesh stop this
  const companyTypes = [
    { id: "'Real estate'", name: "Real estate" },
    { id: "Construction company", name: "Construction company" },
    { id: "Property management", name: "Property management" },
    { id: "Investment company (AEEAP)", name: "Investment company (AEEAP)" },
  ];
  const getUser = () => {
    if (!global.token) {
      global.token = localStorage.getItem("token")
        ? "Bearer " + localStorage.getItem("token").replace(/['"]+/g, "")
        : "";
    }
    // console.log(global.apiUrl, global.token);
    fetch(global.apiUrl + "/editMyProfile", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log();
        setUserStatus(data.user.status);
        setUser(data.user);
        if (data.user) {
          if (data.user.image) {
            setUserImage(data.user.image);
          }
          setFirst_name(data.user.first_name);
          setLast_name(data.user.last_name);
          setEmail(data.user.email);
          setPhone(data.user.phone);
          if (data.user.city && data.user.city !== "NULL") {
            setCity(data.user.city);
          }
          if (data.user.street && data.user.street !== "NULL") {
            setStreet(data.user.street);
          }
          if (data.user.meta_data) {
            setBio(data.user.meta_data.bio);
            setOffice_location(data.user.meta_data.office_location);
          }
        }
        if (data.user.company) {
          if (data.user.company.logo) {
            setCompanyImage(data.user.company.logo);
          }
          setCompany_name(data.user.company.name);
          setCompany_website(data.user.company.company_website);
          setCompany_address(data.user.company.address);
          setCompany_size(data.user.company.company_size);
          setCompany_description(data.user.company.description);
          setCompany_id(data.user.company.id);
          setCompany_email(data.user.company.email);
          // setDescription(data.user.company.description);
          setProfileValues(data.user.company.description);
          setRole(data.user.company.type);
          if (data.user.company.type === "Legal representative") {
            setLegalChecked(true);
            setIsTypeOn(true);
            setCompany_type(data.user.company.company_type);
            const ct = data.user.company.company_type;
            if (ct && ct.length) {
              const ctv = ct.map((a) => ({
                name: a,
                id: a,
              }));
              setCT(ctv);
            }
          }
          if (data.user.company.type === "Individual") {
            setIndividualChecked(true);
          }
          if (
            data.user.company.member_of &&
            data.user.company.member_of.length
          ) {
            setMember_of(data.user.company.member_of);
            const v = data.user.company.member_of.map((a) => ({
              name: a,
              id: a,
            }));
            setDefaultMemberOf(v);
          }
        }
        // setData(data.user);
        // if (user.roles[0].name == "agent") {
        //   setDisabled(true);
        // }
        // const type = user.company.type;
        // console.log(type);
        // if (type == "Legal representative") {
        //   setIsTypeOn(true);
        //   setChecked(true);
        // } else {
        //   setIsTypeOn(false);
        //   setChecked(true);
        // }
        // setSearch_name_input(data.user.first_name);
        // setRole(data.user.first_name);
      });
  };
  const handleProfile = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
    setProfileValues(e.target.value);
  };
  const handleCompanytype = (selectedList, selectedItem) => {
    setCompany_type(selectedList.map((n) => n.id));
  };
  const handleMemberOftype = (selectedList, selectedItem) => {
    setMember_of(selectedList.map((n) => n.id));
  };
  const editState = useRef(null);
  const memberOf = [
    { id: "Entry 1", name: "Entry 1" },
    { id: "Entry 2", name: "Entry 2" },
  ];
  const required = (value) => {
    TabTitle("Registation");
    if (!value) {
      return (
        <div className="alert error" role="alert">
          This field is required!
        </div>
      );
    }
  };
  useEffect(() => {
    getUser();
  }, []);
  const onSetValues = (e) => {
    const { name, value } = e.target;
    if (name === "type") {
      if (value === "Legal representative") {
        setIndividualChecked(false);
        setLegalChecked(true);
        setIsTypeOn(true);
      } else {
        setLegalChecked(false);
        setIsTypeOn(false);
        setIndividualChecked(true);
      }
    }
    setRole(value);
  };
  const editUser = () => {
    axios
      .post(
        global.apiUrl + "/updateMyProfile",
        {
          id: company_id,
          company_email: company_email,
          first_name: first_name,
          last_name: last_name,
          email: email,
          password: password,
          phone: phone,
          city: city,
          street: street,
          bio: bio,
          office_location: office_location,
          company_name: company_name,
          company_website: company_website,
          company_address: company_address,
          company_size: company_size,
          company_description: company_description,
          // company_description: profileValue.company_description,
          individualChecked: individualChecked,
          legalChecked: legalChecked,
          company_type: company_type,
          type: role,
          member_of: member_of,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: global.token,
          },
        }
      )
      .then((data) => {
        // console.log(data);
        successToast("Your profile updated successfully.");
      })
      .catch((error) => {
        errorToast("Something went to wrong.");
        // console.log(error);
      });
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function sendImage(file, type) {
    // console.log(`Update image: ${file} to ${type}`);

    let _data = {
      image: file,
      image_type: type,
    };
    // console.log(_data);
    if (file && type) {
      // console.log(file);
      axios
        .post(global.apiUrl + "/updateImage", _data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: global.token,
          },
        })
        .then((data) => {
          successToast("Image update successfully.");
          if (type === 1) {
            setUserImage(data.data.data);
          } else {
            setCompanyImage(data.data.data);
          }
          // console.log(data.data.data);
        })
        .catch((error) => {
          errorToast("Something went to wrong.");
          console.log(error);
        });
    }
    if (type === 1) {
      userProfileRef.current.value = null;
    } else {
      companyProfileRef.current.value = null;
    }
  }
  function deleteProfileLogo(type) {
    // console.log(`Delete of id ${type}`);
    if (type) {
      axios
        .post(
          global.apiUrl + "/removeImage",
          {
            image_type: type,
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: global.token,
            },
          }
        )
        .then((data) => {
          successToast("Image remove successfully.");
          if (type === 1) {
            setUserImage("/assets/images/user.png");
          } else {
            setCompanyImage("/assets/images/office-building.png");
          }
          // console.log(data);
        })
        .catch((error) => {
          errorToast("Something went to wrong.");
          // console.log(error);
        });
    }
    if (type === 1) {
      userProfileRef.current.value = null;
    } else {
      companyProfileRef.current.value = null;
    }
  }
  return (
    <div className="dashboard my-profile">
      <div className="row mb-3">
        <div className="col-md-12 col-12">
          <h4 className="font-weight-500 mb-0">My Profile</h4>
        </div>
      </div>
      <>
        <div className="row">
          <div className="col-md-6">
            <div className="bg-white">
              <div className="profile-heading">
                <label className="font-weight-500 pl-2 pt-2">Profile</label>
              </div>
              <div className="d-flex mt-2 align-items-center">
                <form encType="multipart/form-data" id="userImageForm">
                  <div className="dp_box">
                    <label htmlFor="image">
                      <input
                        ref={userProfileRef}
                        type="file"
                        id="image"
                        onChange={(e) => sendImage(e.target.files[0], 1)}
                      />
                      <img src={userImage} className="profile-image" alt="" />
                      <div className="overlay">
                        <span className="btn camera">
                          <i class="fa fa-solid fa-camera"></i>
                        </span>
                      </div>
                    </label>
                    <div className="icon_box">
                      <span
                        className="btn trash"
                        onClick={function(e) {
                          deleteProfileLogo(1);
                        }}
                      >
                        <i class="fa fa-solid fa-trash"></i>
                      </span>
                    </div>
                  </div>
                </form>
                <div className="ml-2">
                  <label>
                    {user.first_name} {user.last_name}
                  </label>
                  <br />
                  <input type="text" value="Share my profile" disabled />
                </div>
              </div>
              <div className="profile-fields">
                <div className="row m-0">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="mt-2">First Name</label>
                      <input
                        ref={editState}
                        type="text"
                        name="first_name"
                        value={first_name}
                        className="form-control"
                        required
                        onChange={(e) => setFirst_name(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="mt-2">Last Name</label>
                      <input
                        name="last_name"
                        type="text"
                        // placeholder={user.last_name}
                        value={last_name}
                        onChange={(e) => setLast_name(e.target.value)}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="row m-0">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="">Email</label>
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="">Password</label>
                      <input
                        type="password"
                        name="password"
                        placeholder="********"
                        className="form-control"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <em className="text-danger" style={{ fontSize: 13 }}>
                        Leave blank if you dont't want to change
                      </em>
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="">Phone</label>
                      <input
                        type="text"
                        name="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="">City</label>
                      <input
                        type="text"
                        name="city"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="">Street</label>
                      <input
                        type="text"
                        name="street"
                        value={street}
                        onChange={(e) => setStreet(e.target.value)}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="">Bio</label>
                      <input
                        type="text"
                        name="meta_data"
                        autoComplete="new-password"
                        value={bio}
                        onChange={(e) => setBio(e.target.value)}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="">Office Location</label>
                      <input
                        type="text"
                        name="office_location"
                        value={office_location}
                        onChange={(e) => setOffice_location(e.target.value)}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white mt-4 p-3">
                <span style={{cursor: "pointer"}} className="btn-link font-weight-500 text-danger" onClick={handleShow}>Delete Your Account</span>
                {/* <label className="font-weight-500 pl-2 pt-2">Delete Your Account</label> */}
              
              {/* <div className="d-flex">
                <div className="px-2">
                  <button type="button" className="btn btn-danger btn-block my-4" onClick={handleShow}>
                    Delete Now
                  </button>                  
                </div>
              </div>               */}
            </div>
          </div>
          <div className="col-md-6">
            <div className="bg-white">
              <div className="profile-heading">
                <label className="font-weight-500 pl-2 pt-2">
                  Company Information
                </label>
              </div>
              <div className="d-flex mt-2 align-items-center">
                <form encType="multipart/form-data" id="companyImageForm">
                  <div className="dp_box">
                    <label htmlFor="company">
                      <input
                        ref={companyProfileRef}
                        type="file"
                        id="company"
                        onChange={(e) => {
                          sendImage(e.target.files[0], 2);
                        }}
                      />
                      <img
                        src={companyImage}
                        className="profile-image"
                        alt=""
                      />
                      <div className="overlay">
                        <span className="btn camera">
                          <i class="fa fa-solid fa-camera"></i>
                        </span>
                      </div>
                    </label>
                    <div className="icon_box">
                      <span
                        className="btn trash"
                        onClick={function(e) {
                          deleteProfileLogo(2);
                        }}
                      >
                        <i class="fa fa-solid fa-trash"></i>
                      </span>
                    </div>
                  </div>
                </form>
                <div className="ml-2">
                  <label>{user.company ? user.company.name : "N/A"}</label>
                  <br />
                  {/* <input type='text' value='Share my user.company' /> */}
                </div>
              </div>
              <div className="profile-fields">
                <div className="row m-0 form-group d-flex justify-content-center">
                  <div className="col-md-3">
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          value="Individual"
                          name="type"
                          onChange={onSetValues}
                          validations={[required]}
                          checked={individualChecked}
                        />
                        Individual
                      </label>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="radio">
                      <label>
                        <input
                          name="type"
                          type="radio"
                          value="Legal representative"
                          onChange={onSetValues}
                          checked={legalChecked}
                        />
                        Legal representative
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-md-12">
                    <div className="forsm-group">
                      {isTypeOn && (
                        <div className="form-group">
                          <label className="">Company Type</label>
                          <Multiselect
                            options={companyTypes} // Options to display in the dropdown
                            selectedValues={ct} // Preselected value to persist in dropdown
                            onSelect={handleCompanytype} // Function will trigger on select event
                            onRemove={handleCompanytype} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                            onChange={(e) => handleProfile(e)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row m-0">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="mt-2">Company Name</label>
                      <input
                        name="company_name"
                        value={company_name}
                        onChange={(e) => setCompany_name(e.target.value)}
                        type="text"
                        className="form-control"
                        placeholder="Company name"
                        disabled={disabled}
                        required
                        validations={[required]}
                      />
                      {/* <input type="text" value={user.company ? user.company.name : ''} disabled={disabled} className="form-control" required /> */}
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-md-12">
                    <div className="form-group">
                      {/* <label className=''>Email</label>
                                                <input type="text" value={user.company ? user.company.email : ''} disabled={disabled} className="form-control" required /> */}
                      <label className="">Company website </label>
                      <input
                        name="company_website"
                        value={company_website}
                        onChange={(e) => setCompany_website(e.target.value)}
                        type="text"
                        className="form-control"
                        placeholder="Company website"
                      />
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="">Address</label>
                      <input
                        type="text"
                        name="company_address"
                        value={company_address}
                        onChange={(e) => setCompany_address(e.target.value)}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="">
                        Company size (number of employees / freelancers){" "}
                      </label>
                      <input
                        name="company_size"
                        value={company_size}
                        onChange={(e) => setCompany_size(e.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="">Member of</label>
                      <Multiselect
                        options={memberOf} // Options to display in the dropdown
                        selectedValues={defaultMemberOf} // Preselected value to persist in dropdown
                        onSelect={handleMemberOftype} // Function will trigger on select event
                        onRemove={handleMemberOftype} // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                        // onChange={(e) => handleProfile(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className=" bg-white">
                  <div className="row m-0">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="">Description</label>
                        <textarea
                          className="form-control"                          
                          name="company_description"
                          value={company_description}
                          onChange={(e) => setCompany_description(e.target.value)}
                          rows="3">                            
                        </textarea>                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <button
              type="button"
              className="btn btn-primary btn-block my-4"
              onClick={editUser}
            >
              Submit
            </button>
          </div>
        </div>
      </>
      <Listing status={userStatus} />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Account</Modal.Title>
        </Modal.Header>        
        <Modal.Body>
          <div className="form-group">
            <label className="mt-2">Please, type word DELETE to proceed with account deletion.</label>
            <input              
              type="text"
              name="first_name"              
              className="form-control "              
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleClose}>
            Close
          </button>
          <button className="btn btn-primary">
            Submit
          </button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};
export default MyProfile;
