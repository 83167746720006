import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector, Provider } from "react-redux";
import { TabTitle } from "../../utils/GeneralFunction";
import Dropdown from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import { doPost, doGet, doDelete } from "../../actions/common";
import { successToast, errorToast, infoToast } from "../../actions/toast";
import CustomPopup from "./Demands/CustomPopup";
const MyMatches = () => {
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  TabTitle("My Demands");
  const dispatch = useDispatch();
  const [demands, setValues] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [pending, setPending] = React.useState(true);
  const [visibility, setVisibility] = useState(false);
  const [match, setMatch] = useState(null);
  const columns = [
    { name: "Date/Time", selector: (row) => row.match_date },
    { name: "Your listing", selector: (row) => yourlisting(row) },
    { name: "Contacted", selector: (row) => row.full_address },
    { name: "Match(%)", selector: (row) => row.percentage + "%" },
    {
      name: "Match Info",
      selector: (row) => (
        <div>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => viewMatchInfo(row)}
          >
            Match info
          </button>
        </div>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        //  minHeight: '50px', // override the row height
      },
    },
  };

  const yourlisting = (row) => {
    if (row.type == "property") {
      return "Property: " + row.property.title;
    } else {
      return "Demand: " + row.demand.title;
    }
  };
  const popupCloseHandler = (e) => {
    setVisibility(e);
  };

  const viewMatchInfo = (row) => {
    setVisibility(!visibility);
    setMatch(row);
  };

  const getProperties = () => {
    dispatch(doGet("matches"))
      .then((response) => {
        setValues(response.data.results);
        setPending(false);
        // successToast(response.data.message)
      })
      .catch((message) => {
        errorToast(message);
      });
  };

  const deleteProperties = () => {
    dispatch(doDelete("deleteDemands", { ids: selectedRows.map((r) => r.id) }))
      .then((response) => {
        successToast(response.data.message);
        setValues(differenceBy(demands, selectedRows, "id"));
      })
      .catch((message) => {
        errorToast(message);
      });
  };

  const formatAvailability = (str) => {
    let newString = str.charAt(0).toUpperCase() + str.slice(1);
    return newString.replace(/_/g, ",");
  };

  useEffect(() => {
    getProperties();
  }, []);

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const differenceBy = (array1, array2, key) => {
    return array1.filter((a) => !array2.some((b) => b[key] === a[key]));
  };

  const contextActions = React.useMemo(() => {
    const handleDelete = () => {
      if (
        window.confirm(
          `Are you sure you want to delete:\r ${selectedRows.map(
            (r) => r.title
          )}?`
        )
      ) {
        setToggleCleared(!toggleCleared);
        deleteProperties();
      }
    };

    return (
      <button
        key="delete"
        className="btn btn-danger"
        onClick={handleDelete}
        style={{ backgroundColor: "red" }}
        icon
      >
        Delete
      </button>
    );
  }, [demands, selectedRows, toggleCleared]);

  return (
    <div className="dashboard">
      <div className="row">
        <div className="col-md-6 col-7">
          {/* <h2 className="font-weight-500 mb-0">My Properties ({demands.length})</h2> */}
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-12">
          <DataTable
            title={"My matches"}
            columns={columns}
            data={demands}
            selectableRows
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}
            pagination
            customStyles={customStyles}
            progressPending={pending}
          />
        </div>
      </div>
      <CustomPopup
        onClose={popupCloseHandler}
        show={visibility}
        title="Match Info"
      >
        <div className="">
          {match && (
            <div>
              <table className="table table-bordered">
                <tr>
                  <th>Matched on</th>
                  <td>{match.match_date}</td>
                </tr>
                {match.type == "property" && (
                  <>
                    <tr>
                      <th>Your listing</th>
                      <td>Property: {match.property.title}</td>
                    </tr>
                    <tr>
                      <th>Matched with</th>
                      <td>
                        <a
                          target="blank"
                          href={
                            match.source + "/view/v1/" + match.sourcedata.id
                          }
                        >
                          #{match.sourcedata.id} {match.sourcedata.title}
                        </a>
                      </td>
                    </tr>
                  </>
                )}

                {match.type == "demand" && (
                  <>
                    <tr>
                      <th>Your listing</th>
                      <td>Demand: {match.demand.title}</td>
                    </tr>
                    <tr>
                      <th>Matched with</th>
                      <td>
                        <a
                          target="blank"
                          href={
                            match.source + "/match/view/" + match.sourcedata.id
                          }
                        >
                          #{match.sourcedata.id} {match.sourcedata.title}
                        </a>
                      </td>
                    </tr>
                  </>
                )}

                <tr>
                  <th>Match %</th>
                  <td>{match.percentage}%</td>
                </tr>
              </table>
              <div>
                <h3>Communication details</h3>
                {match.type == "property" && (
                  <table className="table table-bordered">
                    <tr>
                      <th>Agency</th>
                      <td>
                        {match.property.company
                          ? match.property.company.name
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <th>Agent</th>
                      <td>
                        {match.property ? match.property.user.full_name : ""}
                      </td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>{match.property ? match.property.user.email : ""}</td>
                    </tr>
                    <tr>
                      <th>Phone</th>
                      <td>{match.property ? match.property.user.phone : ""}</td>
                    </tr>
                  </table>
                )}

                {match.type == "demand" && (
                  <table className="table table-bordered">
                    <tr>
                      <th>Agency</th>
                      <td>
                        {match.demand.company ? match.demand.company.name : ""}
                      </td>
                    </tr>
                    <tr>
                      <th>Agent</th>
                      <td>{match.demand ? match.demand.user.full_name : ""}</td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>{match.demand ? match.demand.user.email : ""}</td>
                    </tr>
                    <tr>
                      <th>Phone</th>
                      <td>{match.demand ? match.demand.user.phone : ""}</td>
                    </tr>
                  </table>
                )}
              </div>
            </div>
          )}
        </div>
      </CustomPopup>
    </div>
  );
};

export default MyMatches;
