import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Link ,useParams } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { login } from "../actions/auth";
import { isEmail } from "validator";
import { useNavigate } from "react-router-dom";
import { successToast, errorToast, infoToast } from "../actions/toast";
import homexpressLogo from "../img/logos/homexpress-logo.png";
import mobileLogo from "../img/logos/homexpress-logo-sm-vert.png";
import loginGif from "../img/the-desk-without-the-talk.gif";
import { TabTitle } from "../utils/GeneralFunction";
import axios from "axios";
import "./GlobalVariables";
const API_URL = global.apiUrl;
const HEADER = global.header;
const required = (value) => {
  if (!value) {
    return (
      <div className="alert error" role="alert">
        This field is required!
      </div>
    );
  }
};
const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert error" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const Login = (props) => {
  TabTitle("Reset password");
  const {id} = useParams();
  const form = useRef();
  const checkBtn = useRef();
  const [password, setPassword] = useState("");
  const [newPassword, setMewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [checkPassword, setCheckPassword] = useState(false);
  const [emptyPassword, setEmptyPassword] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const sendNewPassword = (e) => {
    setCheckPassword(false);
    setEmptyPassword(false);
    e.preventDefault();
    if(password && newPassword){
      if(password === newPassword){
        setLoading(true);
        axios
          .post(
            API_URL + "/reset_password",
            {
              password: password,
              token:id
            },
            HEADER
          )
          .then((response) => {
            setLoading(false);
            navigate("/");
            successToast("Your password is change.");
          })
          .catch((message) => {
            setLoading(false);
            errorToast(message);
          });
      }
      else{
        setCheckPassword(true);
      }
    }else{
      setEmptyPassword(true);
    }
  };
  if (isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }
  return (
    <div className="d-flex align-items-center" style={{ height: "100%" }}>
      <div className="container-fluid" style={{ height: "100%" }}>
        <div className="row" style={{ height: "100%" }}>
          <div
            className="text-center py-5 col-md-6 d-flex flex-column justify-content-center on-desktop"
            style={{
              backgroundImage: "linear-gradient(97deg, white 85%, #f8f9fa 25%)",
            }}
          >
            <img
              className="img-fluid d-block mx-auto"
              src={homexpressLogo}
              alt=""
            />
            <h5 className="mt-4" style={{ fontWeight: 400 }}>
              Welcome back!
            </h5>
            <div className="row">
              <div className="col-md-3" />
              <div className="col-md-6">
                <p>
                  Paragraph. Then, my friend, when darkness overspreads my eyes,
                  and heaven and earth seem to dwell in my soul and absorb its
                  power, like the form of a beloved mistress, then I often think
                  with longing.
                </p>
              </div>
              <div className="col-md-3" />
            </div>
            <div className="row">
              <div className="col-md-3" />
              <div className="col-md-6">
                <img className="img-fluid d-block mt-5" src={loginGif} alt="" />
              </div>
              <div className="col-md-3" />
            </div>
          </div>
          <div className="bg-light d-flex flex-column justify-content-center col-md-6">
            <div className="row">
              <div className="row-md-12">
                <img
                  className="img-fluid d-block mx-auto mb-4 on-mobile"
                  src={mobileLogo}
                  alt=""
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <Form onSubmit={sendNewPassword} ref={form}>
                <div
                  className="bg-white mt-3 mt-sm-0 py-3 px-3 col-11 col-md-11 col-lg-6"
                  style={{
                    borderRadius: 20,
                    boxShadow: "0px 0px 10px 1px #dfdfdf",
                    margin: "0 auto",
                  }}
                >
                  <p className="lead text-center font-weight-500">Reset password</p>
                  {message && (
                    <div className="form-group">
                      <div className="alert alert-danger" role="alert">
                        {message}
                      </div>
                    </div>
                  )}
                  {checkPassword && (
                    <div className="form-group">
                      <div className="alert alert-danger" role="alert">
                        Password not match.
                      </div>
                    </div>
                  )}
                  {emptyPassword && (
                    <div className="form-group">
                      <div className="alert alert-danger" role="alert">
                        Password is empty.
                      </div>
                    </div>
                  )}
                                   
                  <div className="form-group mt-3">
                    <label className="font-weight-500">New password</label>
                    <Input
                      type="password"
                      name="newpassword"
                      value={password}
                      placeholder="New password"
                      className="form-control"
                      onChange={e => setPassword(e.target.value)}
                      validations={[required]}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label className="font-weight-500">Confirm password</label>
                    <Input
                      type="password"
                      name="confirmpassword"
                      value={newPassword}
                      placeholder="Confirm password"
                      className="form-control"
                      onChange={e => setMewPassword(e.target.value)}
                      validations={[required]}
                    />
                  </div>                  
                  <div className="form-group mt-3">
                    <button
                      className="btn btn-primary btn-block"
                      disabled={loading}
                    >
                      <span>Submit</span>
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                    </button>
                  </div>

                  <CheckButton style={{ display: "none" }} ref={checkBtn} />
                  <p className="mt-2 text-info">
                  Go to the{" "}
                    <Link to="/login">Sign in</Link>                    
                  </p>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;