import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Spinner from "../../../Component/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";
import "../../GlobalVariables";
import { TabTitle } from "../../../utils/GeneralFunction";
import Dropdown from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import { doPost, doGet, doDelete } from "../../../actions/common";
import { successToast, errorToast, infoToast } from "../../../actions/toast";
import CustomPopup from "./CustomPopup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const MyDemands = () => {
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  if (!global.token) {
    global.token = localStorage.getItem("token")
      ? "Bearer " + localStorage.getItem("token").replace(/['"]+/g, "")
      : "";
  }
  TabTitle("My Demands");
  const dispatch = useDispatch();
  const [demands, setValues] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [pending, setPending] = React.useState(true);
  const [visibility, setVisibility] = useState(false);
  const navigate = useNavigate();
  const columns = [
    { name: "Demand ID", selector: (row) => row.id },
    { name: "Demand name", selector: (row) => row.title },
    { name: "Location", selector: (row) => row.full_address },
    {
      name: "Type",
      selector: (row) => (row.property_type ? row.property_type.name : ""),
    },
    {
      name: "Availability",
      selector: (row) =>
        row.property_availabilities
          ? formatAvailability(row.property_availabilities)
          : "",
    },
    {
      name: "Year built",
      selector: (row) => (row.p_year_min || 0) + "-" + (row.p_year_max || 0),
    },
    {
      name: "Floor",
      selector: (row) =>
        (row.no_of_floor_min || 0) + "-" + (row.no_of_floor_max || 0),
    },
    {
      name: "Surface (sqm)",
      selector: (row) =>
        (row.no_of_floor_min || 0) + "-" + (row.no_of_floor_max || 0),
    },
    {
      name: "Bedrooms",
      selector: (row) =>
        (row.bedrooms_min || 0) + "-" + (row.bedrooms_min || 0),
    },
    {
      name: "Bathrooms",
      selector: (row) =>
        (row.bathrooms_min || 0) + "-" + (row.bathrooms_max || 0),
    },
    { name: "Price", selector: (row) => showPrice(row) },
    { name: "Property condition", selector: (row) => row.property_condition },
    { name: "Property Heating", selector: (row) => row.property_heating },
    { name: "Matches", selector: (row) => row.matches_count },
    { name: "Status", selector: (row) => row.status },
    {
      name: "Agent ",
      selector: (row) =>
        row.user ? `${row.user.first_name} ${row.user.last_name}` : "",
    },
    {
      cell: (row) => (
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic"></Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href={`/edit-demand/${row.id}`}>Edit</Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                // console.log(row);
                // setSelectedRows([row]);
                deleteDemand(row.id);
              }}
            >
              Delete
            </Dropdown.Item>
            <Dropdown.Item href={`/demand/view/${row.id}`}>
              View demand
            </Dropdown.Item>
            <Dropdown.Item href={`/matching-properties/${row.id}`}>
              View matches
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const showPrice = (demand) => {
    if (demand.property_availabilities == "buy") {
      return (
        new Intl.NumberFormat("de-DE", {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 0,
        }).format(demand.p_selling_min ? demand.p_selling_min : 0) +
        "-" +
        new Intl.NumberFormat("de-DE", {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 0,
        }).format(demand.p_selling_max ? demand.p_selling_max : 0)
      );
    } else if (demand.property_availabilities == "rent") {
      return (
        new Intl.NumberFormat("de-DE", {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 0,
        }).format(demand.p_renting_min ? demand.p_renting_min : 0) +
        "-" +
        new Intl.NumberFormat("de-DE", {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 0,
        }).format(demand.p_renting_max ? demand.p_renting_max : 0)
      );
    } else {
      return (
        new Intl.NumberFormat("de-DE", {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 0,
        }).format(demand.p_selling ? demand.p_selling + "/" : "") +
        new Intl.NumberFormat("de-DE", {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 0,
        }).format(demand.p_renting ? demand.p_renting : "")
      );
    }
  };
  const customStyles = {
    rows: {
      style: {
        //  minHeight: '50px', // override the row height
      },
    },
  };

  const getProperties = () => {
    dispatch(doGet("myDemands"))
      .then((response) => {
        setValues(response.data.results);
        setPending(false);
        // successToast(response.data.message)
      })
      .catch((message) => {
        errorToast(message);
      });
  };
  const popupCloseHandler = (e, row) => {
    setVisibility(e);
  };

  const popupTrigger = (row) => {
    setVisibility(!visibility);
  };

  const handleAddDemad = () => {
    // if(user.status == 'Inactive'){
    //     setVisibility(!visibility)
    //     return;
    // }
    navigate("/new-demand");
  };
  function deleteMatches(_id_) {
    axios
      .get(`${global.apiUrl}/deleteMaches/${_id_}/demand`, {
        headers: {
          Accept: "application/json",
          Authorization: global.token,
        },
      })
      .then((response) => {
        successToast("Delete successfully");
        const _finalData_ = demands.filter((a) => a.id !== _id_);
        setValues(_finalData_);
      })
      .catch((message) => {
        // errorToast(message);
      });
  }
  function deleteDemand(_id_) {
    // mrugesh start delete matches
    dispatch(doDelete("deleteDemands", { ids: [_id_] }))
      .then((response) => {
        deleteMatches(_id_);
        // successToast(response.data.message);
      })
      .catch((message) => {
        errorToast(message);
      });
    // end
  }

  const deleteDemands = () => {
    dispatch(doDelete("deleteDemands", { ids: selectedRows.map((r) => r.id) }))
      .then((response) => {
        selectedRows.forEach((element) => {
          deleteMatches(element.id);
        });
        // successToast(response.data.message);
        // setValues(differenceBy(demands, selectedRows, "id"));
      })
      .catch((message) => {
        errorToast(message);
      });
  };

  const formatAvailability = (str) => {
    let newString = str.charAt(0).toUpperCase() + str.slice(1);
    return newString.replace(/_/g, ",");
  };

  useEffect(() => {
    getProperties();
  }, []);

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const differenceBy = (array1, array2, key) => {
    return array1.filter((a) => !array2.some((b) => b[key] === a[key]));
  };

  const contextActions = React.useMemo(() => {
    const handleDelete = () => {
      if (
        window.confirm(
          `Are you sure you want to delete:\r ${selectedRows.map(
            (r) => r.title
          )}?`
        )
      ) {
        setToggleCleared(!toggleCleared);
        deleteDemands();
      }
    };

    return (
      <button
        key="delete"
        className="btn btn-danger"
        onClick={handleDelete}
        style={{ backgroundColor: "red" }}
        icon
      >
        Delete
      </button>
    );
  }, [demands, selectedRows, toggleCleared]);

  return (
    <div className="dashboard">
      <div className="row">
        <div className="col-md-6 col-7">
          {/* <h2 className="font-weight-500 mb-0">My Properties ({demands.length})</h2> */}
        </div>

        <div className="col-md-6 text-right col-5">
          <button onClick={handleAddDemad} className="btn btn-primary">
            <i className="fa fa-plus mr-2" />
            Add demand
          </button>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-12">
          <div className="overnot">
            <DataTable
              title={"My Demands (" + demands.length + ")"}
              columns={columns}
              data={demands}
              selectableRows
              contextActions={contextActions}
              onSelectedRowsChange={handleRowSelected}
              clearSelectedRows={toggleCleared}
              pagination
              customStyles={customStyles}
              progressPending={pending}
            />
          </div>
        </div>
      </div>
      <CustomPopup onClose={popupCloseHandler} show={visibility} title="Alert">
        We are verifying your account. We'll notify you when the process is
        complete so that you can add demands
      </CustomPopup>
    </div>
  );
};

export default MyDemands;
