import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Spinner from "../../Spinner";
import { useDispatch, useSelector } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { TabTitle } from "../../../utils/GeneralFunction";
import Dropdown from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import { doPost, doGet, doDelete } from "../../../actions/common";
import { successToast, errorToast, infoToast } from "../../../actions/toast";
const NewViewDemand = () => {
  TabTitle("View Demand");
  const { id } = useParams();
  const dispatch = useDispatch();
  const [demand, setValues] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [pending, setPending] = React.useState(true);
  const [showHeat, setShowHeat] = React.useState(false);
  const [showBuilding, setShowBuilding] = React.useState(false);
  const [showFeature, setshowFeature] = React.useState(false);
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [key, setKey] = useState("general");

  const [city, setCity] = useState();
  const [area, setArea] = useState();
  const [district, setDistrict] = useState();
  const [neighborhoods, setNeighborhoods] = useState();
  const [propertyType, setPropertyType] = useState();
  const [propertyId, setPropertyId] = useState();
  const [match, setMatch] = useState();

  const [agentImage, setAgentImage] = useState();
  const [agentFullName, setAgentFullName] = useState();
  const [agentEmail, setAgentEmail] = useState();
  const [agentPhone, setAgentPhone] = useState();
  const [agentOfficeLocation, setAgentOfficeLocation] = useState();

  const [companyImage, setCompanyImage] = useState();
  const [companyName, setCompanyName] = useState();
  const [companyWebsite, setCompanyWebsite] = useState();
  const [companyAddress, setCompanyAddress] = useState();

  useEffect(() => {
    getDemand();
    getMatch();
  }, []);
  const getDemand = () => {
    dispatch(doGet("demand/" + id))
      .then((response) => {
        setValues(response.data.data);
        // console.log(response.data.data);
        if (response.data.data.city) {
          setCity(response.data.data.city.name);
        }
        if (response.data.data.area) {
          setArea(response.data.data.area.name);
        }
        if (response.data.data.district) {
          setDistrict(response.data.data.district.name);
        }
        if (response.data.data.neighborhoods) {
          setNeighborhoods(response.data.data.neighborhoods.name);
        }
        if (response.data.data.property_type) {
          setPropertyType(response.data.data.property_type.name);
        }
        if (response.data.data.user.image) {
          setAgentImage(response.data.data.user.image);
        }
        setAgentFullName(response.data.data.user.full_name);
        setAgentEmail(response.data.data.user.email);
        setAgentPhone(response.data.data.user.phone);
        setAgentOfficeLocation(
          response.data.data.user.meta_data.office_location
        );
        if (response.data.data.company.logo) {
          setCompanyImage(response.data.data.company.logo);
        }
        setCompanyName(response.data.data.company.name);
        setCompanyWebsite();
        setCompanyAddress(response.data.data.user.meta_data.c_address);

        setPending(false);
      })
      .catch((message) => {
        // console.log(message);
      });
  };
  const getMatch = () => {
    dispatch(doGet("matches"))
      .then((response) => {
        // console.log(response);
        response.data.results.forEach((item, index) => {
          // console.log(item, index);
          if (item.source_id == id) {
            setMatch(item.percentage);
            setPropertyId(item.type_id);
          }
        });
        setPending(false);
      })
      .catch((message) => {
        // console.log(message);
      });
  };
  return (
    <>
      {demand && (
        <div className="dashboard">
          <div className="row">
            <div className="col-md-8 col-7">
              <h2 className="font-weight-500 mb-0">
                Demand #[{id}] <span style={{ fontSize: "1.6rem" }}>matched with property
                <Link className="pl-2" to={"/property/view/" + propertyId}>
                  #[{propertyId}]
                </Link></span>
              </h2>
            </div>
            {/* <div className="col-md-4 text-right col-5">
              <div className="row justify-content-between">
                <div className="col-6"></div>
                <div className="col-6">
                  <Link to="/add-property" className="btn btn-primary">
                    <i className="fa fa-plus mr-2" />
                    Add Property
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
          <div className="row">
            <div className="col-md-9 mb-3 pt-5 mt-5">
              <div class="bg-white py-3 px-3 rounded-3">
                <div class="d-flex align-items-center gap-3 mb-4">
                  <h4 class="font-weight-500 mb-0">{demand.title}</h4>
                  <span>{demand.property_availabilities}</span>
                </div>

                <p className="mb-5">{demand.description}</p>

                <h6 className="font-weight-500 mb-3">Property demands</h6>
                <div className="mb-4">
                  {(() => {
                    if (demand.p_selling_max) {
                      return (
                        <p className="mb-1">
                          Selling price :
                          {new Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "EUR",
                            minimumFractionDigits: 0,
                          }).format(
                            demand.p_selling_min ? demand.p_selling_min : 0
                          ) +
                            "-" +
                            new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "EUR",
                              minimumFractionDigits: 0,
                            }).format(
                              demand.p_selling_max ? demand.p_selling_max : 0
                            )}
                        </p>
                      );
                    }
                  })()}
                  {(() => {
                    if (demand.p_renting_max) {
                      return (
                        <p className="mb-1">
                          Renting price :
                          {new Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "EUR",
                            minimumFractionDigits: 0,
                          }).format(
                            demand.p_renting_min ? demand.p_renting_min : 0
                          ) +
                            "-" +
                            new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "EUR",
                              minimumFractionDigits: 0,
                            }).format(
                              demand.p_renting_max ? demand.p_renting_max : 0
                            )}
                        </p>
                      );
                    }
                  })()}
                </div>
                <div className="mb-4">
                  <p className="mb-1">
                    Location : {city && city}. {area}, {district},
                    {neighborhoods}
                  </p>
                  <p className="mb-1">Property type : {propertyType}</p>
                </div>

                <div className="mb-0">
                  <p className="mb-1">
                    No. of floors : {demand.no_of_floor_min} -{" "}
                    {demand.no_of_floor_max}
                  </p>
                  <p className="mb-1">
                    Bedrooms : {demand.bedrooms_min} - {demand.bedrooms_max}{" "}
                  </p>
                  <p className="mb-1">
                    Bathrooms : {demand.bathrooms_min} - {demand.bathrooms_max}{" "}
                  </p>
                  <p className="mb-1">
                    Property year: {demand.p_year_min} - {demand.p_year_max}{" "}
                  </p>
                  <p className="mb-1">
                    Floor area : {demand.floor_area_min} -{" "}
                    {demand.floor_area_max}
                  </p>
                  {demand.property_condition && (
                    <p className="mb-1">
                      Property condition : {demand.property_condition}
                    </p>
                  )}
                  {demand.property_heating && (
                    <p className="mb-1">
                      Property heating : {demand.property_heating}
                    </p>
                  )}
                  {(() => {
                    if (demand.features && demand.features.length !== 0) {
                      return (
                        <p className="mb-1">
                          Other features : {demand.features.toString()}
                        </p>
                      );
                    }
                  })()}
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="row right_info">
                <div className="col-12 text-right mt-5 mb-3">
                  <span
                    class="btn btn-info text-light"
                    style={{ cursor: "inherit" }}
                  >
                    <span class="badge bg-Success">{match}%</span> Match
                  </span>
                </div>
                <div className="col-12">
                  <h5 className="font-weight-500">Agent Information</h5>
                  <div class="card">
                    <div class="card-body">
                      <div className="d-flex align-items-center justify-content-between gap-3">
                        {(() => {
                          if (agentImage) {
                            return (
                              <div className="imgbox">
                                <img alt="" src={agentImage} />
                              </div>
                            );
                          }
                        })()}
                        <div className="contbox">
                          <div className="font-weight-500">{agentFullName}</div>
                          <div>{agentEmail}</div>
                          <div>{agentPhone}</div>
                          <div>{agentOfficeLocation}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <h5 className="font-weight-500">Agency Information</h5>
                  <div class="card">
                    <div class="card-body">
                      <div className="d-flex align-items-center justify-content-between gap-3">
                        {(() => {
                          if (companyImage) {
                            return (
                              <div className="imgbox">
                                <img alt="" src={companyImage} />
                              </div>
                            );
                          }
                        })()}
                        <div className="contbox">
                          <div className="font-weight-500">{companyName}</div>
                          <div>{companyWebsite}</div>
                          <div>{companyAddress}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default NewViewDemand;
