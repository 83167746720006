import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./assets/translations/en.json";
import translationEL from "./assets/translations/el.json";
const fallbackLng = ["en"];
const availableLanguages = ["en", "el"];
const resources = {
  en: {
    translation: translationEN,
  },
  el: {
    translation: translationEL,
  },
};
// console.log(resources);
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,
    detection: {
      checkWhitelist: true,
    },
    debug: false,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
