import React, { useState, useRef } from "react";
import { Navigate, Link } from "react-router-dom";
import axios from "axios";

import { isEmail } from "validator";
import { register } from "../actions/auth";
import { doPost } from "../actions/common";
import { useNavigate } from "react-router-dom";
import { TabTitle } from "../utils/GeneralFunction";
import { useDispatch, useSelector } from "react-redux";
import { successToast, errorToast, infoToast } from "../actions/toast";
import Multiselect from "multiselect-react-dropdown";

import "../Component/GlobalVariables";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import verticalLogo from "../img/logos/homexpress-logo-sm-vert.png";
// import Dropzone from 'react-dropzone';
const API_URL = global.apiUrl;
const HEADER = global.header;
if (!global.token) {
  global.token = localStorage.getItem("token")
    ? "Bearer " + localStorage.getItem("token").replace(/['"]+/g, "")
    : "";
}

const required = (value) => {
    if (!value) {
      return (
        <div className="alert error" role="alert">
          This field is required!
        </div>
      );
    }
  };
  const email = (value) => {
    if (!isEmail(value)) {
      return (
        <div className="alert error" role="alert">
          This is not a valid email.
        </div>
      );
    }
  };

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};
function Registration() {
  TabTitle("Registration"); //set the title
  let [data, setData] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [errorClass, setErrorClass] = useState("alert alert-danger");
  const [loading, setLoading] = useState(false);
  const [isTypeOn, setIsTypeOn] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const { message } = useSelector((state) => state.message);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const form = useRef();
  const checkBtn = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const companyTypes = [
    { id: "Real estate", name: "Real estate" },
    { id: "Construction company", name: "Construction company" },
    { id: "Property management", name: "Property management" },
    { id: "Investment company (AEEAP)", name: "Investment company (AEEAP)" },
  ];

  const memberOf = [
    { id: "Entry 1", name: "Entry 1" },
    { id: "Entry 2", name: "Entry 2" },
  ];

  const onSetValues = (e) => {
    const { name, value } = e.target;
    if (name === "type") {
      if (value === "Legal representative") {
        setIsTypeOn(true);
      } else {
        setIsTypeOn(false);
      }
    }
    setData({ ...data, [e.target.name]: value });
  };

  const handleRegistration = (e) => {
    e.preventDefault();
    data = { ...data, files: files };
    data = { ...data, tc: 1 };
    setLoading(true);
    dispatch(doPost('user/register/', data))
      .then((response) => {
        console.log(response);
        successToast(response.data.msg);
        // localStorage.setItem(
        //   "auth_user",response.data.token.access
        // )
  
        // localStorage.setItem(
        //   "token",response.data.token.access
        // );
        navigate('/login');
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        let message;
        let errors = [];
        console.log('errorerror',error);
        if (error.message) {
          message = "something went wrong!!";
        } else if (error.errors.non_field_errors) {
          errors = error.errors.non_field_errors;
          message = errors[0]
        }else if (error.errors.email) {
          errors = error.errors.email;
          message = errors[0]
        }else if (error.errors.tc) {
          errors = error.errors.tc;
          message = errors[0]
        } else {
          message = "something went wrong!!";
        }
        errorToast(message);
      });
  };

  const handleCompanytype = (selectedList, selectedItem) => {
    setData({ ...data, company_type: selectedList.map((n) => n.id) });
  };

  const handleMemberOftype = (selectedList, selectedItem) => {
    setData({ ...data, member_of: selectedList.map((n) => n.id) });
  };

  const [files, setFiles] = useState([]);
  function onFileUpload(event) {
    event.preventDefault();
    // Get the file Id
    let id = event.target.name;
    // Create an instance of FileReader API
    let file_reader = new FileReader();
    // Get the actual file itself
    let file = event.target.files[0];
    file_reader.onload = () => {
      // After uploading the file
      // appending the file to our state array
      // set the object keys and values accordingly
      setFiles([...files, { file_id: id, uploaded_file: file_reader.result }]);
    };
    // reading the actual uploaded file
    file_reader.readAsDataURL(file);
  }

  const handleChange = (event) => {
    // if (event.target.checked) {
    //   console.log("Checkbox is checked");
    // } else {
    //   console.log("Checkbox is NOT checked");
    // }
    let { name, value } = event.target;
    setData({ ...data, [event.target.name]: value });
    setIsSubscribed((current) => !current);
  };

  return (
    <div className="py-5 justify-content-center d-flex align-items-center bg-light">
      <div className="container">
        <div className="row justify-content-center">
          <div
            className="col-md-10 bg-white py-3 px-3 col-lg-5 col-11"
            style={{
              borderRadius: 20,
              boxShadow: "0px 0px 10px 1px #dfdfdf",
            }}
          >
            <p className="lead text-center font-weight-500">Registration</p>
            {/* {message && (
                                <div className="form-group">
                                    <div className={errorClass} role="alert">
                                        {message}
                                    </div>
                                </div>
                            )} */}
            <Form onSubmit={handleRegistration} ref={form}>
                <div className="row form-group">
                  <div className="col-md-6">
                    <label className="font-weight-500">Name</label>
                    <Input
                      name="name"
                      onChange={onSetValues}
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      validations={[required]}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="font-weight-500">email</label>
                    <Input
                      name="email"
                      onChange={onSetValues}
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      validations={[required]}
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-6 form-group">
                    <label className="font-weight-500">Password</label>
                    <Input
                      name="password"
                      onChange={onSetValues}
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      validations={[required]}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label className="font-weight-500">Confirm Password</label>
                    <Input
                      name="password2"
                      onChange={onSetValues}
                      type="password"
                      className="form-control"
                      placeholder="Confirm password"
                      validations={[required]}
                    />
                  </div>
                </div>
              <button className="btn btn-primary btn-block" disabled={loading}>
                <span>Submit</span>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
              </button>
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Registration;
