import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import "../../GlobalVariables";

function AddUser() {
  const [addUser, setAddUser] = useState([]);
  const handleInputes = (e) => {
    setAddUser({ ...addUser, [e.target.name]: e.target.value });
  };

  const [profile, setProfile] = useState([]);
  const handleImage = (e) => {
    setProfile({ image: e.target.files[0] });
  };

  function AddUserFormSubmit(e) {
    e.preventDefault();
    console.log(addUser);
    fetch(global.apiUrl + "/addUser", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: global.token,
      },
      body: JSON.stringify(addUser),
    })
      .then((result) => {
        return result.json();
      })
      .then((resp) => {
        if (resp.success === 200) {
          swal({
            title: "Succcess!",
            text: resp.message,
            icon: "success",
            button: "OK!",
          });
          setTimeout(() => {
            //window.location.href = 'my-profile';
          }, 1000);
        } else {
          console.warn(resp.error);
        }
      });
  }

  const [subscriptions, setSubscriptions] = useState([]);
  const fetchSubscriptions = () => {
    fetch(global.apiUrl + "/getSubscriptions", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setSubscriptions(data.data);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const [roles, setRoles] = useState([]);
  const fetchRoles = () => {
    fetch(global.apiUrl + "/getRoles", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setRoles(data.data);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  useEffect(() => {
    fetchRoles();
  }, []);

  return (
    <div className="dashboard">
      <div className="row">
        <div className="col-md-6 col-7">
          <h4 className="font-weight-500 mb-0">Add User</h4>
        </div>
        <div className="col-md-6 text-right col-5">
          <Link to="/my-profile" className="btn btn-primary">
            <i className="fa fa-angle-left mr-2" />
            Back
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <form onSubmit={AddUserFormSubmit}>
            <div className="row mt-3">
              <div className="col-md-6 form-group">
                <label className="font-weight-500">First Name</label>
                <input
                  name="first_name"
                  onChange={(e) => handleInputes(e)}
                  type="text"
                  className="form-control"
                  required
                />
              </div>
              <div className="col-md-6 form-group">
                <label className="font-weight-500">Last Name</label>
                <input
                  name="last_name"
                  onChange={(e) => handleInputes(e)}
                  type="text"
                  className="form-control"
                  required
                />
              </div>

              <div className="col-md-6 form-group">
                <label className="font-weight-500">Email</label>
                <input
                  name="email"
                  onChange={(e) => handleInputes(e)}
                  type="text"
                  className="form-control"
                  required
                />
              </div>
              <div className="col-md-6 form-group">
                <label className="font-weight-500">Password</label>
                <input
                  name="password"
                  onChange={(e) => handleInputes(e)}
                  type="text"
                  className="form-control"
                  required
                />
              </div>
              <div className="col-md-6 form-group">
                <label className="font-weight-500">Phone</label>
                <input
                  name="phone"
                  onChange={(e) => handleInputes(e)}
                  type="text"
                  className="form-control"
                  required
                />
              </div>
              <div className="col-md-6 form-group">
                <label className="font-weight-500">City</label>
                <input
                  name="city"
                  onChange={(e) => handleInputes(e)}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="col-md-6 form-group">
                <label className="font-weight-500">Street</label>
                <input
                  name="street"
                  onChange={(e) => handleInputes(e)}
                  type="text"
                  className="form-control"
                />
              </div>
              {/* <div className="col-md-6 form-group">
                <label className="font-weight-500">Profile</label>
                <input
                  onChange={(e) => handleImage(e)}
                  type="file"
                  className="form-control"
                  required
                />
              </div> */}
              {/* <div className="col-md-6 form-group">
                <label className="font-weight-500">Select Subscription</label>
                <select
                  name="subscription_id"
                  onChange={(e) => handleInputes(e)}
                  className="form-control"
                  required
                >
                  <option value="">Select</option>
                  {subscriptions.map((subscription, index) => (
                    <option key={index} value={subscription.id}>
                      {subscription.title}
                    </option>
                  ))}
                </select>
              </div> */}
              <div className="col-md-6 form-group">
                <label className="font-weight-500">Select Role</label>
                <select
                  name="role_id"
                  onChange={(e) => handleInputes(e)}
                  className="form-control"
                  required
                >
                  <option value="">Select</option>
                  {roles.map((role, index) => (
                    <option key={index} value={role.id}>
                      {role.description}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <button
                  type="submit"
                  className="btn btn-primary btn-block mt-3"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddUser;
