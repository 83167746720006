import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Spinner from "../../../Component/Spinner";
import { useDispatch, useSelector } from "react-redux";
import "../../GlobalVariables";
import { TabTitle } from "../../../utils/GeneralFunction";
import Dropdown from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import { doPost, doGet, doDelete } from "../../../actions/common";
import { successToast, errorToast, infoToast } from "../../../actions/toast";
import axios from "axios";
const MyPropertices = () => {
  if (!global.token) {
    global.token = localStorage.getItem("token")
      ? "Bearer " + localStorage.getItem("token").replace(/['"]+/g, "")
      : "";
  }
  TabTitle("My properties");
  const dispatch = useDispatch();
  const [properties, setValues] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [pending, setPending] = React.useState(true);

  const columns = [
    { name: "Property ID", selector: (row) => row.id },
    { name: "Property name", selector: (row) => row.title },
    { name: "Location", selector: (row) => row.full_address },
    {
      name: "Type",
      selector: (row) => (row.property_type ? row.property_type.name : ""),
    },
    {
      name: "Availability",
      selector: (row) => formatAvailability(row.property_availabilities),
    },
    { name: "Year built", selector: (row) => row.p_year },
    { name: "Floor", selector: (row) => row.prop_floor },
    { name: "Surface (sqm)", selector: (row) => row.floor_area },
    { name: "Bedrooms", selector: (row) => row.bedrooms },
    { name: "Bathrooms", selector: (row) => row.bathrooms },
    { name: "Price", selector: (row) => showPrice(row) },
    { name: "Status", selector: (row) => row.status },
    { name: "Matches", selector: (row) => row.matches_count },
    {
      name: "Agent ",
      selector: (row) =>
        row.user ? row.user.first_name + " " + row.user.last_name : "",
    },
    {
      cell: (row) => (
        <Dropdown>
          <Dropdown.Toggle variant="" id="dropdown-basic"></Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href={`/edit-property/${row.id}`}>
              Edit
            </Dropdown.Item>
            <Dropdown.Item href="#" onClick={() => deleteProperty(row.id)}>
              Delete
            </Dropdown.Item>
            <Dropdown.Item href={`/property/view/${row.id}`}>
              View property
            </Dropdown.Item>
            <Dropdown.Item href={`/matching-demands/${row.id}`}>
              View matches
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const customStyles = (state, rowInfo, column, instance) => ({
    style: {
      cursor: "pointer",
      overflow: "visible",
    },
  });

  const showPrice = (property) => {
    if (property.property_availabilities == "buy") {
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 0,
      }).format(property.p_selling);
    } else if (property.property_availabilities == "rent") {
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 0,
      }).format(property.p_renting);
    } else {
      return (
        new Intl.NumberFormat("de-DE", {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 0,
        }).format(property.p_selling ? property.p_selling + "/" : "") +
        new Intl.NumberFormat("de-DE", {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 0,
        }).format(property.p_renting ? property.p_renting : "")
      );
    }
  };

  const getProperties = () => {
    dispatch(doGet("myProperties"))
      .then((response) => {
        setValues(response.data.data);
        setPending(false);
        // successToast(response.data.message)
      })
      .catch((message) => {
        errorToast(message);
      });
  };
  function deleteMatches(_id_) {
    axios
      .get(`${global.apiUrl}/deleteMaches/${_id_}/property`, {
        headers: {
          Accept: "application/json",
          Authorization: global.token,
        },
      })
      .then((response) => {
        successToast("Delete successfully");
        // console.log(properties);
        const _finalData_ = properties.filter((a) => a.id !== _id_);
        setValues(_finalData_);
      })
      .catch((message) => {
        // errorToast(message);
      });
  }
  function deleteProperty(_id_) {
    // mrugesh start delete matches
    dispatch(doDelete("deleteProperties", { ids: [_id_] }))
      .then((response) => {
        deleteMatches(_id_);
        // successToast(response.data.message);
      })
      .catch((message) => {
        errorToast(message);
      });
    // end
  }
  const deleteProperties = (ids) => {
    // console.log({ ids: [1] });
    // return false;
    dispatch(
      doDelete("deleteProperties", {
        ids: selectedRows.map((r) => r.id),
      })
    )
      .then((response) => {
        selectedRows.forEach((element) => {
          deleteMatches(element.id);
        });
        successToast(response.data.message);
        setValues(differenceBy(properties, selectedRows, "id"));
      })
      .catch((message) => {
        errorToast(message);
      });
  };

  const formatAvailability = (str) => {
    let newString = str.charAt(0).toUpperCase() + str.slice(1);
    return newString.replace(/_/g, ",");
  };

  useEffect(() => {
    getProperties();
  }, []);

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const differenceBy = (array1, array2, key) => {
    return array1.filter((a) => !array2.some((b) => b[key] === a[key]));
  };

  const contextActions = React.useMemo(() => {
    const handleDelete = () => {
      if (
        window.confirm(
          `Are you sure you want to delete:\r ${selectedRows.map(
            (r) => r.title
          )}?`
        )
      ) {
        setToggleCleared(!toggleCleared);
        deleteProperties();
      }
    };

    return (
      <button
        key="delete"
        className="btn btn-danger"
        onClick={handleDelete}
        style={{ backgroundColor: "red" }}
        icon
      >
        Delete
      </button>
    );
  }, [properties, selectedRows, toggleCleared]);

  return (
    <div className="dashboard">
      <div className="row">
        <div className="col-md-6 col-7">
          {/* <h2 className="font-weight-500 mb-0">My Properties ({properties.length})</h2> */}
        </div>
        <div className="col-md-6 text-right col-5">
          <div className="row justify-content-between">
            <div className="col-6">
              <Link to="/import-properties" className="btn btn-primary">
                <i className="fa fa-plus mr-2" />
                Import properties
              </Link>
            </div>
            <div className="col-6">
              <Link to="/add-property" className="btn btn-primary">
                <i className="fa fa-plus mr-2" />
                Add Property
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-12">
          <div className="overnot">
            <DataTable
              title={"My properties (" + properties.length + ")"}
              columns={columns}
              data={properties}
              selectableRows
              contextActions={contextActions}
              onSelectedRowsChange={handleRowSelected}
              clearSelectedRows={toggleCleared}
              pagination
              customStyles={customStyles}
              progressPending={pending}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPropertices;
