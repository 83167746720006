import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Tabs, { TabPane } from "rc-tabs";
import housePlaceholder from "../../../img/placeholder-house.jpg";
import pdfIcon from "../../../img/download-pdf.png";
import "../../GlobalVariables";
import { doPost, doGet } from "../../../actions/common";
import { successToast, errorToast } from "../../../actions/toast";
import Multiselect from "multiselect-react-dropdown";
import { TabTitle } from "../../../utils/GeneralFunction";
import Select from "react-select";
import { useDropzone } from "react-dropzone";
import { Modal, Button } from "react-bootstrap";
import YearPicker from "react-single-year-picker";
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
  position: "relative",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const removeImg = {
  position: "absolute",
  right: "-7px",
  top: "-10px",
  background: "red",
  borderRadius: "50%",
  padding: "2px",
  width: "23px",
  textAlign: "center",
  color: "#fff",
  cursor: "pointer",
};
const EditProperty = (props) => {
  const authUser = JSON.parse(localStorage.getItem("auth_user"));
  //   console.log(authUser);
  const { id } = useParams();
  TabTitle("Edit Property"); //set the title
  const [loading, setLoading] = useState(false);
  let [property, setValues] = useState({});
  let [status, setStatus] = useState(null);
  const [neighbourhoods, setNeighbourhoods] = useState([]);
  const [defaultNeighbourhoods, setDefaultNeighbourhoods] = useState([]);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const [userinfo, setUserInfo] = useState({ languages: [], response: [] });
  const [isBuy, setIsBuy] = useState(true);
  const [isRent, setIsRent] = useState(false);
  const [propertyTypes, setPropertyTypes] = useState([]);

  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [forOpenModel, setForOpenModel] = useState(false);
  const [yearSelected, setYear] = useState();
  const statusOptions = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
    { value: "Draft", label: "Draft" },
    { value: "Sold", label: "Sold" },
    { value: "Rented", label: "Rented" },
    { value: "Offer accepted", label: "Offer accepted" },
    { value: "Withdrawn", label: "Withdrawn" },
  ];

  const updatePropertySubmit = (e) => {
    e.preventDefault();
    property = { ...property, features: userinfo.languages };
    //property = { ...property, status: status };
    property = { ...property, photos: photos };
    property = { ...property, files: pdf };
    setLoading(true);
    dispatch(doPost("updateProperty/" + id, property))
      .then((response) => {
        setLoading(false);
        successToast(response.data.message);
      })
      .catch((message) => {
        setLoading(false);
        errorToast(message);
      });
  };

  const [files, setFiles] = useState([]);
  const [pdf, setPdf] = useState([]);
  function onFileUpload(event) {
    event.preventDefault();
    // Get the file Id
    let id = event.target.name;
    // Create an instance of FileReader API
    let file_reader = new FileReader();
    // Get the actual file itself
    let file = event.target.files[0];
    file_reader.onload = () => {
      // After uploading the file
      // appending the file to our state array
      // set the object keys and values accordingly
      setPdf([...pdf, { file_id: id, uploaded_file: file_reader.result }]);
    };
    // reading the actual uploaded file
    file_reader.readAsDataURL(file);
  }
  function openModel() {
    setForOpenModel(true);
  }
  function closeModel() {
    setForOpenModel(false);
  }
  const getProperty = () => {
    dispatch(doGet("editProperty/" + id, property))
      .then((response) => {
        setValues(response.data.data);
        setYear(response.data.data.p_year);
        let property = response.data.data;
        let n = response.data.data.neighbourhoodss;
        if (n.length > 0) {
          const defaultNeighbourhoods = n.map((neighbourhood) => ({
            name: neighbourhood.name,
            id: neighbourhood.id,
          }));
          setDefaultNeighbourhoods(defaultNeighbourhoods);
        }
        let features = response.data.data.features;
        if (features.length > 0) {
          const defaultFeature = features.map((feature) => feature.id);
          setUserInfo({
            languages: defaultFeature,
            response: defaultFeature,
          });
        }

        if (property.photos) {
          setFiles(
            property.photos.map((file) =>
              Object.assign(file, {
                preview: file,
                source: "api",
              })
            )
          );
        }

        setIsBuy(false);
        setIsRent(false);
        if (property.property_availabilities == "buy") {
          setIsBuy(true);
        } else if (property.property_availabilities == "rent") {
          setIsRent(true);
        } else {
          setIsBuy(true);
          setIsRent(true);
        }
      })
      .catch((message) => {});
  };

  const getNeighbourhoods = () => {
    dispatch(doGet("getNeighbourhoods"))
      .then((response) => {
        const dropDownValue = response.data.results.map((neighbourhood) => ({
          name: neighbourhood.name,
          id: neighbourhood.id,
        }));
        setNeighbourhoods(dropDownValue);
      })
      .catch(() => {});
  };

  const fetchPropertyTypes = () => {
    dispatch(doGet("getPropertyTypes"))
      .then((response) => {
        setPropertyTypes(response.data.results);
      })
      .catch(() => {});
  };

  const [subPropertyTypes, setSubPropertyTypes] = useState([]);
  const fetchSubPropertyTypes = () => {
    dispatch(doGet("getSubPropertyTypes/" + property.property_type))
      .then((response) => {
        setSubPropertyTypes(response.data.results);
      })
      .catch(() => {});
  };
  useEffect(() => {
    if (property.property_type > 0) {
      fetchSubPropertyTypes();
    }
  }, [property.property_type]);

  const fetchCities = () => {
    dispatch(doGet("getCities"))
      .then((response) => {
        setCities(response.data.results);
      })
      .catch(() => {});
  };

  const fetchAreas = () => {
    dispatch(doGet("getAreas/" + property.city))
      .then((response) => {
        setAreas(response.data.results);
      })
      .catch(() => {});
  };
  useEffect(() => {
    if (property.city) {
      fetchAreas();
    }
  }, [property.city]);

  const fetchDistricts = () => {
    dispatch(doGet("getDistricts/" + property.area))
      .then((response) => {
        setDistricts(response.data.results);
      })
      .catch(() => {});
  };
  useEffect(() => {
    if (property.area) {
      fetchDistricts();
    }
  }, [property.area]);

  useEffect(() => {
    getProperty();
    getNeighbourhoods();
    fetchPropertyTypes();
    fetchCities();
    fetchFeatures();
  }, []);

  const handleProperty = (e) => {
    if (e.target.name == "property_availabilities") {
      setIsBuy(false);
      setIsRent(false);
      if (e.target.value == "buy") {
        setIsBuy(true);
      } else if (e.target.value == "rent") {
        setIsRent(true);
      } else {
        setIsBuy(true);
        setIsRent(true);
      }
    }
    if (e.target.name === "price_negotiable") {
      setValues({ ...property, [e.target.name]: e.target.checked ? 1 : 0 });
    } else {
      console.log(e.target.name);
      console.log(e.target.value);
      setValues({ ...property, [e.target.name]: e.target.value });
    }
  };
  function handleYearPicker(year) {
    setValues({ ...property, ["p_year"]: year });
  }
  const setStatusHandle = (e) => {
    setStatus(e.value);
  };

  const handleNeighbourhoods = (selectedList, selectedItem) => {
    setValues({
      ...property,
      neighbourhoods_id: selectedList.map((n) => n.id),
    });
  };

  const handlePropertyType = (e) => {
    setValues({ ...property, property_type: e.target.value });
  };

  const handleChangeFeatures = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { languages } = userinfo;
    if (checked) {
      setUserInfo({
        languages: [...languages, value],
      });
    } else {
      let index = languages.findIndex((f) => f == value);
      languages.splice(index, 1);
      setUserInfo({
        languages: languages,
      });
    }
  };

  const fetchNeighborhoods = () => {
    dispatch(doGet("getNeighborhoods/" + property.district))
      .then((response) => {
        setNeighborhoods(response.data.results);
      })
      .catch(() => {});
  };
  useEffect(() => {
    fetchNeighborhoods();
  }, [property.district]);

  const [features, setFeatures] = useState([]);
  const fetchFeatures = () => {
    dispatch(doGet("getFeatures/"))
      .then((response) => {
        setFeatures(response.data.results);
      })
      .catch(() => {});
  };

  async function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const [photos, setPhotos] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      var i = 0;
      let photos = [];
      setPhotos([]);
      acceptedFiles.map((file) => {
        blobToBase64(file).then((result) => {
          photos.push({ file_id: i++, uploaded_file: result });
        });
        setPhotos(photos);
      });
      var c = [
        ...files,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            source: "local",
          })
        ),
      ];
      setFiles(c);
    },
  });

  const deletePhotos = (file) => {
    if (file.source == "local") {
      let newFiles = files.filter(function(item) {
        return item.preview != file.preview;
      });
      setFiles(newFiles);
      return;
    }
    setLoading(true);
    dispatch(doPost("delete/property/image/" + property.id, { file: file }))
      .then((response) => {
        setLoading(false);
        let property = response.data.data;
        if (property.photos) {
          successToast(response.data.message);
          setFiles(
            property.photos.map((file) =>
              Object.assign(file, {
                preview: file,
              })
            )
          );
        }
        // successToast(response.data.message)
      })
      .catch((message) => {
        setLoading(false);
        errorToast(message);
      });
  };

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
      <a style={removeImg} onClick={() => deletePhotos(file)}>
        X
      </a>
    </div>
  ));

  return (
    <div className="dashboard">
      <form onSubmit={updatePropertySubmit}>
        <div className="row">
          <div className="col-md-6 col-7">
            <h2 className="font-weight-500 mb-0">Edit Property</h2>
          </div>
          <div className="col-md-6 text-right col-5">
            <div className="row">
              <div className="col-md-6"></div>
              <div className="col-md-6">
                <Link to="/my-properties" className="btn btn-primary">
                  <i className="fa fa-angle-left mr-2" /> Back
                </Link>
                <button
                  type="submit"
                  className="btn btn-primary ml-3"
                  disabled={loading}
                >
                  Save
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <Tabs tabPosition="left" defaultActiveKey="1">
            <TabPane tab="General" key="1">
              <div className="row">
                <div className="col-md-12 form-group">
                  <label className="font-weight-500">Title</label>
                  <input
                    name="title"
                    value={property.title}
                    onChange={(e) => handleProperty(e)}
                    type="text"
                    className="form-control"
                    placeholder="Title"
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 form-group">
                  <label className="font-weight-500">Description</label>
                  <textarea
                    name="description"
                    value={property.description}
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                    placeholder="Description"
                    rows="3"
                  ></textarea>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Property Year</label>
                  <YearPicker
                    value={yearSelected}
                    onSelect={(e) => {
                      setYear(e);
                      handleYearPicker(e);
                    }}
                    hideInput={false}
                    name="p_year"
                  />
                  {/* <input
                    name="p_year"
                    value={property.p_year}
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  /> */}
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Property-b-levels</label>
                  <input
                    name="b_levels"
                    value={property.b_levels}
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Plot area</label>
                  <input
                    name="plot_area"
                    value={property.plot_area}
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Field Area</label>
                  <input
                    name="field_area"
                    value={property.field_area}
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Coverage Ratio</label>
                  <input
                    name="coverage_ratio"
                    value={property.coverage_ratio}
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Floor Area Ratio</label>
                  <input
                    name="area_ratio"
                    value={property.area_ratio}
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Frontage length</label>
                  <input
                    name="frontage_length"
                    value={property.frontage_length}
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Land-depth</label>
                  <input
                    name="land_depth"
                    value={property.land_depth}
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Floor Area</label>
                  <input
                    name="floor_area"
                    value={property.floor_area}
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Bedrooms</label>
                  <input
                    name="bedrooms"
                    value={property.bedrooms}
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Bathrooms</label>
                  <input
                    name="bathrooms"
                    value={property.bathrooms}
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Property wc</label>
                  <input
                    name="property_wc"
                    value={property.property_wc}
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Parking Space</label>
                  <input
                    name="parking_space"
                    value={property.parking_space}
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Status </label>
                  {(() => {
                    if (authUser.status === "Active") {
                      return (

                        <select
                      name="status"
                      onChange={(e) => handleProperty(e)}
                      value={property.status}
                      className="form-control"
                    >
                      <option value="">Select</option>
                      {statusOptions.map((status, index) => (
                      <option key={index} value={status.value}>
                        {status.label}
                      </option>
                    ))}
                    </select>
                        
                      );
                    } else {
                      return (
                        <>
                          <Modal show={forOpenModel} onHide={closeModel}>
                            <Modal.Header closeButton>
                              <Modal.Title>Alert</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              You can update the status once we verify your
                              account.
                            </Modal.Body>
                          </Modal>
                          <input
                            readOnly
                            type="text"
                            className="form-control"
                            placeholder="Draft"
                            value="Draft"
                            onClick={openModel}
                          />
                        </>
                      );
                    }
                  })()}
                </div>
                {/* <div className="col-md-6 form-group">
                                    <label className="font-weight-500">Neighbourhoods </label>
                                    <Multiselect
                                        options={neighbourhoods} // Options to display in the dropdown
                                        selectedValues={defaultNeighbourhoods} // Preselected value to persist in dropdown
                                        onSelect={handleNeighbourhoods} // Function will trigger on select event
                                        onRemove={handleNeighbourhoods} // Function will trigger on remove event
                                        displayValue="name" // Property name to display in the dropdown options
                                        />
                                </div> */}
              </div>
            </TabPane>
            <TabPane tab="Property Details" key="2">
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Propery Type</label>
                  <select
                    value={property.property_type}
                    onChange={(e) => handlePropertyType(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    {propertyTypes.map((type, index) => (
                      <option key={index} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Sub Propery Type</label>
                  <select
                    value={property.sub_property_type}
                    name="sub_property_type"
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    {subPropertyTypes.map((subType, index) => (
                      <option key={index} value={subType.id}>
                        {subType.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Property Floor</label>
                  <select
                    name="prop_floor"
                    value={property.prop_floor}
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="Basement">Basement</option>
                    <option value="Semi basement">Semi basement</option>
                    <option value="Ground floor">Ground floor</option>
                    <option value="Mezzanine">Mezzanine</option>
                  </select>
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">No of Floors</label>
                  <select
                    name="no_of_floor"
                    value={property.no_of_floor}
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9+</option>
                  </select>
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Building condition</label>
                  <select
                    name="building_condition"
                    value={property.building_condition}
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="Excellent">Excellent</option>
                    <option value="Semi basement">Semi basement</option>
                    <option value="Very Good">Very Good</option>
                    <option value="Good">Good</option>
                    <option value="Average">Average</option>
                  </select>
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Property condition</label>
                  <select
                    name="property_condition"
                    value={property.property_condition}
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="Fully Renovated">Fully Renovated</option>
                    <option value="Renovated">Renovated</option>
                    <option value="Partially Renovated">
                      Partially Renovated
                    </option>
                    <option value="Non Renovated">Non Renovated</option>
                    <option value="Renovation Year">Renovation Year</option>
                  </select>
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Energy rating</label>
                  <select
                    name="energy_rating"
                    value={property.energy_rating}
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="A+">A+</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="B+">B+</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                    <option value="Z">Z</option>
                    <option value="H">H</option>
                    <option value="Excepted From Mandatory EPC Issuance">
                      Excepted From Mandatory EPC Issuance
                    </option>
                    <option value="EPC Under Issuance">
                      EPC Under Issuance
                    </option>
                  </select>
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Property Windows</label>
                  <select
                    name="windows"
                    value={property.windows}
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="Wooden">Wooden</option>
                    <option value="Single Glazed">Single Glazed</option>
                    <option value="Double Glazed">Double Glazed</option>
                    <option value="PVC">PVC</option>
                  </select>
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Property floor type</label>
                  <select
                    name="floor_type"
                    value={property.floor_type}
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="Tiles">Tiles</option>
                    <option value="Mosaic">Mosaic</option>
                    <option value="Marble">Marble</option>
                    <option value="Parque">Parque</option>
                    <option value="Wooden">Wooden</option>
                    <option value="Laminate">Laminate</option>
                    <option value="Vinyl">Vinyl</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Property Heating</label>
                  <select
                    name="property_heating"
                    value={property.property_heating}
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="Central Heating">Central Heating</option>
                    <option value="Autonomous Heating">
                      Autonomous Heating
                    </option>
                    <option value="Does Not Have">Does Not Have</option>
                    <option value="Oil">Oil</option>
                    <option value="Natural Gas">Natural Gas</option>
                    <option value="Heat Pump">Heat Pump</option>
                    <option value="Electrical">Electrical</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Orientation</label>
                  <select
                    name="orientation"
                    value={property.orientation}
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="North">North</option>
                    <option value="Northeast">Northeast</option>
                    <option value="Northwest">Northwest</option>
                    <option value="South">South</option>
                    <option value="Southeast">Southeast</option>
                    <option value="Southwest">Southwest</option>
                    <option value="East">East</option>
                    <option value="West">West</option>
                  </select>
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Property View</label>
                  <select
                    name="property_view"
                    value={property.property_view}
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="Openness">Openness</option>
                    <option value="Horizon">Horizon</option>
                    <option value="Panoramic">Panoramic</option>
                    <option value="Sea">Sea</option>
                    <option value="Mountain">Mountain</option>
                    <option value="Park">Park</option>
                    <option value="Acropolis">Acropolis</option>
                  </select>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Near by places" key="3">
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Distance From Sea</label>
                  <input
                    name="dis_sea"
                    value={property.dis_sea}
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">
                    Distance From Metro{" "}
                  </label>
                  <input
                    name="dis_metro"
                    value={property.dis_metro}
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">
                    Distance From Supermarket
                  </label>
                  <input
                    name="dis_supermarket"
                    value={property.dis_supermarket}
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">
                    Distance From Market{" "}
                  </label>
                  <input
                    name="dis_market"
                    value={property.dis_market}
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">
                    Distance From Pharmacy
                  </label>
                  <input
                    name="dis_pharmacy"
                    value={property.dis_pharmacy}
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">
                    Distance From School{" "}
                  </label>
                  <input
                    name="dis_school"
                    value={property.dis_school}
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">
                    Distance From Playground
                  </label>
                  <input
                    name="dis_playground"
                    value={property.dis_playground}
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Distance From Park </label>
                  <input
                    name="dis_park"
                    value={property.dis_park}
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">
                    Distance From Airport
                  </label>
                  <input
                    name="dis_airport"
                    value={property.dis_airport}
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Distance From Port </label>
                  <input
                    name="dis_port"
                    value={property.dis_port}
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>
            </TabPane>
            <TabPane tab="Prices" key="4">
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">
                    Property Availabilities
                  </label>
                  <select
                    name="property_availabilities"
                    value={property.property_availabilities}
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="buy">Buy</option>
                    <option value="rent">Rent</option>
                    <option value="buy_rent">Buy/Rent</option>
                  </select>
                </div>
                {isBuy && (
                  <div className="col-md-6 form-group">
                    <label className="font-weight-500">Price Selling</label>
                    <input
                      name="p_selling"
                      value={property.p_selling}
                      onChange={(e) => handleProperty(e)}
                      type="number"
                      min="0"
                      onKeyUp={(e) => {
                        if (e.target.value < 0) e.target.value = 1;
                      }}
                      onBlur={(e) => {
                        if (e.target.value < 0) e.target.value = 1;
                      }}
                      className="form-control"
                      placeholder=""
                    />
                  </div>
                )}

                {isRent && (
                  <div className="col-md-6 form-group">
                    <label className="font-weight-500">Price Renting</label>
                    <input
                      name="p_renting"
                      value={property.p_renting}
                      onChange={(e) => handleProperty(e)}
                      type="number"
                      min="0"
                      onKeyUp={(e) => {
                        if (e.target.value < 0) e.target.value = 1;
                      }}
                      onBlur={(e) => {
                        if (e.target.value < 0) e.target.value = 1;
                      }}
                      className="form-control"
                      placeholder=""
                    />
                  </div>
                )}

                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Expenses Enfia</label>
                  <input
                    name="exp_enfia"
                    value={property.exp_enfia}
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">
                    Expenses Electricity
                  </label>
                  <input
                    name="exp_electricity"
                    value={property.exp_electricity}
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Expenses water</label>
                  <input
                    name="exp_water"
                    value={property.exp_water}
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>

                <div className="col-md-6 d-flex align-items-center">
                  <div className="form-check">
                    <input
                      checked={property.price_negotiable}
                      className="form-check-input"
                      name="price_negotiable"
                      onChange={(e) => handleProperty(e)}
                      type="checkbox"
                    />
                    <label className="form-check-label font-weight-500">
                      Price negotiable
                    </label>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Location" key="5">
              <div className="row">
                <div className="col-md-3 form-group">
                  <label className="font-weight-500">City</label>
                  <select
                    name="city"
                    onChange={(e) => handleProperty(e)}
                    value={property.city}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    {cities.map((city, index) => (
                      <option key={index} value={city.id}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3 form-group">
                  <label className="font-weight-500">Area</label>
                  <select
                    name="area"
                    onChange={(e) => handleProperty(e)}
                    value={property.area}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    {areas
                      ? areas.map((area, index) => (
                          <option key={index} value={area.id}>
                            {area.name}
                          </option>
                        ))
                      : ""}
                  </select>
                </div>
                <div className="col-md-3 form-group">
                  <label className="font-weight-500">Districts</label>
                  <select
                    name="district"
                    onChange={(e) => handleProperty(e)}
                    value={property.district}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    {districts
                      ? districts.map((district, index) => (
                          <option key={index} value={district.id}>
                            {district.name}
                          </option>
                        ))
                      : ""}
                  </select>
                </div>
                <div className="col-md-3 form-group">
                  <label className="font-weight-500">Neighborhoods</label>
                  <select
                    name="neighborhoods"
                    onChange={(e) => handleProperty(e)}
                    value={property.neighborhoods}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    {neighborhoods
                      ? neighborhoods.map((neighborhood, index) => (
                          <option key={index} value={neighborhood.id}>
                            {neighborhood.name}
                          </option>
                        ))
                      : ""}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Address</label>
                  <input
                    name="address"
                    value={property.address}
                    onChange={(e) => handleProperty(e)}
                    type="text"
                    className="form-control"
                    placeholder=""
                  />
                  <input
                    name="address_latitude"
                    value="25.2636589"
                    onChange={(e) => handleProperty(e)}
                    type="hidden"
                    id="address-latitude"
                  />
                  <input
                    name="address_longitude"
                    value="25.2636590"
                    onChange={(e) => handleProperty(e)}
                    type="hidden"
                    id="address-longitude"
                  />
                </div>
                {/* <div className="col-md-6 form-group">
                                    <label className="font-weight-500">Near By</label>
                                    <input name='near_by' value={property.near_by} onChange={(e) => handleProperty(e)} type="text" className="form-control" placeholder="" />
                                </div> */}
              </div>
            </TabPane>
            <TabPane tab="Other Info" key="6">
              <div className="row">
                {features.map((feature, index) => (
                  <div key={index} className="col-md-3 form-group">
                    <label htmlFor={"feature_id_" + feature.id}>
                      <input
                        name="features"
                        onChange={handleChangeFeatures}
                        value={feature.id}
                        id={"feature_id_" + feature.id}
                        type="checkbox"
                        defaultChecked={
                          property.features &&
                          property.features.findIndex(
                            (f) => f.id === feature.id
                          ) != -1
                            ? true
                            : false
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"feature_id_" + feature.id}
                      >
                        {feature.feature_name}
                      </label>
                    </label>
                  </div>
                ))}
              </div>
            </TabPane>
            <TabPane tab="Media" key="7">
              <div className="row">
                <div className="col-md-12 form-group">
                  <label className="font-weight-500">Video Url</label>
                  <input
                    name="video_url"
                    value={property.video_url}
                    onChange={(e) => handleProperty(e)}
                    type="url"
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="row">
                {/* <div className="col-md-6 form-group">
                                    <label className="font-weight-500">Photos</label>
                                    <input name='main_image'  id={1} onChange={onFileUpload} type="file" className="form-control" placeholder="" />
                                    { property.main_image && (
                                    <img className="img-fluid d-block rounded" src={property.main_image} alt=""/>
                                        )
                                    }
                                </div> */}
                <div className="col-md-12 form-group">
                  <div className="col-md-12 border rounded bg-white ">
                    <div
                      {...getRootProps({
                        className:
                          "dropzone d-flex justify-content-center align-items-center",
                      })}
                    >
                      <input
                        {...getInputProps()}
                        className="form-control"
                        name="main_image"
                      />
                      <p className="text-secondary m-0 pt-3">
                        <i
                          className="fa fa-cloud-upload"
                          aria-hidden="true"
                        ></i>{" "}
                        Drag 'n' drop some files here, or click to select files
                      </p>
                    </div>
                    <aside style={thumbsContainer}>{thumbs}</aside>
                  </div>
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Video</label>
                  <input
                    name="video"
                    onChange={onFileUpload}
                    type="file"
                    className="form-control"
                    placeholder=""
                  />
                  <video width="100%" height="" controls >
                                    { property.video && (
                                            <source src={property.video} type="video/mp4"/>
                                        )
                                    }
                                    </video>
                </div>

                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Floor Plan (PDF*)</label>
                  <input
                    name="floor_plan"
                    onChange={onFileUpload}
                    accept=".pdf"
                    type="file"
                    className="form-control"
                    placeholder=""
                  />

                  {property.floor_plan && (
                    <a
                      href={property.floor_plan ? property.floor_plan : ""}
                      target="_blank"
                      download
                      className="w-100"
                    >
                      <img className="" src={pdfIcon} alt="" />
                    </a>
                  )}
                </div>
              </div>
            </TabPane>
          </Tabs>
        </div>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-10 pl-0">
            <button
              type="submit"
              className="btn btn-primary btn-block mt-3"
              disabled={loading}
            >
              Update
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default EditProperty;
