import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Link } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { login } from "../actions/auth";
import { isEmail } from "validator";
import { useNavigate } from "react-router-dom";
import { successToast, errorToast, infoToast } from "../actions/toast";
import homexpressLogo from "../img/logos/homexpress-logo.png";
import mobileLogo from "../img/logos/homexpress-logo-sm-vert.png";
import loginGif from "../img/the-desk-without-the-talk.gif";
import { TabTitle } from "../utils/GeneralFunction";
import axios from "axios";
import "../Component/GlobalVariables";
const API_URL = global.apiUrl;
const HEADER = global.header;
const required = (value) => {
  if (!value) {
    return (
      <div className="alert error" role="alert">
        This field is required!
      </div>
    );
  }
};
const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert error" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const Forgotpassword = (props) => {
  TabTitle("Forgot password");

  const form = useRef();
  const checkBtn = useRef();
  const [sendEmail, setSendEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  // const { message } = useSelector((state) => state.message);
  const [message, setMessage] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChangeUserEmail = (e) => {
    setMessage(false);
    const userEmail = e.target.value;
    setSendEmail(userEmail);
  };
  const handleForgotPassword = (e) => {
    e.preventDefault();
    if (sendEmail) {
      setLoading(true);
      axios
        .post(
          API_URL + "/forgot_password",
          {
            email: sendEmail,
          },
          HEADER
        )
        .then((response) => {
          setLoading(false);
          successToast("We have sent a reset password link to your email.");
          navigate("/");
        })
        .catch((message) => {
          setLoading(false);
          setMessage(true);
          errorToast(message);
        });
    }
  };
  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <div className="d-flex align-items-center" style={{ height: "100%" }}>
      <div className="container-fluid" style={{ height: "100%" }}>
        <div className="row" style={{ height: "100%" }}>
          <div
            className="text-center py-5 col-md-6 d-flex flex-column justify-content-center on-desktop"
            style={{
              backgroundImage: "linear-gradient(97deg, white 85%, #f8f9fa 25%)",
            }}
          >
            <img
              className="img-fluid d-block mx-auto"
              src={homexpressLogo}
              alt=""
            />
            <h5 className="mt-4" style={{ fontWeight: 400 }}>
              Welcome back!
            </h5>
            <div className="row">
              <div className="col-md-3" />
              <div className="col-md-6">
                <p>
                  Paragraph. Then, my friend, when darkness overspreads my eyes,
                  and heaven and earth seem to dwell in my soul and absorb its
                  power, like the form of a beloved mistress, then I often think
                  with longing.
                </p>
              </div>
              <div className="col-md-3" />
            </div>
            <div className="row">
              <div className="col-md-3" />
              <div className="col-md-6">
                <img className="img-fluid d-block mt-5" src={loginGif} alt="" />
              </div>
              <div className="col-md-3" />
            </div>
          </div>
          <div className="bg-light d-flex flex-column justify-content-center col-md-6">
            <div className="row">
              <div className="row-md-12">
                <img
                  className="img-fluid d-block mx-auto mb-4 on-mobile"
                  src={mobileLogo}
                  alt=""
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <Form onSubmit={handleForgotPassword} ref={form}>
                <div
                  className="bg-white mt-3 mt-sm-0 py-3 px-3 col-11 col-md-11 col-lg-6"
                  style={{
                    borderRadius: 20,
                    boxShadow: "0px 0px 10px 1px #dfdfdf",
                    margin: "0 auto",
                  }}
                >
                  <p className="lead text-center font-weight-500">
                    Forgot password
                  </p>
                  {message && (
                    <div className="form-group">
                      <div className="alert alert-danger" role="alert">
                        <span>
                          We are unable to find the email address in our
                          records.
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="form-group">
                    <label className="font-weight-500">Email address</label>
                    <Input
                      type="email"
                      placeholder="Enter email"
                      className="form-control"
                      onChange={onChangeUserEmail}
                      validations={[required, email]}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <button
                      className="btn btn-primary btn-block"
                      disabled={loading}
                    >
                      <span>Submit</span>
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                    </button>
                  </div>

                  <CheckButton style={{ display: "none" }} ref={checkBtn} />
                  <p className="mt-2 text-info">
                    Back to <Link to="/login">Sign in</Link>
                  </p>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgotpassword;
