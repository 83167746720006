import React, { Component } from 'react';
import { Link } from "react-router-dom";

// import homexpressLogo from "../../img/logos/homexpress-logo.png";
import bedroomIcon from "../../img/house-icons/small/infographics-(63b).png";
import parkingIcon from "../../img/house-icons/small/infographics-(73b).png";
import wcIcon from "../../img/house-icons/small/infographics-(79b).png";
import housePlaceholder from "../../img/placeholder-house.jpg";

class Dashboard extends Component{
    
    constructor(props) {
        super(props);
        document.title = "Dashboard";
    }


    render(){
        return(
            <div className='dashboard'>                
                <div className="bg-info1 d-flex flex-column justify-content-center">
                    <div className="row">
                        <div className="col-md-12 mb-5">
                            <p className="lead text-center font-weight-bold mb-5">
                                Welcome to Demo Panel
                            </p>
                            <div className="row">
                                <div className="col-md-6 text-center d-flex justify-content-end pr-5">
                                <Link to="/add-property" className='text-body'>
                                    <div className="bg-white py-3 px-3" style={{ width: "fit-content", borderRadius: 15 }} >
                                    <i className="fa fa-home fa-4x" />
                                    <i className="fa fa-plus fa-lg" />
                                    <p className="mb-0">Add property</p>
                                    </div>
                                    </Link>
                                </div>
                                <div className="col-md-6 text-center d-flex pl-5">
                                    <Link to="/new-demand" className='text-body'>
                                        <div className="bg-white py-3 px-3" style={{ width: "fit-content", borderRadius: 15 }}>
                                            <i className="fa fa-search fa-4x" />
                                            <i className="fa fa-plus fa-lg" />
                                            <p className="mb-0">Add demand</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Dashboard;