import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import "../../GlobalVariables";

function EditUser() {
  const { id } = useParams();
  const [userEdit, setUseredit] = useState({});
  const [roleId, setRoleId] = useState();

  useEffect(() => {
    const userId = async () => {
      fetch(global.apiUrl + "/editUser/" + id, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: global.token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setUseredit(data.data);
          setRoleId(data.data.roles[0].id);
        });
    };
    userId();
  }, []);

  const handleEdit = (e) => {
    setUseredit({ ...userEdit, [e.target.name]: e.target.value });
  };

  const handleImage = (e) => {
    var file = e.target.files[0];
    setUseredit({ ...userEdit, image: file });
  };

  // For update user
  const updateUser = (e) => {
    e.preventDefault();
    // console.log(userEdit);
    fetch(global.apiUrl + "/updateUser/" + id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: global.token,
      },
      body: JSON.stringify(userEdit),
    })
      .then((result) => {
        return result.json();
      })
      .then((resp) => {
        if (resp.success === 200) {
          swal({
            title: "Succcess!",
            text: resp.message,
            icon: "success",
            button: "OK!",
          });
          setTimeout(() => {
            //window.location.href = '/my-profile';
          }, 2000);
        } else {
          console.log(resp);
        }
      });
  };

  const [subscriptions, setSubscriptions] = useState([]);
  const fetchSubscriptions = () => {
    fetch(global.apiUrl + "/getSubscriptions", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setSubscriptions(data.data);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const [roles, setRoles] = useState([]);
  const fetchRoles = () => {
    fetch(global.apiUrl + "/getRoles", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setRoles(data.data);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  useEffect(() => {
    fetchRoles();
  }, []);

  return (
    <div className="dashboard">
      <div className="row">
        <div className="col-md-6 col-7">
          <h4 className="font-weight-500 mb-0">Edit User</h4>
        </div>
        <div className="col-md-6 text-right col-5">
          <Link to="/my-profile" className="btn btn-primary">
            <i className="fa fa-angle-left mr-2" />
            Back
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <form onSubmit={updateUser} encType="multipart/form-data">
            <div className="row mt-3">
              <div className="col-md-6 form-group">
                <label className="font-weight-500">First Name</label>
                <input
                  name="first_name"
                  value={userEdit.first_name}
                  onChange={(e) => handleEdit(e)}
                  type="text"
                  className="form-control"
                  required
                />
              </div>

              <div className="col-md-6 form-group">
                <label className="font-weight-500">Last Name</label>
                <input
                  name="last_name"
                  onChange={(e) => handleEdit(e)}
                  value={userEdit.last_name}
                  type="text"
                  className="form-control"
                  required
                />
              </div>

              <div className="col-md-6 form-group">
                <label className="font-weight-500">Email</label>
                <input
                  name="email"
                  onChange={(e) => handleEdit(e)}
                  value={userEdit.email}
                  type="text"
                  className="form-control"
                  required
                />
              </div>

              <div className="col-md-6 form-group">
                <label className="font-weight-500">Password</label>
                <input
                  name="password"
                  onChange={(e) => handleEdit(e)}
                  type="text"
                  className="form-control"
                />
                <em className="text-danger" style={{ fontSize: 13 }}>
                  Leave blank if you dont't want to change
                </em>
              </div>

              <div className="col-md-6 form-group">
                <label className="font-weight-500">Phone</label>
                <input
                  name="phone"
                  onChange={(e) => handleEdit(e)}
                  value={userEdit.phone}
                  type="text"
                  className="form-control"
                  required
                />
              </div>

              <div className="col-md-6 form-group">
                <label className="font-weight-500">City</label>
                <input
                  name="city"
                  onChange={(e) => handleEdit(e)}
                  value={userEdit.city}
                  type="text"
                  className="form-control"
                />
              </div>

              <div className="col-md-6 form-group">
                <label className="font-weight-500">Street</label>
                <input
                  name="street"
                  onChange={(e) => handleEdit(e)}
                  value={userEdit.street}
                  type="text"
                  className="form-control"
                />
              </div>

              {/* <div className="col-md-6 form-group">
                                <label className="font-weight-500">Profile</label>
                                <input onChange={(e) => handleImage(e)} type="file" className="form-control"/>
                                {   
                                    userEdit.image ? (<img src={userEdit.image} className="profile-image" alt=""  />) : ''
                                }
                            </div> */}
              {/* <div className="col-md-6 form-group">
                                <label className="font-weight-500">Select Subscription</label>
                                <select value={userEdit.subscription_id} name="subscription_id" onChange={(e) => handleEdit(e)} className="form-control" required>
                                    <option value="">Select</option>
                                    {subscriptions.map((subscription, index)=>(
                                        <option key={index} value={subscription.id}>
                                            {subscription.title}
                                        </option>
                                    ))}
                                </select>
                            </div> */}
              <div className="col-md-6 form-group">
                <label className="font-weight-500">Select Role</label>
                <select
                  name="role_id"
                  onChange={(e) => handleEdit(e)}
                  className="form-control"
                  required
                >
                  {roles.map((role, index) => (
                    <option
                      key={index}
                      value={role.id}
                      selected={roleId === role.id}
                    >
                      {role.description}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <button
                  type="submit"
                  className="btn btn-primary btn-block mt-3"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default EditUser;
