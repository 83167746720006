import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Tabs, { TabPane } from "rc-tabs";
import swal from "sweetalert";
import "../../GlobalVariables";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { doPost } from "../../../actions/common";
import { successToast, errorToast } from "../../../actions/toast";
import { useDispatch, useSelector } from "react-redux";
import { TabTitle } from "../../../utils/GeneralFunction";
import { NumericFormat } from "react-number-format";
import { useDropzone } from "react-dropzone";
import YearPicker from "react-single-year-picker";
// import Dropzone from 'react-dropzone';
if (!global.token) {
  global.token = localStorage.getItem("token")
    ? "Bearer " + localStorage.getItem("token").replace(/['"]+/g, "")
    : "";
}
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
  position: "relative",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};
const removeImg = {
  position: "absolute",
  right: "-7px",
  top: "-10px",
  background: "red",
  borderRadius: "50%",
  padding: "2px",
  width: "23px",
  textAlign: "center",
  color: "#fff",
  cursor: "pointer",
};
function AddProperty() {
  TabTitle("Add Property"); //set the title

  const authUser = JSON.parse(localStorage.getItem("auth_user"));
  // console.log(authUser.status);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [status, setStatus] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const [isBuy, setIsBuy] = useState(true);
  const [isRent, setIsRent] = useState(false);
  const navigate = useNavigate();
  const [yearSelected, setYear] = useState();
  const [userinfo, setUserInfo] = useState({
    languages: [],
    response: [],
  });
  let [addProperty, setValues] = useState({});
  const handleProperty = (e) => {
    if (e.target.name == "property_availabilities") {
      setIsBuy(false);
      setIsRent(false);
      if (e.target.value == "buy") {
        setIsBuy(true);
      } else if (e.target.value == "rent") {
        setIsRent(true);
      } else {
        setIsBuy(true);
        setIsRent(true);
      }
    }
    if (e.target.name === "price_negotiable") {
      setValues({ ...addProperty, [e.target.name]: e.target.checked ? 1 : 0 });
    } else {
      setValues({ ...addProperty, [e.target.name]: e.target.value });
    }
  };
  function handleYearPicker(year) {
    setValues({ ...addProperty, ["p_year"]: year });
  }
  const onSubmitform = (type) => {
    addPropertySubmit(type);
  };

  const addPropertySubmit = (type) => {
    // e.preventDefault();
    addProperty = { ...addProperty, features: userinfo.languages };
    addProperty = { ...addProperty, status: type };
    addProperty = { ...addProperty, photos: photos };
    addProperty = { ...addProperty, files: pdf };

    setLoading(true);

    const URI = data && data.id ? `updateProperty/${data.id}` : `addProperty`;

    dispatch(doPost(URI, addProperty))
      .then((response) => {
        // console.log(response);
        setLoading(false);
        const p = response.data.data;
        //navigate("/my-properties");
        setData(response.data.data);
        successToast(response.data.message);
        setStatus("Property " + p.title + " saved as status " + type);
      })
      .catch((error) => {
        setLoading(false);
        let message;
        let errors = [];
        if (error.message) {
          message = "something went wrong!!";
        } else if (error.error.title) {
          errors = error.error.title;
          errors.forEach((element) => (message = element));
        } else if (error.error.property_availabilities) {
          errors = error.error.property_availabilities;
          errors.forEach((element) => (message = element));
        }else if (error.error.price_negotiable) {
          errors = error.error.price_negotiable;
          errors.forEach((element) => (message = element));
        } else if (error.message) {
          message = error.message;
        } else {
          message = "something went wrong!!";
        }
        errorToast(message);
      });
  };

  const [pdf, setPdf] = useState([]);
  function onFileUpload(event) {
    event.preventDefault();
    // Get the file Id
    let id = event.target.name;
    // Create an instance of FileReader API
    let file_reader = new FileReader();
    // Get the actual file itself
    let file = event.target.files[0];
    file_reader.onload = () => {
      // After uploading the file
      // appending the file to our state array
      // set the object keys and values accordingly
      setPdf([...pdf, { file_id: id, uploaded_file: file_reader.result }]);
    };
    // reading the actual uploaded file
    file_reader.readAsDataURL(file);
  }
  const handleChangeFeatures = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { languages } = userinfo;
    if (checked) {
      setUserInfo({
        languages: [...languages, value],
      });
    } else {
      let index = languages.findIndex((f) => f == value);
      languages.splice(index, 1);
      setUserInfo({
        languages: languages,
      });
    }
  };

  const [propertyTypeId, setPropertyTypeId] = useState([]);
  const handlePropertyType = (e) => {
    setPropertyTypeId(e.target.value);
    setValues({ ...addProperty, property_type: e.target.value });
  };

  const handleNeighbourhoods = (selectedList, selectedItem) => {
    setValues({
      ...addProperty,
      neighbourhoods_id: selectedList.map((n) => n.id),
    });
  };

  const [cityId, setCityId] = useState("");
  const handleCity = (e) => {
    setCityId(e.target.value);
    setValues({ ...addProperty, city: e.target.value });
  };

  const [areaId, setAreaId] = useState("");
  const handleArea = (e) => {
    setAreaId(e.target.value);
    setValues({ ...addProperty, area: e.target.value });
  };

  const [districtId, setDistrictId] = useState("");
  const handleDistrict = (e) => {
    setDistrictId(e.target.value);
    setValues({ ...addProperty, district: e.target.value });
  };

  const [checkedFeature, setCheckedFeature] = useState([]);
  const handleFeatures = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setCheckedFeature([...checkedFeature, Number(value)]);
    } else {
      setCheckedFeature(checkedFeature.filter((e) => e !== Number(value)));
    }
    setValues({ ...addProperty, feature_ids: checkedFeature });
  };

  const addPropertySubmit1 = (e) => {
    e.preventDefault();
    addProperty = { ...addProperty, features: userinfo.languages };

    fetch(global.apiUrl + "/addProperty", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: global.token,
      },
      body: JSON.stringify(addProperty),
    })
      .then((result) => {
        return result.json();
      })
      .then((resp) => {
        if (resp.success === 200) {
          swal({
            title: "Succcess!",
            text: resp.message,
            icon: "success",
            button: "OK!",
          });
          setTimeout(() => {
            //window.location.href = '/my-properties';
          }, 2000);
        } else {
          console.log(resp);
        }
      });
  };

  const [neighbourhoods, setNeighbourhoods] = useState([]);
  const fetchData = () => {
    fetch(global.apiUrl + "/getNeighbourhoods", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const dropDownValue = data.results.map((neighbourhood) => ({
          name: neighbourhood.name,
          id: neighbourhood.id,
        }));
        // console.log(dropDownValue);
        setNeighbourhoods(dropDownValue);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [propertyTypes, setPropertyTypes] = useState([]);
  const fetchPropertyTypes = () => {
    fetch(global.apiUrl + "/getPropertyTypes", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setPropertyTypes(data.results);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  useEffect(() => {
    fetchPropertyTypes();
  }, []);

  const [subPropertyTypes, setSubPropertyTypes] = useState([]);
  const fetchSubPropertyTypes = () => {
    fetch(global.apiUrl + "/getSubPropertyTypes/" + propertyTypeId, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setSubPropertyTypes(data.results);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  useEffect(() => {
    if (propertyTypeId > 0) {
      fetchSubPropertyTypes();
    }
  }, [propertyTypeId]);

  const [cities, setCities] = useState([]);
  const fetchCities = () => {
    fetch(global.apiUrl + "/getCities", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCities(data.results);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  useEffect(() => {
    fetchCities();
  }, []);

  const [areas, setAreas] = useState([]);
  const fetchAreas = () => {
    fetch(global.apiUrl + "/getAreas/" + cityId, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setAreas(data.results);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  useEffect(() => {
    if (cityId > 0) {
      fetchAreas();
    }
  }, [cityId]);

  const [districts, setDistricts] = useState([]);
  const fetchDistricts = () => {
    fetch(global.apiUrl + "/getDistricts/" + areaId, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setDistricts(data.results);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  useEffect(() => {
    if (areaId > 0) {
      fetchDistricts();
    }
  }, [areaId]);

  const [neighborhoodses, setNeighborhoodses] = useState([]);
  const fetchNeighborhoods = () => {
    fetch(global.apiUrl + "/getNeighborhoods/" + districtId, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setNeighborhoodses(data.results);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  useEffect(() => {
    if (districtId > 0) {
      fetchNeighborhoods();
    }
  }, [districtId]);

  const [features, setFeatures] = useState([]);
  const fetchFeatures = () => {
    fetch(global.apiUrl + "/getFeatures", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setFeatures(data.results);
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };

  useEffect(() => {
    fetchFeatures();
  }, []);

  async function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const deletePhotos = (file) => {
    let newFiles = files.filter(function(item) {
      return item.preview != file;
    });
    setFiles(newFiles);
  };

  const [files, setFiles] = useState([]);
  const [photos, setPhotos] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      var i = 0;
      //let photos = [];
      setPhotos([]);
      acceptedFiles.map((file) => {
        blobToBase64(file).then((result) => {
          photos.push({ file_id: i++, uploaded_file: result });
        });
        setPhotos(photos);
      });
      // var reader = new FileReader();
      //   reader.readAsDataURL(blob);
      //   reader.onloadend = function() {
      //     var base64data = reader.result;
      //     console.log(base64data);
      //   }

      var c = [
        ...files,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ];
      setFiles(c);
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
      <a style={removeImg} onClick={() => deletePhotos(file.preview)}>
        X
      </a>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <div className="dashboard">
      <form onSubmit={addPropertySubmit}>
        <div className="row">
          <div className="col-md-6 col-7">
            <h4 className="font-weight-500 mb-0">Add Property</h4>
          </div>
          <div className="col-md-6 text-right col-5">
            <Link to="/my-properties" className="btn btn-primary">
              <i className="fa fa-angle-left mr-2" /> Back
            </Link>
            <button
              type="button"
              onClick={() => onSubmitform("Draft")}
              className="btn btn-primary ml-3"
              disabled={loading}
            >
              Save as draft
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
            </button>
            {authUser.status === "Active" && (
              <button
                type="button"
                onClick={() => onSubmitform("Active")}
                className="btn btn-primary ml-3"
                disabled={loading}
              >
                Publish
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
              </button>
            )}
          </div>
        </div>

        {status && (
          <div class="alert alert-success mt-4">
            {status}
            <Link to="/my-properties" className="">
              <i className="fa fa-angle-left mr-2 ml-2" /> Go to My properties
            </Link>
          </div>
        )}
        <div className="mt-4">
          <Tabs tabPosition="left" defaultActiveKey="1">
            <TabPane tab="General" key="1">
              <div className="row">
                <div className="col-md-12 form-group">
                  <label className="font-weight-500">Title</label>
                  <input
                    type="hidden"
                    name="user_id"
                    value={authUser.id}
                    onChange={(e) => handleProperty(e)}
                  />
                  <input
                    name="title"
                    onChange={(e) => handleProperty(e)}
                    type="text"
                    className="form-control"
                    placeholder="Title"
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 form-group">
                  <label className="font-weight-500">Description</label>
                  <textarea
                    name="description"
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                    placeholder="Description"
                    rows="3"
                  ></textarea>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Property Year</label>
                  <YearPicker
                    value={yearSelected}
                    onSelect={(e) => {
                      setYear(e);
                      handleYearPicker(e);
                    }}
                    hideInput={false}
                    name="p_year"
                  />
                  {/* <input
                    name="p_year"
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  /> */}
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Property-b-levels</label>
                  <input
                    name="b_levels"
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Plot area</label>
                  <input
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    name="plot_area"
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Field Area</label>
                  <input
                    name="field_area"
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Coverage Ratio</label>
                  <input
                    name="coverage_ratio"
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Floor Area Ratio</label>
                  <input
                    name="area_ratio"
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Frontage length</label>
                  <input
                    name="frontage_length"
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Land-depth</label>
                  <input
                    name="land_depth"
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Floor Area</label>
                  <input
                    name="floor_area"
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Bedrooms</label>
                  <input
                    name="bedrooms"
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Bathrooms</label>
                  <input
                    name="bathrooms"
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Property wc</label>
                  <input
                    name="property_wc"
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Parking Space</label>
                  <input
                    name="parking_space"
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                {/* <div className="col-md-6 form-group">
                                    <label className="font-weight-500">Neighbourhoods </label>
                                    <Multiselect
                                        options={neighbourhoods} // Options to display in the dropdown
                                        onSelect={handleNeighbourhoods} // Function will trigger on select event
                                        onRemove={handleNeighbourhoods} // Function will trigger on remove event
                                        displayValue="name" // Property name to display in the dropdown options
                                        />
                                </div> */}
              </div>
            </TabPane>
            <TabPane tab="Property Details" key="2">
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Propery Type</label>
                  <select
                    onChange={(e) => handlePropertyType(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    {propertyTypes.map((type, index) => (
                      <option key={index} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Sub Propery Type</label>
                  <select
                    name="sub_property_type"
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    {subPropertyTypes.map((subType, index) => (
                      <option key={index} value={subType.id}>
                        {subType.name}
                      </option>
                    ))}
                  </select>
                </div>
                {/* <PropertyTypes handleChange={e => handleProperty(e)}/> */}
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Property Floor</label>
                  <select
                    name="prop_floor"
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="Basement">Basement</option>
                    <option value="Semi basement">Semi basement</option>
                    <option value="Ground floor">Ground floor</option>
                    <option value="Mezzanine">Mezzanine</option>
                  </select>
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">No of Floors</label>
                  <select
                    name="no_of_floor"
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9+</option>
                  </select>
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Building condition</label>
                  <select
                    name="building_condition"
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="Excellent">Excellent</option>
                    <option value="Semi basement">Semi basement</option>
                    <option value="Very Good">Very Good</option>
                    <option value="Good">Good</option>
                    <option value="Average">Average</option>
                  </select>
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Property condition</label>
                  <select
                    name="property_condition"
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="Fully Renovated">Fully Renovated</option>
                    <option value="Renovated">Renovated</option>
                    <option value="Partially Renovated">
                      Partially Renovated
                    </option>
                    <option value="Non Renovated">Non Renovated</option>
                    <option value="Renovation Year">Renovation Year</option>
                  </select>
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Energy rating</label>
                  <select
                    name="energy_rating"
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="A+">A+</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="B+">B+</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                    <option value="Z">Z</option>
                    <option value="H">H</option>
                    <option value="Excepted From Mandatory EPC Issuance">
                      Excepted From Mandatory EPC Issuance
                    </option>
                    <option value="EPC Under Issuance">
                      EPC Under Issuance
                    </option>
                  </select>
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Property Windows</label>
                  <select
                    name="windows"
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="Wooden">Wooden</option>
                    <option value="Single Glazed">Single Glazed</option>
                    <option value="Double Glazed">Double Glazed</option>
                    <option value="PVC">PVC</option>
                  </select>
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Property floor type</label>
                  <select
                    name="floor_type"
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="Tiles">Tiles</option>
                    <option value="Mosaic">Mosaic</option>
                    <option value="Marble">Marble</option>
                    <option value="Parque">Parque</option>
                    <option value="Wooden">Wooden</option>
                    <option value="Laminate">Laminate</option>
                    <option value="Vinyl">Vinyl</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Property Heating</label>
                  <select
                    name="property_heating"
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="Central Heating">Central Heating</option>
                    <option value="Autonomous Heating">
                      Autonomous Heating
                    </option>
                    <option value="Does Not Have">Does Not Have</option>
                    <option value="Oil">Oil</option>
                    <option value="Natural Gas">Natural Gas</option>
                    <option value="Heat Pump">Heat Pump</option>
                    <option value="Electrical">Electrical</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Orientation</label>
                  <select
                    name="orientation"
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="North">North</option>
                    <option value="Northeast">Northeast</option>
                    <option value="Northwest">Northwest</option>
                    <option value="South">South</option>
                    <option value="Southeast">Southeast</option>
                    <option value="Southwest">Southwest</option>
                    <option value="East">East</option>
                    <option value="West">West</option>
                  </select>
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Property View</label>
                  <select
                    name="property_view"
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="Openness">Openness</option>
                    <option value="Horizon">Horizon</option>
                    <option value="Panoramic">Panoramic</option>
                    <option value="Sea">Sea</option>
                    <option value="Mountain">Mountain</option>
                    <option value="Park">Park</option>
                    <option value="Acropolis">Acropolis</option>
                  </select>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Near by places" key="3">
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Distance From Sea</label>
                  <input
                    name="dis_sea"
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">
                    Distance From Metro{" "}
                  </label>
                  <input
                    name="dis_metro"
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">
                    Distance From Supermarket
                  </label>
                  <input
                    name="dis_supermarket"
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">
                    Distance From Market{" "}
                  </label>
                  <input
                    name="dis_market"
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">
                    Distance From Pharmacy
                  </label>
                  <input
                    name="dis_pharmacy"
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">
                    Distance From School{" "}
                  </label>
                  <input
                    name="dis_school"
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">
                    Distance From Playground
                  </label>
                  <input
                    name="dis_playground"
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Distance From Park </label>
                  <input
                    name="dis_park"
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">
                    Distance From Airport
                  </label>
                  <input
                    name="dis_airport"
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Distance From Port </label>
                  <input
                    name="dis_port"
                    onChange={(e) => handleProperty(e)}
                    type="number"
                    min="0"
                    onKeyUp={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) e.target.value = 1;
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>
            </TabPane>
            <TabPane tab="Prices" key="4">
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">
                    Property Availabilities
                  </label>
                  <select
                    name="property_availabilities"
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="buy">Buy</option>
                    <option value="rent">Rent</option>
                    <option value="buy_rent">Buy/Rent</option>
                  </select>
                </div>
                {isBuy && (
                  <div className="col-md-6 form-group">
                    <label className="font-weight-500">Price Selling</label>
                    <NumericFormat
                      name="p_selling"
                      onChange={(e) => handleProperty(e)}
                      type="text"
                      className="form-control"
                      placeholder=""
                      thousandSeparator={"."}
                      decimalSeparator={","}
                    />
                  </div>
                )}
                {isRent && (
                  <div className="col-md-6 form-group">
                    <label className="font-weight-500">Price Renting</label>

                    <NumericFormat
                      name="p_renting"
                      onChange={(e) => handleProperty(e)}
                      type="text"
                      className="form-control"
                      placeholder=""
                      thousandSeparator={"."}
                      decimalSeparator={","}
                    />
                  </div>
                )}

                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Expenses Enfia</label>

                  <NumericFormat
                    name="exp_enfia"
                    onChange={(e) => handleProperty(e)}
                    type="text"
                    className="form-control"
                    placeholder=""
                    thousandSeparator={"."}
                    decimalSeparator={","}
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">
                    Expenses Electricity
                  </label>

                  <NumericFormat
                    name="exp_electricity"
                    onChange={(e) => handleProperty(e)}
                    type="text"
                    className="form-control"
                    placeholder=""
                    thousandSeparator={"."}
                    decimalSeparator={","}
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Expenses water</label>
                  <NumericFormat
                    name="exp_water"
                    onChange={(e) => handleProperty(e)}
                    type="text"
                    className="form-control"
                    placeholder=""
                    thousandSeparator={"."}
                    decimalSeparator={","}
                  />
                </div>
                <div className="col-md-6 d-flex align-items-center">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      name="price_negotiable"
                      onChange={(e) => handleProperty(e)}
                      type="checkbox"
                    />
                    <label className="form-check-label font-weight-500">
                      Price negotiable
                    </label>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Location" key="5">
              <div className="row">
                <div className="col-md-3 form-group">
                  <label className="font-weight-500">City</label>
                  <select
                    onChange={(e) => handleCity(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    {cities.map((city, index) => (
                      <option key={index} value={city.id}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3 form-group">
                  <label className="font-weight-500">Area</label>
                  <select
                    onChange={(e) => handleArea(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    {areas
                      ? areas.map((area, index) => (
                          <option key={index} value={area.id}>
                            {area.name}
                          </option>
                        ))
                      : ""}
                  </select>
                </div>
                <div className="col-md-3 form-group">
                  <label className="font-weight-500">Districts</label>
                  <select
                    onChange={(e) => handleDistrict(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    {districts
                      ? districts.map((district, index) => (
                          <option key={index} value={district.id}>
                            {district.name}
                          </option>
                        ))
                      : ""}
                  </select>
                </div>
                <div className="col-md-3 form-group">
                  <label className="font-weight-500">Neighborhoods</label>
                  <select
                    name="neighborhoods"
                    onChange={(e) => handleProperty(e)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    {neighborhoodses
                      ? neighborhoodses.map((neighborhoods, index) => (
                          <option key={index} value={neighborhoods.id}>
                            {neighborhoods.name}
                          </option>
                        ))
                      : ""}
                  </select>
                </div>
                {/* <Cities handleChange={e => handleProperty(e)}/> */}
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Address</label>
                  <input
                    name="address"
                    onChange={(e) => handleProperty(e)}
                    type="text"
                    className="form-control"
                    placeholder=""
                  />
                  <input
                    type="hidden"
                    onChange={(e) => handleProperty(e)}
                    name="address_latitude"
                  />
                  <input
                    type="hidden"
                    onChange={(e) => handleProperty(e)}
                    name="address_longitude"
                  />
                </div>
                {/* <div className="col-md-6 form-group">
                                    <label className="font-weight-500">Near By</label>
                                    <input name='near_by' onChange={(e) => handleProperty(e)} type="text" className="form-control" placeholder="" />
                                </div> */}
              </div>
            </TabPane>
            <TabPane tab="Other Info" key="6">
              <div className="row">
                {features.map((feature, index) => (
                  <div key={index} className="col-md-3 form-group">
                    <label htmlFor={"feature_id_" + feature.id}>
                      <input
                        name="features"
                        onChange={handleChangeFeatures}
                        value={feature.id}
                        id={"feature_id_" + feature.id}
                        type="checkbox"
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"feature_id_" + feature.id}
                      >
                        {feature.feature_name}
                      </label>
                    </label>
                  </div>
                ))}
              </div>
            </TabPane>
            <TabPane tab="Media" key="7">
              <div className="row">
                <div className="col-md-12 form-group">
                  <label className="font-weight-500">Video Url</label>
                  <input
                    name="video_url"
                    onChange={onFileUpload}
                    type="url"
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 form-group">
                  <label className="font-weight-500">Photos</label>
                  {/* <input
                    name="main_image"
                    onChange={onFileUpload}
                    type="file"
                    className="form-control"
                    placeholder=""
                    multiple
                  /> */}
                  <div className="col-md-12 border rounded bg-white ">
                    <div
                      {...getRootProps({
                        className:
                          "dropzone d-flex justify-content-center align-items-center",
                      })}
                    >
                      <input
                        {...getInputProps()}
                        className="form-control"
                        name="main_image"
                      />
                      <p className="text-secondary m-0 pt-3">
                        <i class="fa fa-cloud-upload" aria-hidden="true"></i>{" "}
                        Drag 'n' drop some files here, or click to select files
                      </p>
                    </div>
                    <aside style={thumbsContainer}>{thumbs}</aside>
                  </div>
                </div>
                {/* <div className="col-md-6 form-group">
                                    <label className="font-weight-500">Cover Image</label>
                                    <input name='cover_image' onChange={onFileUpload} type="file" className="form-control" placeholder="" />
                                </div> */}
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Video</label>
                  <input
                    name="video"
                    onChange={onFileUpload}
                    type="file"
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="font-weight-500">Floor Plan (PDF*)</label>
                  <input
                    name="floor_plan"
                    onChange={onFileUpload}
                    type="file"
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>
            </TabPane>
          </Tabs>
        </div>
        {/* <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-5 pl-0">
            <button
              type="button"
              onClick={() => onSubmitform("Draft")}
              className="btn btn-primary btn-block mt-3"
              disabled={loading}
            >
              Save as draft
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
            </button>
          </div>
        </div> */}
      </form>
    </div>
  );
}
export default AddProperty;
