import React from "react";
import "./App.css";
import "./styles/my-theme.css";
import "../node_modules/rc-tabs/assets/index.css";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next"; 
import { useDispatch, useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import Header from './Component/Header';
// import Home from './Component/Home';
import Login from "./Component/Login";
import Registration from "./Component/Registration";
import Forgotpassword from "./Component/Forgotpassword";
import Resetpassword from "./Component/resetpassword";
import EmailConfiguration from "./Component/emailConfiguration";
// import Footer from './Component/Footer';

import FrontendHeader from "./Component/Frontend/Header";

import Dashboard from "./Component/Frontend/Dashboard";
import UploadCsv from "./Component/UploadCsv";
import ProductListing from "./Component/ProductListing";

import MyProperties from "./Component/Frontend/Propertices/MyProperties";
import ViewProperty from "./Component/Frontend/Propertices/ViewProperty";

import ImportProperties from "./Component/Frontend/Propertices/ImportProperties";

import AddProperty from "./Component/Frontend/Propertices/AddProperty";
import EditProperty from "./Component/Frontend/Propertices/EditProperty";
import MatchingDemands from "./Component/Frontend/Propertices/MatchingDemands";

import MyDemands from "./Component/Frontend/Demands/MyDemands";
import ViewDemand from "./Component/Frontend/Demands/ViewDemand";
import NewViewDemand from "./Component/Frontend/Demands/NewViewDemand";
import AddDemand from "./Component/Frontend/Demands/AddDemand";
import EditDemand from "./Component/Frontend/Demands/EditDemand";
import MatchingProperties from "./Component/Frontend/Demands/MatchingProperties";

import MyProfile from "./Component/Frontend/Users/MyProfile";
import AddUser from "./Component/Frontend/Users/AddUser";
import EditUser from "./Component/Frontend/Users/EditUser";

import MyMatches from "./Component/Frontend/MyMatches";

import FrontendSidebar from "./Component/Frontend/Sidebar";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ProtectedRoute from "./Component/ProtectedRoute";

const App = () => {
  const { t } = useTranslation();
  const { isLoggedIn } = useSelector((state) => state.auth);
  return (
    <Router>
     
      <ToastContainer />
      {isLoggedIn && <FrontendHeader />}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Dashboard />} />
        <Route
          path="/dashboard"
          element={!isLoggedIn ? <Navigate to="/" /> : <Dashboard />}
        />
        <Route path="/registration" element={<Registration />} />
        <Route path="/Forgotpassword" element={<Forgotpassword />} />
        <Route path="/resetpassword/:id" element={<Resetpassword />} />
        <Route
          path="/uploadCsv"
          element={!isLoggedIn ? <Navigate to="/" /> : <UploadCsv />}
        />
        <Route
          path="/productListing"
          element={!isLoggedIn ? <Navigate to="/" /> : <ProductListing />}
        />
        <Route
          path="/my-profile"
          element={!isLoggedIn ? <Navigate to="/" /> : <MyProfile />}
        />
        <Route
          path="/add-user"
          element={!isLoggedIn ? <Navigate to="/" /> : <AddUser />}
        />
        <Route
          path="/edit-user/:id"
          element={!isLoggedIn ? <Navigate to="/" /> : <EditUser />}
        />

        <Route
          path="/my-properties"
          element={!isLoggedIn ? <Navigate to="/" /> : <MyProperties />}
        />
        <Route
          path="/import-properties"
          element={!isLoggedIn ? <Navigate to="/" /> : <ImportProperties />}
        />
        <Route
          path="/add-property"
          element={!isLoggedIn ? <Navigate to="/" /> : <AddProperty />}
        />
        <Route
          path="/edit-property/:id"
          element={!isLoggedIn ? <Navigate to="/" /> : <EditProperty />}
        />
        <Route
          path="/matching-demands"
          element={!isLoggedIn ? <Navigate to="/" /> : <MatchingDemands />}
        />

        <Route
          path="/my-demands"
          element={!isLoggedIn ? <Navigate to="/" /> : <MyDemands />}
        />
        <Route
          path="/new-demand"
          element={!isLoggedIn ? <Navigate to="/" /> : <AddDemand />}
        />
        <Route
          path="/edit-demand/:id"
          element={!isLoggedIn ? <Navigate to="/" /> : <EditDemand />}
        />
        <Route
          path="/demand/view/:id"
          element={!isLoggedIn ? <Navigate to="/" /> : <ViewDemand />}
        />

        <Route
          path="/demand/view/v1/:id"
          element={!isLoggedIn ? <Navigate to="/" /> : <NewViewDemand />}
        />

        <Route
          path="/matching-properties/:id"
          element={!isLoggedIn ? <Navigate to="/" /> : <MatchingProperties />}
        />

        <Route
          path="/matching-demands/:id"
          element={!isLoggedIn ? <Navigate to="/" /> : <MatchingDemands />}
        />

        <Route
          path="/property/view/:id"
          element={!isLoggedIn ? <Navigate to="/" /> : <ViewProperty />}
        />

        <Route
          path="/property/:page/view/:id"
          element={!isLoggedIn ? <Navigate to="/" /> : <ViewProperty />}
        />

        <Route
          path="/my-matches"
          element={!isLoggedIn ? <Navigate to="/" /> : <MyMatches />}
        />
      </Routes>
    </Router>
  );
};

export default App;
