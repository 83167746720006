import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Spinner from "../../Spinner";
import { useDispatch, useSelector } from "react-redux";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import { TabTitle } from "../../../utils/GeneralFunction";
import Dropdown from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import { doPost, doGet, doDelete } from "../../../actions/common";
import { successToast, errorToast, infoToast } from "../../../actions/toast";
import ViewIcon69 from "../../../img/house-icons/infographics-(69).png";
import ViewIcon63 from "../../../img/house-icons/infographics-(63).png";
import ViewIcon65 from "../../../img/house-icons/infographics-(65).png";
import ViewIcon67 from "../../../img/house-icons/infographics-(67).png";
import ViewIcon73 from "../../../img/house-icons/infographics-(73).png";
import ViewIcon81 from "../../../img/house-icons/infographics-(81).png";
import ViewIcon75 from "../../../img/house-icons/infographics-(75).png";
import ViewIcon78 from "../../../img/house-icons/infographics-(78).png";
// import ViewIcon73 from "../../../img/house-icons/infographics-(73).png";
import ViewIcon79 from "../../../img/house-icons/infographics-(79).png";
import ViewIcon80 from "../../../img/house-icons/infographics-(80).png";
import ViewIcon101 from "../../../img/house-icons/infographics-(101).png";
import TypeIcon1 from "../../../img/house-icons/Τύπος-ιδιοκτησίας-infographics.png";
import { getCardActionAreaUtilityClass } from "@mui/material";

const ViewDemand = () => {
  TabTitle("View Demand");
  const { id } = useParams();
  const dispatch = useDispatch();
  const [demand, setValues] = useState([]);
  const [pending, setPending] = React.useState(true);
  const [cities, setCities] = useState({});
  const [Areas, setAreas] = useState({});
  const getDemand = () => {
    dispatch(doGet("demand/" + id))
      .then((response) => {
        setValues(response.data.data);
        setPending(false);
      })
      .catch((message) => {
        errorToast(message);
      });
  };
  useEffect(() => {
    getDemand();
    fetchCities();
  }, []);
  const fetchCities = () => {
    fetch(global.apiUrl + "/getCities", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: global.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.results) {
          let tempData = {};
          let tempAera = {};
          data.results.forEach((element) => {
            // console.log(element);
            tempData[element.id] = element.name;
            element.nested_children.forEach((_element) => {
              tempAera[_element.id] = _element.name;
            });
            setAreas(tempAera);
          });
          setCities(tempData);
        }
      })
      .catch((err) => {
        console.log("Error Reading data " + err);
      });
  };
  return (
    <div className="dashboard">
      <div className="bg-info1 d-flex flex-column justify-content-center">
        <div className="row">
          <div className="col-md-12 mb-5">
            <div className="row">
              <h3 class="font-weight-500 mb-0">Damand Info</h3>
            </div>
            {demand && (
              <div className="row mt-4">
                <div className="col-md-12">
                  <div
                    className="bg-white py-3 px-3"
                    style={{ borderRadius: 15 }}
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <div className="damandwrap">
                        <table className="DamandInfoTable">
                          <thead>
                            <tr>
                              <th>Demand name</th>
                              <th>Location</th>
                              <th>Type</th>
                              <th>Availability</th>
                              <th>Year built</th>
                              <th>Floor</th>
                              <th>Surface (sqm)</th>
                              <th>Bedrooms</th>
                              <th>Bathrooms</th>
                              <th>Price</th>
                              <th>Property condition</th>
                              <th>Property Heating</th>
                              <th>Matches</th>
                              <th>Status</th>
                              <th>Agent</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{demand.title}</td>
                              <td>{demand.full_address}</td>
                              <td>{(() => {
                                if (demand.property_type) {
                                  return demand.property_type.name;
                                }
                              })()}</td>
                              <td>{demand.property_availabilities}</td>
                              <td>{demand.p_year_min}-{demand.p_year_max}</td>
                              <td>{demand.no_of_floor_min}-
                                {demand.no_of_floor_max}</td>
                              <td>{demand.sub_property_type}</td>
                              <td>{demand.bedrooms_min}-
                                {demand.bedrooms_max}</td>
                              <td>{demand.bathrooms_min}-
                                {demand.bathrooms_max}</td>
                              <td>{new Intl.NumberFormat("de-DE", {
                                style: "currency",
                                currency: "EUR",
                                minimumFractionDigits: 0,
                              }).format(
                                demand.p_selling_min ? demand.p_selling_min : 0
                              ) +
                                "-" +
                                new Intl.NumberFormat("de-DE", {
                                  style: "currency",
                                  currency: "EUR",
                                  minimumFractionDigits: 0,
                                }).format(
                                  demand.p_selling_max ? demand.p_selling_max : 0
                                )}</td>
                              <td>{demand.property_condition}</td>
                              <td>{demand.property_heating}</td>
                              <td>{demand.matches_count}</td>
                              <td>{demand.status}</td>
                              <td>{" "}
                                {(() => {
                                  if (demand.user) {
                                    return demand.user.full_name;
                                  }
                                })()}</td>
                            </tr>
                          </tbody>
                        </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-5">
            <div className="row">
              <h3 class="font-weight-500 mb-0">Matchs</h3>
            </div>
            {(() => {
              if (demand.matches) {
                return demand.matches.map((demandMatchesData, _id_) => (
                  <div className="row mt-4" key={demandMatchesData.id}>
                    <div className="col-md-6">
                      <div
                        className="bg-white py-3 px-3"
                        style={{ borderRadius: 15 }}
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <h5 className="font-weight-500 mb-3">
                            {demandMatchesData.percentage}% Match
                          </h5>
                          <h6 className="font-weight-500">
                            <Link
                              className=""
                              to={
                                "/property/view/" +
                                demandMatchesData.property.id
                              }
                            >
                              #{demandMatchesData.property.id}
                            </Link>
                          </h6>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            {(() => {
                              if (demandMatchesData.property.photos) {
                                return (
                                  <img
                                    className="img-fluid d-block rounded mb-3"
                                    src={demandMatchesData.property.photos[0]}
                                    alt=""
                                  />
                                );
                              }
                            })()}
                            <div className="border p-3">
                              <div className="d-flex align-items-center justify-content-start gap-3">
                                {(() => {
                                  if (demandMatchesData.property.user.image) {
                                    return (
                                      <img
                                        className="img-fluid d-block rounded-circle"
                                        src={
                                          demandMatchesData.property.user.image
                                        }
                                        width={100}
                                        height={100}
                                        alt=""
                                      />
                                    );
                                  }
                                })()}
                                <div>
                                  <p className="font-weight-500 mb-0">
                                    {demandMatchesData.property.user.full_name}
                                  </p>
                                  <p className="mb-0">
                                    {demandMatchesData.property.company.name}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <p className="font-weight-500 mb-0">
                              {Areas[demandMatchesData.property.area]},
                              {cities[demandMatchesData.property.city]}
                            </p>
                            <p className="mb-0">
                              For{" "}
                              {
                                demandMatchesData.property
                                  .property_availabilities
                              }
                            </p>

                            <div className="row">
                              <div className="col-12">
                                <div className="d-inline-block mt-2">
                                  <img
                                    className=""
                                    src={ViewIcon63}
                                    alt=""
                                    width={29}
                                  />
                                  <p className="d-inline-block mb-0 font-weight-500">
                                    {demandMatchesData.property.bedrooms}
                                  </p>
                                </div>
                                <div className="d-inline-block ml-3 mt-2">
                                  <img
                                    className=""
                                    src={ViewIcon65}
                                    alt=""
                                    width={29}
                                  />
                                  <p className="d-inline-block mb-0 font-weight-500">
                                    {demandMatchesData.property.bathrooms}
                                  </p>
                                </div>
                                <div className="d-inline-block ml-3 mt-2">
                                  <img
                                    className=""
                                    src={ViewIcon79}
                                    alt=""
                                    width={29}
                                  />
                                  <p className="d-inline-block mb-0 font-weight-500">
                                    {demandMatchesData.property.property_wc}
                                  </p>
                                </div>
                                <div className="d-inline-block ml-3 mt-2">
                                  <img
                                    className=""
                                    src={ViewIcon73}
                                    alt=""
                                    width={29}
                                  />
                                  <p className="d-inline-block mb-0 font-weight-500">
                                    {demandMatchesData.property.parking_space}
                                  </p>
                                </div>
                                <div className="d-inline-block ml-3 mt-2">
                                  <img
                                    className=""
                                    src={ViewIcon78}
                                    alt=""
                                    width={29}
                                  />
                                  <p className="d-inline-block mb-0 font-weight-500">
                                    {demandMatchesData.property.parking_space}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="d-inline-block mt-2">
                                  <img
                                    className=""
                                    src={ViewIcon63}
                                    alt=""
                                    width={29}
                                  />
                                  <p className="d-inline-block mb-0 font-weight-500">
                                    {demandMatchesData.property.plot_area}/
                                    {demandMatchesData.property.field_area}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <p className="mt-2">
                              {demandMatchesData.property.description}
                            </p>
                            <p className="lead font-weight-500 mt-2">
                              {(() => {
                                if (demandMatchesData.property.p_renting) {
                                  return (
                                    demandMatchesData.property.p_renting +
                                    "€ / month"
                                  );
                                }
                                if (demandMatchesData.property.p_selling) {
                                  return (
                                    demandMatchesData.property.p_selling + "€"
                                  );
                                }
                              })()}
                            </p>
                            <div className="d-flex align-items-center gap-3">
                              <button className="btn btn-primary btn-sm">
                                Contact Info
                              </button>
                              <i class="far fa-heart text-primary"></i>
                              <i class="fas fa-trash-alt text-primary"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ));
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewDemand;
