import React, { useState, useRef } from "react";
import { Navigate, Link } from "react-router-dom";
import { isEmail } from "validator";
import { register } from "../actions/auth";
import { doPost } from "../actions/common";
import { useNavigate } from "react-router-dom";
import { TabTitle } from "../utils/GeneralFunction";
import { useDispatch, useSelector } from "react-redux";
import { successToast, errorToast, infoToast } from "../actions/toast";
import Multiselect from "multiselect-react-dropdown";
import axios, { post } from 'axios';
import parseClip from '../utils/parseClip';

import "../Component/GlobalVariables";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import verticalLogo from "../img/logos/homexpress-logo-sm-vert.png";
import { useForm } from "react-hook-form";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";


// import Dropzone from 'react-dropzone';
const API_URL = global.apiUrl;
const HEADER = global.header;
if (!global.token) {
  global.token = localStorage.getItem("token")
    ? "Bearer " + localStorage.getItem("token").replace(/['"]+/g, "")
    : "";
}

const required = (value) => {
    if (!value) {
      return (
        <div className="alert error" role="alert">
          This field is required!
        </div>
      );
    }
  };
  const email = (value) => {
    if (!isEmail(value)) {
      return (
        <div className="alert error" role="alert">
          This is not a valid email.
        </div>
      );
    }
  };

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};
function UploadCsv() {
  TabTitle("Import csv"); //set the title
  let [data, setData] = useState("");
  
  const [errorClass, setErrorClass] = useState("alert alert-danger");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState('');
  const [cPData, setCPData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const { message } = useSelector((state) => state.message);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const form = useRef();
  const checkBtn = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { register, handleSubmit } = useForm();

  const onSubmit1 = async (data) => {
      const formData = new FormData();
      formData.append("file", file);

      const res = await fetch(API_URL+"/uploader/import_csv/", {
          method: "POST",
          body: formData,
      }).then((res) => res.json())
      //alert(JSON.stringify(`${res.message}, status: ${res.status}`));
  };

  const handleRegistration = (e) => {
    setFile(e.target.files[0])
  };

  const onSubmit = (e) =>{
    e.preventDefault();
    setLoading(true);
    const url = API_URL+'/uploader/import_csv/';
    const formData = new FormData();
    formData.append('file',file)
    const config = {
        headers: {
            'Authorization': 'Bearer '+localStorage.getItem('token'),
            'content-type': 'multipart/form-data'
        }
    }
     axios.post(url, formData,config).then(response => {
        console.log(response);
        successToast(response.data);
        navigate('/productListing')
        setLoading(false);
    }).catch(error => {
     console.log("*****  "+error)
     setLoading(false);
    })
  }

  const CopyPasteHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(doPost('uploader/copypaste/', {data:gridData}))
      .then((response) => {
        setLoading(false);
        navigate('/productListing')
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  
  const handlePaste = e => {
    if (e.target.tagName && e.target.tagName.match(/(input|textarea)/i)) {
        // Do not handle past when an input element is currently focused
        return;
    }

    // Get clipboard data as text
    const data = e.clipboardData.getData("text");

    // Simplified parsing of the TSV data with hard-coded columns
    const rows = data.split("\n");
    const result = rows.map(row => {
        const cells = row.split("\t");
        return {
            user: cells[0],
            category: cells[1],
            price: cells[2],
            name: cells[3],
            description: cells[4],
            quantity: cells[5]
        };
    });
    console.log('result', result);

    setGridData(result);
};
  const [files, setFiles] = useState(null);
  const handleChange = (event) => {
    setCPData(parseClip(event.target.value));
  };

  return (
    <div className="py-5 justify-content-center d-flex align-items-center bg-light">
      <div className="container">
        <div className="row justify-content-left">
          <div
            className="col-md-10 bg-white py-3 px-3 col-lg-6 col-11"
            style={{
              borderRadius: 20,
              boxShadow: "0px 0px 10px 1px #dfdfdf",
            }}
          >
            <p className="lead text-center font-weight-500">Import file &nbsp;
            <   Link to="/part_2.csv" target="_blank" download>Download sample</Link>
            </p>
            {/* {message && (
                                <div className="form-group">
                                    <div className={errorClass} role="alert">
                                        {message}
                                    </div>
                                </div>
                            )} */}
            <Form onSubmit={onSubmit} ref={form}>
              <div className="form-group">
                <label className="font-weight-500">
                  Choose file
                </label>
                <input type="file" {...register("file")} className="form-control" required onChange={handleRegistration}/>

{/*                  
                <Input
                  name="csf_file"
                  onChange={onFileUpload}
                  accept=".csv"
                  type="file"
                  className="form-control"
                  validations={[required]}
                /> */}
              </div>
              <button className="btn btn-primary btn-block" disabled={loading}>
                <span>Submit</span>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
              </button>
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
          </div>
          <div
            className="col-md-10 bg-white py-3 px-3 col-lg-6 col-11"
            style={{
              borderRadius: 20,
              boxShadow: "0px 0px 10px 1px #dfdfdf",
            }}
          >
            <p className="lead text-center font-weight-500">Copy paste upload
            {gridData.length >0 && (
            <button className="btn btn-primary" onClick={CopyPasteHandler} disabled={loading}>
                <span>Submit</span>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
              </button>
            )}
            </p>
            {/* {message && (
                                <div className="form-group">
                                    <div className={errorClass} role="alert">
                                        {message}
                                    </div>
                                </div>
                            )} */}
            {/* <Form onSubmit={CopyPasteHandler} ref={form}>   
              <div className="form-group">
                <label className="font-weight-500">
                 Paste here excel file copied rows
                </label>
                <textarea
                    rows="7"
                    placeholder="Paste your excel form data here..."
                    // onPaste={this.handlePaste}
                    onChange={handleChange}
                    className="form-control"
                    />
              </div>
              <button className="btn btn-primary btn-block" disabled={loading}>
                <span>Submit</span>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
              </button>
            </Form> */}
            <div onPaste={handlePaste}>
                    <Grid style={{ height: "400px" }} data={gridData}>
                        <Column field="user" title="User" />
                        <Column field="category" title="Category" />
                        <Column field="price" title="Price" />
                        <Column field="name" title="Name" />
                        <Column field="description" title="Description" />
                        <Column field="quantity" title="Quantity" />
                    </Grid>
                </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default UploadCsv;
