import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Spinner from "../../../Component/Spinner";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ReactDOM from "react-dom";
import "../../../styles/property.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import FileViewer from "react-file-viewer";
import DocViewer from "react-doc-viewer";
import GoogleMapReact from "google-map-react";
// import { CustomErrorComponent } from 'custom-error';
import { TabTitle } from "../../../utils/GeneralFunction";
import Dropdown from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import { doPost, doGet, doDelete } from "../../../actions/common";
import { successToast, errorToast, infoToast } from "../../../actions/toast";
import ViewIcon69 from "../../../img/house-icons/infographics-(69).png";
import ViewIcon63 from "../../../img/house-icons/infographics-(63).png";
import ViewIcon65 from "../../../img/house-icons/infographics-(65).png";
import ViewIcon67 from "../../../img/house-icons/infographics-(67).png";
import ViewIcon73 from "../../../img/house-icons/infographics-(73).png";
import ViewIcon81 from "../../../img/house-icons/infographics-(81).png";
import ViewIcon75 from "../../../img/house-icons/infographics-(75).png";
import ViewIcon78 from "../../../img/house-icons/infographics-(78).png";
// import ViewIcon73 from "../../../img/house-icons/infographics-(73).png";
import ViewIcon79 from "../../../img/house-icons/infographics-(79).png";
import ViewIcon80 from "../../../img/house-icons/infographics-(80).png";
import ViewIcon101 from "../../../img/house-icons/infographics-(101).png";
import TypeIcon1 from "../../../img/house-icons/Τύπος-ιδιοκτησίας-infographics.png";
const ViewProperty = () => {
  TabTitle("View property");
  const { id, page } = useParams();
  // console.log(page);
  const [match, setMatch] = useState();
  const dispatch = useDispatch();
  const [property, setValues] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [pending, setPending] = React.useState(true);
  const [showHeat, setShowHeat] = React.useState(false);
  const [showBuilding, setShowBuilding] = React.useState(false);
  const [showFeature, setshowFeature] = useState(false);
  const [showMore, setshowMore] = useState(false);
  const [center, setCenter] = useState({ lat: "", lng: "" });
  const [key, setKey] = useState("general");
  const [filetype, setFileType] = useState("image");
  const [srcFile, setSrcFile] = useState(null);
  const columns = [
    { name: "Property ID", selector: (row) => row.id },
    { name: "Property name", selector: (row) => row.title },
    { name: "Location", selector: (row) => row.full_address },
    {
      name: "Type",
      selector: (row) => (row.property_type ? row.property_type.name : ""),
    },
    {
      name: "Availability",
      selector: (row) => formatAvailability(row.property_availabilities),
    },
    { name: "Year built", selector: (row) => row.p_year },
    { name: "Floor", selector: (row) => row.prop_floor },
    { name: "Surface (sqm)", selector: (row) => row.floor_area },
    { name: "Bedrooms", selector: (row) => row.bedrooms },
    { name: "Price", selector: (row) => row.p_renting },
    { name: "Matches", selector: (row) => "" },
    { name: "Status", selector: (row) => row.status },
    {
      name: "Agent ",
      selector: (row) =>
        row.user ? row.user.first_name + " " + row.user.last_name : "",
    },
    {
      cell: (row) => (
        <Dropdown>
          <Dropdown.Toggle variant="" id="dropdown-basic"></Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href={`/edit-property/${row.id}`}>
              Edit
            </Dropdown.Item>
            <Dropdown.Item href="#/action-3">Delete</Dropdown.Item>
            <Dropdown.Item href="#">View property</Dropdown.Item>
            <Dropdown.Item href="#/action-2">View matches</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const customStyles = (state, rowInfo, column, instance) => ({
    style: {
      cursor: "pointer",
      overflow: "visible",
    },
  });
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };
  const AnyReactComponent = ({ text }) => <div>{text}</div>;
  const getproperty = () => {
    dispatch(doGet("property/" + id))
      .then((response) => {
        // console.log(response);
        let p = response.data.data;
        setValues(response.data.data);
        // console.log(response.data.data);
        if (response.data.data) {
          let p = response.data.data;
          let file = "https://drive.google.com/viewerng/viewer?url="+p.floor_plan+"?pid=explorer&efh=false&a=v&chrome=false&embedded=true";
          setSrcFile(file);
          response.data.data.matches.forEach((item, index) => {
            if (item.source_id == id) {
              setMatch(item.percentage);
            }
          });
        }
        setPending(false);
        setCenter({ lat: 25.2048493, lng: p.address_longitude });
        // successToast(response.data.message)
      })
      .catch((message) => {
        errorToast(message);
      });
  };

  const deleteproperty = (ids) => {
    dispatch(doDelete("deleteproperty", { ids: selectedRows.map((r) => r.id) }))
      .then((response) => {
        successToast(response.data.message);
        setValues(differenceBy(property, selectedRows, "id"));
      })
      .catch((message) => {
        errorToast(message);
      });
  };

  const formatAvailability = (str) => {
    let newString = str.charAt(0).toUpperCase() + str.slice(1);
    return newString.replace(/_/g, ",");
  };

  useEffect(() => {
    getproperty();
  }, []);

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const differenceBy = (array1, array2, key) => {
    return array1.filter((a) => !array2.some((b) => b[key] === a[key]));
  };

  const [isActive, setActive] = useState("false");
  const handleshowFeature = () => {
    setActive(!isActive);
    if (showFeature === false) {
      return setshowFeature(true);
    } else {
      return setshowFeature(false);
    }
  };

  const [isShowMore, setShowMore] = useState("false");
  const handleShowMore = () => {
    setShowMore(!isShowMore);
    if (showMore === false) {
      return setshowMore(true);
    } else {
      return setshowMore(false);
    }
  };

  const [isChanged, setChanged] = useState("false");
  const handleShowHeating = () => {
    setChanged(!isChanged);
    if (showHeat === false) {
      return setShowHeat(true);
    } else {
      return setShowHeat(false);
    }
  };

  const [isBuilding, setBuilding] = useState("false");
  const handleShowBuilding = () => {
    setBuilding(!isBuilding);
    if (showBuilding === false) {
      return setShowBuilding(true);
    } else {
      return setShowBuilding(false);
    }
  };

  const contextActions = React.useMemo(() => {
    const handleDelete = () => {
      if (
        window.confirm(
          `Are you sure you want to delete:\r ${selectedRows.map(
            (r) => r.title
          )}?`
        )
      ) {
        setToggleCleared(!toggleCleared);
        deleteproperty();
      }
    };

    return (
      //   <button
      //     key="delete"
      //     className="btn btn-danger"
      //     onClick={handleDelete}
      //     style={ backgroundColor: "red" }
      //     icon
      //   >
      //     Delete
      //   </button>
      false
    );
  }, [property, selectedRows, toggleCleared]);

  const docs = [
    {
      uri:
        "http://localhost:8000/frontend/assets/images/property/img4_1664810860.pdf",
    },
  ];

  return (
    <div className="dashboard">
      <div className="row">
        <div className="col-md-6 col-7">
          <h2 className="font-weight-500 mb-0">
            {property.title} # {property.id}
          </h2>
        </div>
        <div className="col-md-6 text-right col-5">
          <div className="row justify-content-between">
            <div className="col-6">
              Matched with property
              <Link className="pl-2" to={"/property/view/" + id}>
                #[{id}]
              </Link>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-9 mb-3 pt-5 mt-5">
          <div className="first-box rounded-3">
            <div className="row">
              <div className="col-md-4">
                {property.property_type ? property.property_type.name : ""}
              </div>
              <div className="col-md-8 text-right">
                <i class="fa fa-map-marker text-primary" aria-hidden="true"></i>
                &nbsp;{property.full_address ? property.full_address : ""}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="big-box">
                  <img src="" alt=""></img>
                </div>
              </div>
            </div>

            <div className="files col-md-12">
              <Tabs
                id="controlled-tab-example"
                activeKey={filetype}
                onSelect={(k) => setFileType(k)}
                className="mb-3"
                justify
              >
                <Tab
                  eventKey="image"
                  title={<i class="fa fa-picture-o" aria-hidden="true"></i>}
                >
                  {property.photos && (
                    <Carousel>
                      {property.photos.map((s) => (
                        <div>
                          {" "}
                          <img src={s} />
                        </div>
                      ))}
                    </Carousel>
                  )}
                </Tab>
                {property.video_url && (
                  <Tab
                    eventKey="video"
                    title={
                      <i class="fa fa-video-camera" aria-hidden="true"></i>
                    }
                  >
                     
                  </Tab>
                )}
                {property.floor_plan && (
                  <Tab
                    eventKey="file"
                    title={<i class="fa fa-file" aria-hidden="true"></i>}
                  >
                    
                    {/* <iframe src="https://docs.google.com/viewer?url=http://46.101.207.99:8000/frontend/assets/images/property/6352745dae545.pdf&embedded=true" width="100%" height="100%"   frameborder="0"/> */}
                    
                    <iframe src={srcFile} width="100%" height="350px"  />
                  </Tab>
                )}
              </Tabs>
            </div>
            <div className="row m-3 justify-content-around">
              <div className="col-md-2 d-flex align-items-center">
                <img alt="" src={ViewIcon69} className="w-20" />
                &nbsp;{property.plot_area / property.field_area}
              </div>
              <div className="col-md-2 d-flex align-items-center">
                <img alt="" src={ViewIcon63} className="w-20" />
                &nbsp;{property.bedrooms}
              </div>
              <div className="col-md-2 d-flex align-items-center">
                <img alt="" src={ViewIcon65} className="w-20" />
                &nbsp;{property.bathrooms}
              </div>
              <div className="col-md-2 d-flex align-items-center">
                <img alt="" src={ViewIcon67} className="w-20" />
                &nbsp;{property.prop_floor}
              </div>
              <div className="col-md-2 d-flex align-items-center">
                <img alt="" src={ViewIcon73} className="w-20" />
                &nbsp;{property.parking_space}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="nav nav-tabs mb-3"
                  justify
                >
                  <Tab eventKey="general" title="General">
                    <div className="row mt-1">
                      <div className="col-md-6 d-flex align-items-center">
                        <img alt="" src={TypeIcon1} />
                        &nbsp; Type:{" "}
                        {property.property_type
                          ? property.property_type.name
                          : ""}
                      </div>
                      <div className="col-md-6 d-flex align-items-center">
                        <img alt="" src={ViewIcon81} />
                        &nbsp;Floor: {property.prop_floor}
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-md-6 d-flex align-items-center">
                        <img alt="" src={ViewIcon75} />
                        &nbsp;Year: {property.p_year}
                      </div>
                      {(() => {
                        if (
                          property.property_type &&
                          property.property_type.name === "Land"
                        ) {
                          let _areaName_ = "Floor area";
                          let _areaValue_ = property.floor_area;
                          switch (property.sub_property_type.nam) {
                            case "Plot":
                              _areaName_ = "Plot Area";
                              _areaValue_ = property.plot_area;
                              break;
                            case "Field":
                              _areaName_ = "Field Area";
                              _areaValue_ = property.field_area;
                              break;
                            default:
                              break;
                          }
                          return (
                            <div className="col-md-6 d-flex align-items-center">
                              <img alt="" src={ViewIcon78} />
                              &nbsp;{_areaName_}:{_areaValue_}
                            </div>
                          );
                        }
                      })()}
                    </div>
                    <div className="row mt-1">
                      <div className="col-md-6 d-flex align-items-center">
                        <img alt="" src={ViewIcon63} />
                        &nbsp;Bedrooms: {property.bedrooms}
                      </div>
                      <div className="col-md-6 d-flex align-items-center">
                        <img alt="" src={ViewIcon65} />
                        &nbsp;Bathrooms: {property.bathrooms}
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-md-6 d-flex align-items-center">
                        <img alt="" src={ViewIcon79} />
                        &nbsp;WC: {property.property_wc}
                      </div>
                      <div className="col-md-6 d-flex align-items-center">
                        <img alt="" src={ViewIcon73} />
                        &nbsp;Parking: {property.parking_space}
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-md-6 d-flex align-items-center">
                        <img alt="" src={ViewIcon80} />
                        &nbsp;Year: {property.p_year}
                      </div>
                      <div className="col-md-6 d-flex align-items-center">
                        <img alt="" src={ViewIcon101} />
                        &nbsp;Orientation: {property.orientation}
                      </div>
                    </div>

                    <div className="row mt-3 mb-2">
                      <div className="col-md-12">
                        <button
                          type="button"
                          className="btn btn-outline-success w-100 getMoreFatures"
                          onClick={handleshowFeature}
                        >
                          <i
                            className={`fa fa-${isActive ? "plus" : "minus"}`}
                          ></i>{" "}
                          More Features
                        </button>
                      </div>
                    </div>

                    {showFeature && (
                      <div className="moreFeatures">
                        <div className="row mt-2 mb-2 p-1">
                          {property.features.map((user) => (
                            <div className="user">
                              <img alt="" src={user.icon} />
                              &nbsp;{user.feature_name}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    <div className="row mb-2">
                      <div className="col-md-12 ">
                        <button
                          type="button"
                          className="btn btn-outline-success  w-100 getHeating "
                          onClick={handleShowHeating}
                        >
                          <i
                            className={`fa fa-${isChanged ? "plus" : "minus"}`}
                          ></i>{" "}
                          Heating
                        </button>
                      </div>
                      {showHeat ? (
                        <div className="heating mt-2">
                          <div className="col-md-12">
                            <div>
                              1. Property Heating: {property.property_heating}
                              <br />
                              2. Energy Rating: {property.energy_rating}
                              {/* <br />
                              3. TBC: N/A */}
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className="col-md-12 mt-2">
                        <button
                          type="button"
                          className="btn btn-outline-success  w-100 grtBuilding"
                          onClick={handleShowBuilding}
                        >
                          <i
                            className={`fa fa-${isBuilding ? "plus" : "minus"}`}
                          ></i>{" "}
                          Building
                        </button>
                      </div>
                      {showBuilding ? (
                        <div className="building mt-2">
                          <div className="row">
                            <div className="col-md-6">
                              <div>
                                1. Building Condition:{" "}
                                {property.building_condition
                                  ? property.building_condition
                                  : " N/A"}
                                <br />
                                2. Property Condition:{" "}
                                {property.property_condition
                                  ? property.property_condition
                                  : " N/A"}
                                <br />
                                3. Property View:{" "}
                                {property.property_view
                                  ? property.property_view
                                  : " N/A"}
                                <br />
                                4. Property Floor Type:{" "}
                                {property.floor_type
                                  ? property.floor_type
                                  : " N/A"}
                                <br />
                                6. Property Frontage Length:{" "}
                                {property.frontage_length
                                  ? property.frontage_length
                                  : " N/A"}
                                <br />
                                7. Property Land Depth:{" "}
                                {property.land_depth
                                  ? property.land_depth
                                  : " N/A"}
                                <br />
                                8. Property Orientaion :{" "}
                                {property.orientation
                                  ? property.orientation
                                  : " N/A"}
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="col-md-12 mt-2">
                        <button
                          type="button"
                          className="btn btn-outline-secondary w-100 grtBuilding"
                          onClick={handleShowMore}
                        >
                          <i
                            className={`fa fa-${isShowMore ? "plus" : "minus"}`}
                          ></i>{" "}
                          Distant And Neighbourhood
                        </button>
                      </div>
                      {showMore ? (
                        <div className="building mt-2">
                          <div className="row">
                            <div className="col-md-6">
                              <div>
                                <ol>
                                  {property.dis_airport
                                    ? "<li>Distance From Airport:" +
                                      property.dis_airport +
                                      "</li>"
                                    : ""}
                                  {property.dis_market
                                    ? "<li>Distance From Market:" +
                                      property.dis_market +
                                      "</li>"
                                    : ""}
                                  {property.dis_metro
                                    ? "<li>Distance From Metro:" +
                                      property.dis_metro +
                                      "</li>"
                                    : ""}
                                  {property.dis_park
                                    ? "<li>Distance From Park:" +
                                      property.dis_park +
                                      "</li>"
                                    : ""}
                                  {property.dis_school
                                    ? "<li>Distance From School:" +
                                      property.dis_school +
                                      "</li>"
                                    : ""}
                                  {property.dis_playground
                                    ? "<li>Distance From Play Ground:" +
                                      property.dis_playground +
                                      "</li>"
                                    : ""}
                                  {property.dis_sea
                                    ? "<li>Distance From Sea:" +
                                      property.dis_sea +
                                      "</li>"
                                    : ""}
                                  {property.dis_pharmacy
                                    ? "<li>Distance From Pharmacy:" +
                                      property.dis_pharmacy +
                                      "</li>"
                                    : ""}
                                </ol>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div>
                                <ol>
                                  {property.near_by
                                    ? "<li>Near By Place : " +
                                      property.near_by +
                                      "</li>"
                                    : " "}
                                  {property.neighborhoods
                                    ? "<li>Neighbourhood:" +
                                      property.neighborhoods +
                                      "</li>"
                                    : ""}
                                </ol>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Tab>
                  <Tab eventKey="location" title="Location">
                    <div className="row mt-1">
                      <div className="col-md-12">
                        <div style={{ height: "60vh", width: "100%" }}>
                          <GoogleMapReact
                            bootstrapURLKeys={{
                              key: "AIzaSyDKxqxymhwwVVHfHtec5lyx0DlqnLTvxKQ",
                            }}
                            defaultCenter={defaultProps.center}
                            //  center={center}
                            defaultZoom={defaultProps.zoom}
                          >
                            <AnyReactComponent
                              lat={59.955413}
                              lng={30.337844}
                              text="My Marker"
                            />
                          </GoogleMapReact>
                        </div>
                        <div id="address-map-container">
                          <div id="address-map"></div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="descritpion" title="Descritpion">
                    <div className="row mt-1">
                      <p className="p-2">{property.description}</p>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 mb-3">
          <div className="row right_info">
            {!page && (
              <>
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-end gap-3 pe-4 pb-3">
                      <button type="button" class="btn btn-primary">
                        Edit
                      </button>
                      <button type="button" class="btn btn-danger">
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
                {property.matches_count > 0 ? (
                  property.matches.map((match) => (
                    <>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card">
                            <div className="card-body">
                              <div className="d-flex align-items-center justify-content-around">
                                <div className="box d-flex align-items-center">
                                  {(() => {
                                    // console.log(match.demand);
                                    if (match.demand != null) {
                                      return (
                                        <img
                                          src={match.demand.company.logo}
                                          className="box-image"
                                          alt=""
                                        ></img>
                                      );
                                    } else {
                                      return (
                                        <img
                                          src="/assets/images/user.png"
                                          className="box-image"
                                          alt=""
                                        ></img>
                                      );
                                    }
                                  })()}
                                </div>
                                <div>{match.percentage}% match</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br></br>
                    </>
                  ))
                ) : (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center justify-content-around">
                            <div>No Matched Found</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="row h-99"></div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-2">
                      {(property.property_availabilities == "buy" ||
                        property.property_availabilities == "buy_rent") && (
                        <div className="price-sec">
                          <h5>Selling For:</h5>{" "}
                          <div className="mb-2">{property.p_selling} € </div>{" "}
                          <div className="border-green"></div>
                        </div>
                      )}
                    </div>

                    <div></div>
                    {(property.property_availabilities == "rent" ||
                      property.property_availabilities == "buy_rent") && (
                      <div>
                        <h5>Selling For:</h5>{" "}
                        <div className="mb-2">{property.p_renting} €</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12">
                    <div className="diff-price-sec">
                      <button
                        type="button"
                        className="btn w-100 text-dark w-100"
                      >
                        <i className="fa fa-plus"></i> Property Expences
                      </button>
                      <div className="p-2">
                        <p>
                          Expences Enfia:{" "}
                          {property.exp_enfia
                            ? property.exp_enfia + "€"
                            : "N/A"}
                        </p>
                        <p>
                          Expences Electricity:{" "}
                          {property.exp_electricity
                            ? property.exp_electricity + "€"
                            : "N/A"}
                        </p>
                        <p>
                          Expences Water:{" "}
                          {property.exp_water
                            ? property.exp_water + "€"
                            : "N/A"}
                        </p>
                        {/* <p>TBC: 00,00€</p> */}
                        <div className="border-green"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {page && (
              <>
                <div className="col-12 text-right mt-5 mb-3">
                  <span
                    class="btn btn-info text-light"
                    style={{ cursor: "inherit" }}
                  >
                    <span class="badge bg-Success">{match}%</span> Match
                  </span>
                </div>
                <div className="col-12">
                  <h5 className="font-weight-500">Agent Information</h5>
                  <div class="card">
                    <div class="card-body">
                      {property.user && (
                        <div className="d-flex align-items-center justify-content-between gap-3">
                          {(() => {
                            if (property.user.image) {
                              return (
                                <div className="imgbox">
                                  <img alt="" src={property.user.image} />
                                </div>
                              );
                            } else {
                              return (
                                <div className="imgbox">
                                  <img alt="" src="/assets/images/user.png" />
                                </div>
                              );
                            }
                          })()}
                          <div className="contbox">
                            <div>{property.user.full_name}</div>
                            <div>{property.user.email}</div>
                            <div>{property.user.phone}</div>
                            <div>{property.user.meta_data.office_location}</div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <h5 className="font-weight-500">Agency Information</h5>
                  <div class="card">
                    <div class="card-body">
                      {property.company && (
                        <div className="d-flex align-items-center justify-content-between gap-3">
                          {(() => {
                            if (property.company.logo) {
                              return (
                                <div className="imgbox">
                                  <img alt="" src={property.company.logo} />
                                </div>
                              );
                            } else {
                              return (
                                <div className="imgbox">
                                  <img
                                    alt=""
                                    src="/assets/images/office-building.png"
                                  />
                                </div>
                              );
                            }
                          })()}
                          <div className="contbox">
                            <div>{property.company.name}</div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProperty;
