import React, { useState, useEffect } from "react";
import { successToast, errorToast, infoToast } from "../actions/toast";
import { useNavigate, Navigate, Link, useParams } from "react-router-dom";
import { TabTitle } from "../utils/GeneralFunction";
import axios from "axios";
import "../Component/GlobalVariables";
const API_URL = global.apiUrl;
const HEADER = global.header;
const emailConfiguration = (props) => {
  const navigate = useNavigate();
  TabTitle("Email Verification");
  const { id } = useParams();
  if (id) {
    axios
      .post(
        API_URL + "/comfirmemail",
        {
          unquevalue: id,
        },
        HEADER
      )
      .then((response) => {
        // console.log(response);
        setTimeout(() => {
          successToast("You can login now!");
          navigate("/");
        }, 2000);
      })
      .catch((message) => {
        // console.log(message);
        errorToast("Account can't verify");
        navigate("/");
      });
  } else {
    errorToast("User not found.");
    navigate("/");
  }
};

export default emailConfiguration;
